import { ReactNode } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';

import styles from './Modal.module.css';

const appElements = document.querySelectorAll('#root, #storybook-root');
if (appElements.length > 0) {
  ReactModal.setAppElement(appElements[0] as HTMLElement);
}

export type ModalProps = {
  children: ReactNode;
} & ReactModalProps;

export function Modal({ children, ...rest }: ModalProps) {
  return (
    <ReactModal overlayClassName={styles.overlay} className={styles.content} {...rest}>
      <div className={styles.scrollContainer}>{children}</div>
    </ReactModal>
  );
}
