import { FieldConfig, Prediction, PredictionResponse } from '@lucidtech/las-sdk-browser';

export type SimplePrediction = {
  value: string | number | boolean | null;
  confidence: number;
};

export type NormalizedPredictions = {
  [k: string]: Array<SimplePrediction | NormalizedPredictions>;
};

export type LabelSettings = Record<string, boolean | Record<string, boolean>>;

export function fieldConfigToLabelSettings(fieldConfig: FieldConfig): LabelSettings {
  const settings: LabelSettings = {};
  for (const [field, fieldSettings] of Object.entries(fieldConfig)) {
    settings[field] = fieldSettings.fields
      ? (fieldConfigToLabelSettings(fieldSettings.fields) as Record<string, boolean>)
      : true;
  }

  return settings;
}

export function evaluationToLabelSettings(evaluation: Record<string, any>): LabelSettings {
  const settings: LabelSettings = {};
  for (const field of Object.keys(evaluation?.labels || {})) {
    const [fieldName, lineItemFieldName] = String(field).split('/');
    if (!settings[fieldName]) {
      settings[fieldName] = lineItemFieldName ? {} : true;
    }
    if (lineItemFieldName) {
      (settings[fieldName] as Record<string, boolean>)[lineItemFieldName] = true;
    }
  }

  return settings;
}

// Lowest confidence prediction to show, discard anything below this confidence value
const DISCARD_PREDICTION_THRESHOLD = 0.25;

export function normalizePredictionResponse(predictions: PredictionResponse, labels: LabelSettings = {}) {
  const normalized: NormalizedPredictions = {};

  // Go through settings for labels, add empty prediction array for all
  for (const [label, labelSetting] of Object.entries(labels)) {
    if (!normalized[label] && labelSetting) {
      normalized[label] = [];
    }
  }

  for (let prediction of predictions?.predictions || []) {
    // If this field is set as false, ignore adding anything
    if (labels[prediction.label] === false) {
      continue;
    }

    if (!normalized[prediction.label]) {
      normalized[prediction.label] = [];
    }
    // is line item prediction
    if (Array.isArray(prediction.value)) {
      // for each line item
      for (const line of prediction.value) {
        const lineItem: NormalizedPredictions = {};

        // Go through settings for line item labels, add empty prediction array for all
        for (const [label, labelSetting] of Object.entries(labels[prediction.label] || {})) {
          if (!lineItem[label] && labelSetting) {
            lineItem[label] = [];
          }
        }

        for (const lineItemField of line) {
          // If this line item field is set as false, ignore adding anything
          if (
            typeof labels[prediction.label] === 'object' &&
            (labels[prediction.label] as Record<string, boolean>)?.[lineItemField.label] === false
          ) {
            continue;
          }

          const { value, confidence } = lineItemField as Prediction;

          // If prediction confidence is below threshold, discard it
          if (confidence < DISCARD_PREDICTION_THRESHOLD) {
            continue;
          }

          if (!lineItem[lineItemField.label]) {
            lineItem[lineItemField.label] = [];
          }
          lineItem[lineItemField.label].push({ value: value as any, confidence });

          // Go through settings for line item label, add empty prediction array for all
          for (const [label, labelSetting] of Object.entries(labels[prediction.label] || {})) {
            if (!lineItem[label] && labelSetting === true) {
              lineItem[label] = [];
            }
          }
        }
        normalized[prediction.label].push(lineItem);
      }
    } else {
      prediction = prediction as Prediction;
      const { value, confidence } = prediction;
      // If prediction confidence is below threshold, discard it
      if (confidence < DISCARD_PREDICTION_THRESHOLD) {
        continue;
      }
      normalized[prediction.label].push({ value, confidence });
    }
  }

  return normalized;
}
