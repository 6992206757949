import { Dialog, Text, Group } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { ReactNode, Suspense, useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';

import CalendarIcon from '@assets/calendar-icon.svg?react';
import { Banner } from '@components/TopBar/Banner';
import { ButtonLink } from '@components';
import { Content } from '@components/Content';
import { DatasetDeleteProvider } from '@contexts/DatasetDelete';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { Loading } from '@components/Loading';
import { Prefetch } from '@components/Prefetch';
import { RequireAuth } from '@components/RequireAuth';
import { Sidebar } from '@shared/components/sidebar';
import { useIdentifyToChatlio, useTracker } from '@hooks';

export const VIDEO_KEY = 'hasClosedHomeVideo';

export type LayoutProps = {
  children?: ReactNode;
};
export function Layout({ children }: LayoutProps) {
  useIdentifyToChatlio();
  const { tracker } = useTracker();
  const [opened, { toggle, close }] = useDisclosure(!Boolean(localStorage.getItem(VIDEO_KEY)));

  useEffect(() => {
    const handleStorage = () => {
      const hasClosedHomeVideo = Boolean(localStorage.getItem(VIDEO_KEY));
      if (opened && hasClosedHomeVideo) {
        toggle();
      }
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [opened, toggle]);

  return (
    <ErrorBoundary>
      <RequireAuth>
        <Prefetch />
        <DatasetDeleteProvider>
          <Sidebar>
            <ErrorBoundary>
              <Content>
                <Banner />
                <Suspense fallback={<Loading />}>
                  <Outlet />
                  {children}
                </Suspense>
              </Content>
            </ErrorBoundary>
            <Dialog
              classNames={{ root: 'tw-flex', closeButton: 'tw-mt-3 tw-mr-3' }}
              opened={opened}
              withCloseButton
              onClose={() => {
                close();
                localStorage.setItem(VIDEO_KEY, 'true');
                tracker.track('Home Video Closed', {
                  properties: {},
                });
              }}
              radius="lg"
              size="720"
            >
              <div className="tw-space-y-2 tw-p-2">
                <Text className="!tw-text-lg !tw-font-medium !tw-text-gray-900">Getting started</Text>
                <Group className="!tw-flex !tw-flex-row">
                  <video
                    className="tw-rounded-lg"
                    controls
                    muted
                    width="400"
                    poster="https://docs.cradl.ai/video/ai_studio_thumb.png"
                    onPlay={(event) => {
                      tracker.track('Home Video Played', {
                        properties: {},
                      });
                    }}
                  >
                    <source src="https://docs.cradl.ai/video/cradl-in-3-min.mp4" type="video/mp4" />
                  </video>
                  <div className="tw-w-[250px] tw-space-y-2 tw-rounded-lg tw-bg-gray-50 tw-p-5">
                    <Text className="!tw-text-lg !tw-font-medium !tw-text-gray-900">Want hands-on help?</Text>
                    <Text className="!tw-text-md !tw-text-gray-600">
                      Meet with a product expert to help set-up your first automation project in no-time.
                    </Text>
                    <div className="tw-py-3">
                      <ButtonLink variant="secondary-gray" to="https://www.cradl.ai/contact" target="_blank">
                        <CalendarIcon />
                        Book a session
                      </ButtonLink>
                    </div>
                  </div>
                </Group>
              </div>
            </Dialog>
          </Sidebar>
        </DatasetDeleteProvider>
      </RequireAuth>
    </ErrorBoundary>
  );
}
