import { styled } from '@config/stitches';

export const UnstyledButton = styled('button', {
  display: 'inline-block',
  padding: 0,
  backgroundColor: 'unset',
  margin: 0,
  border: 'unset',
  color: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  textAlign: 'unset',
  verticalAlign: 'unset',
});
