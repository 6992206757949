import { HTMLAttributes } from 'react';

export type StepIndicatorProps = {
  max: number;
  current: number;
} & HTMLAttributes<HTMLDivElement>;

export function StepIndicator({ max, current, ...rest }: StepIndicatorProps) {
  return (
    <div {...rest}>
      {current}/{max}
      <ol className="tw-mt-3 tw-flex tw-gap-1">
        {Array.from({ length: max }).map((_, index) => {
          return (
            <li key={index + 1}>
              <div
                className={`tw-h-2 tw-w-16 tw-rounded-[3px] ${
                  current >= index + 1 ? 'tw-bg-primary-500' : 'tw-bg-gray-200'
                }`}
              />
            </li>
          );
        })}
      </ol>
    </div>
  );
}
