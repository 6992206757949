import { CSS } from '@dnd-kit/utilities';
import { Group, Menu, Popover, Text, Textarea, Tooltip } from '@mantine/core';
import { twMerge } from 'tailwind-merge';
import { useSortable } from '@dnd-kit/sortable';
import { useTranslation } from 'react-i18next';

import BankNoteIcon from '@assets/icon-bank-note-03.svg?react';
import CalendarIcon from '@assets/icon-calendar.svg?react';
import CheckIcon from '@assets/icon-check-2.svg?react';
import EmptyPromptIcon from '@assets/magic-wand.svg?react';
import GridIcon from '@assets/icon-grid.svg?react';
import NullTypeIcon from '@assets/icon-edit-05.svg?react';
import NumbersIcon from '@assets/icon-numbers.svg?react';
import PromptIcon from '@assets/magic-wand-filled.svg?react';
import TagIcon from '@assets/icon-tag-01.svg?react';
import TypeSquareIcon from '@assets/icon-type-square.svg?react';
import { Cell } from '../Cell';
import { DeleteButtonCell } from '../DeleteButtonCell';
import { DragHandle } from '../DragHandle';
import { Field as LasField } from '@lucidtech/las-sdk-browser';
import { Button, Field, FieldConfigAction, LineItemField, TypeOption } from '@components';
import { Row } from '../Row';
import { ThresholdInput } from '@features/flows/components/threshold-modal/threshold-input';
import { styled } from '@config/stitches';
import { useState } from 'react';

export const DraggableItemContainer = styled('li', {
  flexDirection: 'column',
  display: 'flex',
  borderTop: '1px solid transparent',
  borderBottom: '1px solid transparent',
  margin: '0 -30px',
  '&.active': {
    opacity: 0.5,
    borderTop: '1px dashed $gray500',
    borderBottom: '1px dashed $gray500',
  },

  '&.dragging': {
    position: 'relative',
    boxShadow: '$md',
    border: '1px solid $gray200',
    padding: '$2',
    borderRadius: '8px',
    overflow: 'hidden',
    opacity: 0.85,
  },
  '&.active:after': {
    content: ' ',
    zIndex: 10,
    display: 'block',
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    opacity: 1,
    backgroundColor: 'rgb(83, 93, 198, 0.05)',
  },
});

const LINE_ITEM_VOCABULARY_OPTIONS: Array<TypeOption> = ['string', 'amount', 'date', 'numeric'];

export type DraggableLineItemFieldProps = {
  active?: boolean;
  dragging?: boolean;
  item: LineItemField;
  dispatch: React.Dispatch<FieldConfigAction>;
  itemIndex: number;
  parentIndex: number;
  isLastItem: boolean;
  showConfidences: boolean;
  canEditFields?: boolean;
  enablePromptConfig?: boolean;
};

export const fieldTypeToIcon = {
  amount: BankNoteIcon,
  date: CalendarIcon,
  digits: NumbersIcon,
  enum: TagIcon,
  lines: GridIcon,
  numeric: NumbersIcon,
  string: TypeSquareIcon,
};

function FieldTypeIcon(type?: string | null) {
  if (type) {
    const Icon = fieldTypeToIcon[type as LasField['type']];
    return <Icon className="tw-h-[20px] tw-w-[20px] tw-text-gray-500" />;
  } else {
    return <NullTypeIcon className="tw-h-[20px] tw-w-[20px] tw-text-gray-500" />;
  }
}

function FieldTypeMenuItem(type: string, selected: boolean, dispatchType: () => void) {
  return (
    <Menu.Item onClick={dispatchType} rightSection={selected ? <CheckIcon className="tw-text-primary-600" /> : null}>
      <Group>
        {FieldTypeIcon(type)}
        <Text
          className={twMerge('!tw-text-sm !tw-capitalize', selected ? '!tw-text-primary-600' : '!tw-text-gray-900')}
        >
          {type}
        </Text>
      </Group>
    </Menu.Item>
  );
}

export function DraggableLineItemField({
  dispatch,
  item,
  itemIndex,
  active,
  dragging,
  parentIndex,
  isLastItem,
  showConfidences,
  canEditFields,
  enablePromptConfig,
}: DraggableLineItemFieldProps) {
  const { id, description, name, type: lineItemType } = item;
  const { t } = useTranslation();
  const [descriptionConfigOpened, setDescriptionConfigOpened] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id, disabled: isLastItem });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <DraggableItemContainer
      ref={setNodeRef}
      style={style}
      className={dragging ? 'dragging' : active ? 'active' : undefined}
    >
      <div className="tw-group/main tw-flex tw-items-center">
        <DragHandle {...listeners} {...attributes} dragging={dragging} />
        <Row className="tw-flex-1">
          <Cell className="tw-min-w-[70%]">
            <input
              value={name}
              disabled={!canEditFields}
              readOnly={!canEditFields}
              className="disabled:!tw-bg-gray-50"
              onChange={(e) =>
                dispatch({
                  type: 'nameLineItem',
                  index: itemIndex,

                  fieldIndex: parentIndex,
                  payload: e.target.value,
                })
              }
              placeholder={
                isLastItem
                  ? t('models-create:fields.lineItemAddPlaceHolder')
                  : t('models-create:fields.lineItemNamePlaceHolder')
              }
              aria-label="Field name"
            />
          </Cell>
          {canEditFields ? (
            <Cell className="tw-min-w-[16%] tw-gap-8">
              {!isLastItem && (
                <>
                  <Menu width={200} classNames={{ dropdown: '!tw-rounded-lg' }}>
                    <Menu.Target>
                      <div
                        className={twMerge(
                          'tw-cursor-pointer tw-rounded-lg tw-border-1.5 tw-p-1.5 tw-shadow-sm hover:tw-bg-gray-50',
                          lineItemType ? 'tw-border-gray-200' : 'tw-border-error-500'
                        )}
                      >
                        {FieldTypeIcon(lineItemType)}
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {['amount', 'string', 'date', 'numeric'].map((menuType) =>
                        FieldTypeMenuItem(menuType, menuType === lineItemType, () => {
                          dispatch({
                            type: 'typeLineItem',
                            index: itemIndex,
                            fieldIndex: parentIndex,
                            payload: menuType as NonNullable<Field['type']>,
                          });
                        })
                      )}
                    </Menu.Dropdown>
                  </Menu>
                  <Tooltip disabled={enablePromptConfig} label="Enable LLM for your model in order to configure prompt">
                    <div>
                      <Popover
                        disabled={!enablePromptConfig}
                        classNames={{ dropdown: '!tw-p-0 !tw-rounded-lg' }}
                        withArrow
                        shadow="md"
                        opened={descriptionConfigOpened}
                        onChange={setDescriptionConfigOpened}
                        width={300}
                      >
                        <Popover.Target>
                          <div
                            className="tw-cursor-pointer tw-rounded-lg tw-border-1.5 tw-border-gray-200 tw-p-1.5 tw-shadow-sm hover:tw-bg-gray-50"
                            onClick={() => setDescriptionConfigOpened(!descriptionConfigOpened)}
                          >
                            {description ? (
                              <PromptIcon className="tw-h-[20px] tw-w-[20px] tw-text-gray-600" />
                            ) : (
                              <EmptyPromptIcon className="tw-h-[20px] tw-w-[20px] tw-text-gray-200" />
                            )}
                          </div>
                        </Popover.Target>
                        <Popover.Dropdown>
                          <div className="tw-space-y-1">
                            <Group className="!tw-gap-1 tw-border-b-1.5 tw-border-gray-200 tw-p-3">
                              <PromptIcon className="tw-h-[18px] tw-w-[18px] tw-text-gray-600" />
                              <Text className="!tw-text-gray-700">LLM prompt optimization</Text>
                            </Group>
                            <div className="tw-space-y-2 tw-p-3">
                              <Text className="!tw-text-sm !tw-text-gray-600">
                                Provide additional info about this field to improve accuracy from the LLM
                              </Text>
                              <Textarea
                                autosize
                                placeholder="Example: The due date of the invoice. Such as: 01.09.2024, 30 days, September 1. 2024"
                                onChange={(e) =>
                                  dispatch({
                                    type: 'descriptionLineItem',
                                    index: itemIndex,
                                    fieldIndex: parentIndex,
                                    payload: e.target.value,
                                  })
                                }
                                value={description}
                              />
                            </div>
                            <div className="tw-p-3">
                              <Button
                                variant="secondary-gray"
                                size="sm"
                                onClick={() => setDescriptionConfigOpened(!descriptionConfigOpened)}
                              >
                                Ok
                              </Button>
                            </div>
                          </div>
                        </Popover.Dropdown>
                      </Popover>
                    </div>
                  </Tooltip>
                </>
              )}
            </Cell>
          ) : null}
          {showConfidences ? (
            <Cell className="confidence">
              <ThresholdInput
                value={item.confidence}
                onChange={(value) =>
                  dispatch({ type: 'confidenceLineItem', index: itemIndex, fieldIndex: parentIndex, payload: value })
                }
              />
            </Cell>
          ) : null}
          {canEditFields ? (
            <DeleteButtonCell
              disabled={isLastItem}
              onClick={() =>
                dispatch({
                  type: 'deleteLineItem',
                  index: itemIndex,
                  fieldIndex: parentIndex,
                })
              }
              aria-label={t('models-create:fields.removeFieldBtnAriaLabel')}
            />
          ) : null}
        </Row>
      </div>
    </DraggableItemContainer>
  );
}
