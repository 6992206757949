import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const DATE_REGEX = /(\d{1,4})[^\d]+(\d{1,2})[^\d]+(\d{1,4})/;
export function dateBestFit(input: string) {
  let guessedDate: dayjs.Dayjs | undefined;

  if (typeof input !== 'string') return guessedDate;

  let trimmed = input.replace(/\s+/g, '');

  // Unexpected format (?)
  if (!DATE_REGEX.test(trimmed)) {
    const wildGuessDate = Date.parse(trimmed);
    return dayjs(wildGuessDate);
  }

  const parts = DATE_REGEX.exec(trimmed) || [];

  const relevantParts = parts.slice(1, 4);
  const middlePart = relevantParts[1];

  const yearFormat = relevantParts.some((part) => part.length === 4) ? 'YYYY' : 'YY';
  let formats = [
    `DD.MM.${yearFormat}`,
    `DD.MM.${yearFormat}`,
    `DD/MM/${yearFormat}`,
    `DD/MM/${yearFormat}`,
    `DD-MM-${yearFormat}`,
    `DD-MM-${yearFormat}`,
  ];

  // Presumably US format?
  if (Number(middlePart) > 12) {
    formats = [`MM/DD/${yearFormat}`, `MM/DD/${yearFormat}`, `${yearFormat}-DD-MM`];
  }

  guessedDate = dayjs(trimmed, formats);

  return guessedDate;
}
