import { memo, SVGAttributes } from 'react';

export type DoughnutChartProps = {
  value: number;
  title?: string;
  desc?: string;
  strokeWidth?: number;
  showInnerText?: boolean;
  backgroundColor?: string;
  fillColor?: string;
} & SVGAttributes<SVGElement>;

function SVGDoughnutChart({
  value,
  title,
  desc,
  strokeWidth = 15,
  showInnerText,
  backgroundColor = '#EAECF0',
  fillColor = '#15B79E',
  ...rest
}: DoughnutChartProps) {
  const circumference = 2 * Math.PI * 35;
  const percentToCircumference = (percent: number) => (circumference / 100) * percent;
  const offset = percentToCircumference(25);

  return (
    <svg viewBox="0 0 100 100" {...rest}>
      <style>
        {`.donut-percent {
          color: black;
          font-weight: 500;
          font-size: 1em;
        }`}
      </style>
      <title id="title">{title}</title>
      <desc id="desc">{desc}</desc>
      <circle cx="50" cy="50" r="35" fill="#fff" role="presentation" />
      <circle
        cx="50"
        cy="50"
        r="35"
        fill="transparent"
        stroke={backgroundColor}
        strokeWidth={strokeWidth}
        role="presentation"
      />
      <circle
        cx="50"
        cy="50"
        r="35"
        fill="transparent"
        stroke={fillColor}
        // + 1.5 to fix occasional issue with this circle's edge intersecting and/or overlapping with
        // the background, creating a jagged "border" around the background circle.
        // Should possibly be fixed in a better way than to hard code this magic value 🤷
        strokeWidth={strokeWidth + 1.5}
        strokeDasharray={`${percentToCircumference(value)} ${percentToCircumference(100 - value)}`}
        strokeDashoffset={offset}
      />
      {showInnerText && (
        <text y="50%" transform="translate(0, 5)">
          <tspan x="50%" textAnchor="middle" className="donut-percent">
            {value}%
          </tspan>
        </text>
      )}
    </svg>
  );
}

export const MemoizedSVGDoughnutChart = memo(SVGDoughnutChart);
