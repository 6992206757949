import { styled } from '@config/stitches';

export const Box = styled('div', {
  variants: {
    flex: {
      true: {
        display: 'flex',
      },
    },
    flexDirection: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
      },
    },
    flexWrap: {
      true: {
        flexWrap: 'wrap',
      },
    },
    shadow: {
      xs: {
        boxShadow: '$xs',
      },
      sm: {
        boxShadow: '$sm',
      },
      md: {
        boxShadow: '$md',
      },
      lg: {
        boxShadow: '$lg',
      },
      xl: {
        boxShadow: '$xl',
      },
    },
    padding: {
      sm: {
        padding: '$0',
      },
      md: {
        padding: '$3',
      },
      lg: {
        padding: '24px',
      },
      xl: {
        padding: '$5',
      },
    },
  },
});
