import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from './useAuthContext';

export function useUser(userId?: string | null | undefined) {
  const { client } = useAuthContext();
  const result = useQuery({
    queryKey: ['user', userId],
    queryFn: () => {
      if (!userId) throw new Error('userId is required');
      return client!.getUser(userId);
    },
    enabled: Boolean(userId),
    retryDelay: (attempt) => attempt * 1500,
    retry: 12,
  });

  return result;
}
