import { ReactNode, useCallback, useMemo } from 'react';
import PQueue from 'p-queue';

import { BackgroundJobContext, BackgroundJobContextReturnValues } from './BackgroundJobContext';
import { useLazyRef, useLogger } from '@hooks';

export interface BackgroundJobProviderProps {
  children?: ReactNode | null;
}

export function BackgroundJobProvider({ children }: BackgroundJobProviderProps) {
  const queue = useLazyRef(() => new PQueue({ autoStart: true, concurrency: 10 }));
  const { logger } = useLogger();

  const addJob = useCallback(
    (job: () => any) => {
      const returnedPromise = queue.current?.add(() => job());
      logger.debug('Background job added:', returnedPromise);
      return returnedPromise;
    },
    [queue, logger]
  );

  const contextValues: BackgroundJobContextReturnValues = useMemo(() => {
    return { queue: queue.current, addJob };
  }, [queue, addJob]);

  return <BackgroundJobContext.Provider value={contextValues}>{children}</BackgroundJobContext.Provider>;
}
