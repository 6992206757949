import XIcon from '@assets/icon-x-thin.svg?react';
import { Cell } from './Cell';

export type DeleteButtonCell = {
  onClick: () => void;
  disabled?: boolean;
};

export function DeleteButtonCell({ onClick, disabled }: DeleteButtonCell) {
  return (
    <Cell className="tw-max-w-[35px]">
      <button
        onClick={onClick}
        type="button"
        disabled={disabled}
        className="tw-flex tw-flex-1 tw-items-center tw-self-stretch tw-p-0 tw-text-lg tw-text-slate-500 hover:tw-text-slate-900"
      >
        <XIcon />
      </button>
    </Cell>
  );
}
