import { useEffect } from 'react';
import { PrivateProfile } from '@lucidtech/las-sdk-browser';

import { useAuthContext, useProfile } from '@hooks';

export type UseIdentifyFn = (data: PrivateProfile) => void;
/**
 * Hook to identify the user to external APIs once logged in
 * @returns
 */
export function useIdentify(callbackFn: UseIdentifyFn) {
  const { isLoggedIn, client } = useAuthContext();
  const { data } = useProfile('me', {
    enabled: isLoggedIn && client !== null,
  });

  useEffect(() => {
    if (isLoggedIn && data && callbackFn) {
      callbackFn(data);
    }
  }, [isLoggedIn, data, callbackFn]);
}
