import type { ModelList } from '@lucidtech/las-sdk-browser';
import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from './useAuthContext';

// Refetch models every 5 minutes
const MODEL_REFETCH_INTERVAL = 1_000 * 60 * 5;

/**
 * Hook to get models data from API, using useQuery and its cache.
 * @returns
 */
export function useModels() {
  const { client } = useAuthContext();
  const useQueryResult = useQuery({
    queryKey: ['models'],
    queryFn: () => {
      // @TODO: update SDK
      return client?.makeGetRequest<ModelList>('/models', { owner: ['me', 'las:organization:cradl'] });
    },
    refetchInterval: MODEL_REFETCH_INTERVAL,
    refetchIntervalInBackground: true,
    // PS: structuralSharing is great, until it's not.
    // If this is to be enabled again, make sure any update to the models cache properly propogates down
    // to all listeners. This might cause issues with re-ordering of fieldConfig fields for instance.
    structuralSharing: false,
  });
  return useQueryResult;
}
