import { Accordion } from '@mantine/core';

import { styled } from '@config/stitches';

export const AccordionItem = styled(Accordion.Item, {
  borderBottom: '1px solid $gray200',
});

export const AccordionControl = styled(Accordion.Control, {
  backgroundColor: '$gray50',
  padding: '16px',
  color: '$gray900',
  fontWeight: 500,
  display: 'flex',
  width: '100%',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  textAlign: 'left',
  border: 'none',
  gap: '20px',
  '& .mantine-Accordion-label': {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .mantine-Accordion-chevron': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 200ms ease',
    width: '24px',
    minWidth: '24px',
  },
  '& .mantine-Accordion-chevron[data-rotate="true"]': {
    transform: 'rotate(180deg)',
  },
});

export const AccordionPanel = styled(Accordion.Panel, {
  backgroundColor: '$gray25',
  borderTop: '1px solid $gray200',
});
