import { useContext } from 'react';

import { LoggerContext } from '@contexts';

export function useLogger() {
  const logger = useContext(LoggerContext);

  if (logger === undefined) {
    throw new Error('useLogger must be used within a LoggerContextProvider');
  }

  return logger;
}
