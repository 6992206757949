import { Location } from 'react-router-dom';
import { BaseLogger } from '../logger/BaseLogger';

export type PredictionFeedbackEvent = 'Created Prediction Feedback';
export type DatasetEvent = 'Dataset Created' | 'Dataset Deleted';
export type AppClientEvent = 'AppClient Created' | 'AppClient Deleted';
export type WizardEvent =
  | 'Training Wizard Started'
  | 'Training Wizard Exited'
  | 'Training Wizard Dataset Selected'
  | 'Training Wizard Data Source Selected'
  | 'Training Wizard Historical Data Uploaded'
  | 'Training Wizard Manual Data Uploaded'
  | 'Training Wizard Manual Annotation Completed'
  | 'Training Wizard Data Report Created'
  | 'Training Wizard Data Report Errors Viewed'
  | 'Training Wizard Training Created';
export type BaseTrackerEvent = AppClientEvent | DatasetEvent | PredictionFeedbackEvent | WizardEvent | string;

export type BaseTrackerOptions = {
  /** Enable debug logging of events */
  debug?: boolean;
  /** Custom logger instance */
  logger?: BaseLogger;
};

/**
 * Base class for tracking events.
 */
export class BaseTracker {
  debug?: boolean;
  logger?: BaseLogger | Console = console;
  constructor(options?: BaseTrackerOptions) {
    if (options?.debug) {
      this.debug = true;
    }
    if (options?.logger) {
      this.logger = options.logger;
    }
  }

  track(event: BaseTrackerEvent, options?: any) {
    if (this.debug) console.debug(`[TrackingEvent]:`, { event, options });
  }

  identify(userId: string, options?: any) {
    if (this.debug) console.debug(`[TrackingIdentify]: ${userId}`, options);
  }

  page(location: Location, options?: any) {
    if (this.debug) console.debug(`[TrackingPage]:`, location, { options });
  }
}
