import { useMemo } from 'react';
import { useAppClients, useOrganizationQuery } from '@hooks';
import { FLOWS_DEFAULT_APP_CLIENT_DESCRIPTION } from '@config/app';

export type ErrorReason = 'loading' | 'appclient' | 'dataset' | 'secret' | 'workflow';
export type UseCanCreateWorkflowResult = {
  isLoading: boolean;
  canCreateWorkflow: boolean;
  error: ErrorReason | undefined;
};

export function useCanCreateWorkflow(): UseCanCreateWorkflowResult {
  const { data, isLoading } = useOrganizationQuery();
  const { data: appClientsData, isLoading: appClientsIsLoading } = useAppClients();

  const [canCreateWorkflow, error]: [boolean, ErrorReason | undefined] = useMemo(() => {
    if (isLoading || appClientsIsLoading) return [false, 'loading'];
    if (!data || !appClientsData) return [false, 'loading'];

    // Check if we have a default app client, or if not, whether we can create a new one
    const hasDefaultAppClient = appClientsData.appClients.some(
      (appClient) => appClient.description === FLOWS_DEFAULT_APP_CLIENT_DESCRIPTION
    );

    if (!hasDefaultAppClient) {
      const canCreateAppClient = data.numberOfAppClientsCreated < data.numberOfAppClientsAllowed;
      if (!canCreateAppClient) return [false, 'appclient'];
    }

    const canCreateDataset = data.numberOfDatasetsCreated < data.numberOfDatasetsAllowed;
    if (!canCreateDataset) return [false, 'dataset'];

    const canCreateSecret = data.numberOfSecretsCreated < data.numberOfSecretsAllowed;
    if (!canCreateSecret) return [false, 'secret'];

    const canCreateWorkflow = data.numberOfWorkflowsCreated < data.numberOfWorkflowsAllowed;
    if (!canCreateWorkflow) return [false, 'workflow'];

    return [true, undefined];
  }, [data, isLoading, appClientsIsLoading, appClientsData]);

  return { isLoading, canCreateWorkflow, error };
}
