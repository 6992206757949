import { ComponentProps } from 'react';
import { styled } from '@config/stitches';
import RoundLoaderSVG from './round-loader.svg?react';

const StyledRoundLoaderSVG = styled(RoundLoaderSVG);

export type RoundLoaderProps = ComponentProps<typeof StyledRoundLoaderSVG>;
export function RoundLoader(props: RoundLoaderProps) {
  return <StyledRoundLoaderSVG {...props} />;
}
