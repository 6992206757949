import { StitchesBadge, StitchesBadgeProps } from '@components';
import { ComponentProps, ReactNode } from 'react';
import { styled } from '@config/stitches';

const BadgeGroupContainer = styled('div', {
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  padding: '4px 12px 4px 4px',
  borderRadius: '99px',
  mixBlendMode: 'multiply',
  fontWeight: 500,
  '& .inner-badge': {
    gap: '6px',
    '& svg': {
      width: '1em',
      height: '1em',
    },
  },
  defaultVariants: {
    variant: 'primary-dark',
  },
  variants: {
    variant: {
      'error-dark': {
        backgroundColor: '$error50',
        color: '$error700',
        '& .inner-badge': {
          backgroundColor: '$error600',
          color: 'white',
        },
      },
      'primary-light': {
        color: '$primary700',
        backgroundColor: '$primary50',
        '& .inner-badge': {
          backgroundColor: 'white',
          color: '$primary700',
        },
      },
      'primary-dark': {
        color: '$primary700',
        backgroundColor: '$primary50',
        '& .inner-badge': {
          backgroundColor: '$primary600',
          color: 'white',
        },
      },
      gray: {
        backgroundColor: '$gray100',
        color: '$grayblue800',
        '& .inner-badge': {
          backgroundColor: '$grayblue800',
          color: 'white',
        },
      },
      white: {
        backgroundColor: 'white',
        border: '1px solid $gray100',
        color: '$gray900',
      },
    },
  },
});

export type BadgeGroupProps = {
  leading?: boolean;
  badgeContent: ReactNode;
  children: ReactNode;
  iconOnly?: boolean;
  innerBadgeVariant?: StitchesBadgeProps['variant'];
} & ComponentProps<typeof BadgeGroupContainer>;
export function BadgeGroup({
  leading = true,
  iconOnly,
  badgeContent,
  children,
  innerBadgeVariant,
  ...containerProps
}: BadgeGroupProps) {
  return (
    <BadgeGroupContainer {...containerProps}>
      <StitchesBadge
        className="inner-badge"
        iconOnly={iconOnly}
        size={iconOnly ? 'xs' : undefined}
        variant={innerBadgeVariant}
      >
        {badgeContent}
      </StitchesBadge>
      {children}
    </BadgeGroupContainer>
  );
}
