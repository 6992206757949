import LockIcon from '@assets/icon-lock-03.svg?react';
import { StitchesBadge } from '@components/Badge';
import { Tooltip } from '@mantine/core';
import { styled } from '@config/stitches';

const ReadOnlyInputContainer = styled('div', {
  width: '100%',
  display: 'flex',
  border: '1px solid $gray200',
  padding: '0.5rem 0.75rem',
  fontSize: '0.9375rem',
  fontWeight: 400,
  lineHeight: 1.5,
  backgroundColor: 'white',
  alignItems: 'center',
  borderRadius: '0.375rem',
  gap: '0.4rem',
  whiteSpace: 'nowrap',
  color: '$gray500',
  '& svg': {
    width: '1.2em',
    height: '1.2em',
    color: '$gray400',
  },
});

export type ReadOnlyInputProps = {
  name?: string;
};
export function ReadOnlyInput({ name = '' }: ReadOnlyInputProps) {
  return (
    <ReadOnlyInputContainer>
      {name}
      <Tooltip label="Pre-trained fields can not be renamed" withArrow>
        <StitchesBadge
          css={{
            ml: 'auto',
            gap: '0.3em',
            backgroundColor: '$grayblue50',
            color: '$grayblue700',
            fontSize: '0.8rem',
            '& svg': { color: '$gray500' },
          }}
        >
          <LockIcon /> Pre-trained
        </StitchesBadge>
      </Tooltip>
    </ReadOnlyInputContainer>
  );
}
