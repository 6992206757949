import { Link } from 'react-router-dom';
import { styled } from '@config/stitches';
import icon from './icon-chevronright.svg';

const NavContainer = styled('nav', {
  marginBottom: '20px',
});

const BreadcrumbsContainer = styled('ol', {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  fontSize: '0.9rem',
  color: '$gray500',
  fontWeight: 500,
  '& a:link, & a:visited': {
    color: '$gray500',
  },
  '& a:hover': {
    color: '$gray900',
  },
  '& li': {
    display: 'flex',
    alignItems: 'center',
  },
  '& li:not(:last-child):after': {
    content: '',
    display: 'block',
    marginLeft: '$2',
    width: '1.2em',
    height: '1.2em',
    backgroundImage: `url(${icon})`,
    backgroundSize: '1.2em 1.2em',
  },
  '& li.current': {
    backgroundColor: '$gray50',
    color: '$gray700',
    borderRadius: '6px',
    padding: '$1 $2',
  },
});

export type BreadcrumbsProps = {
  parts: Array<{
    url?: string;
    display: string;
  }>;
};

export function Breadcrumbs({ parts }: BreadcrumbsProps) {
  return (
    <NavContainer aria-label="Breadcrumb">
      <BreadcrumbsContainer>
        {parts.map((part) => (
          <li className={part.url ? undefined : 'current'} key={part.display}>
            {part.url ? <Link to={part.url}>{part.display}</Link> : part.display}
          </li>
        ))}
      </BreadcrumbsContainer>
    </NavContainer>
  );
}
