import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAuthContext } from '@hooks/useAuthContext';
import { ModelList } from '@lucidtech/las-sdk-browser';

export function Prefetch() {
  const queryClient = useQueryClient();
  const { client } = useAuthContext();

  useEffect(() => {
    if (client) {
      queryClient.prefetchQuery({
        queryKey: ['models'],
        queryFn: () => client.makeGetRequest<ModelList>('/models', { owner: ['me', 'las:organization:cradl'] }),
      });
      queryClient.prefetchQuery({ queryKey: ['datasets'], queryFn: () => client.listDatasets() });
      queryClient.prefetchQuery({ queryKey: ['users'], queryFn: () => client.listUsers() });
    }
  }, [client, queryClient]);
  return null;
}
