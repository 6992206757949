import { Location } from 'react-router-dom';
import * as rudderanalytics from 'rudder-sdk-js';

import { BaseTracker, BaseTrackerEvent, BaseTrackerOptions } from './BaseTracker';

type RudderStackInstance = typeof rudderanalytics;
type EventOptions = { properties?: Record<string, any>; [k: string]: any };

export interface RudderStackTrackerOptions extends BaseTrackerOptions {
  /** Events to ignore */
  ignoredEvents?: Array<BaseTrackerEvent>;
  /** Events to explicitly track */
  includedEvents?: Array<BaseTrackerEvent>;
  /** Disables sending page event (page views, or route changes) */
  disablePageEvent?: boolean;
}

/**
 * Track events using RudderStack.
 */
export class RudderStackTracker extends BaseTracker {
  private RudderStackInstance: RudderStackInstance;
  private disablePageEvent: boolean = false;
  private deploymentInfo: any = {
    current: { commit: import.meta.env.VITE_CURRENT_COMMIT_SHA, date: import.meta.env.VITE_CURRENT_DEPLOYMENT_DATE },
    previous: { commit: import.meta.env.VITE_PREVIOUS_COMMIT_SHA, date: import.meta.env.VITE_PREVIOUS_DEPLOYMENT_DATE },
  };

  constructor(
    writeKey: string,
    dataPlaneUrl: string,
    options?: RudderStackTrackerOptions & rudderanalytics.loadOptions
  ) {
    super(options);
    this.RudderStackInstance = rudderanalytics;
    this.RudderStackInstance.load(writeKey, dataPlaneUrl, { secureCookie: true, configUrl: options?.configUrl });
    this.disablePageEvent = options?.disablePageEvent || false;
  }

  async identify(userId: string, traits?: any) {
    super.identify(userId, traits);
    return this.RudderStackInstance.identify(userId, traits);
  }

  async track(event: BaseTrackerEvent, options: EventOptions = {}) {
    const allOptions = this._addDeploymentInfoToOptions(options);

    super.track(event, allOptions);
    const { properties, ...context } = allOptions;
    return this.RudderStackInstance.track(event, properties, context);
  }

  async page(location: Location, options: EventOptions = {}) {
    if (this.disablePageEvent) return;

    const allOptions = this._addDeploymentInfoToOptions(options);

    super.page(location, allOptions);
    const { properties, ...context } = allOptions;
    this.RudderStackInstance.page(undefined, undefined, properties, context);
  }

  /**
   * Add deployment information to properties, taken from environment variables
   * added during deployment
   */
  private _addDeploymentInfoToOptions(options: EventOptions): EventOptions {
    const optionsWithDeploymentInfo = {
      ...options,
      properties: { ...(options?.properties || {}), deployment: this.deploymentInfo },
    };
    return optionsWithDeploymentInfo;
  }
}
