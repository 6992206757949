import { ComponentProps, MutableRefObject, useState } from 'react';
import { Checkbox, Group, Collapse, Tooltip, Popover, Text, Textarea, Menu } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FiTrash2 } from 'react-icons/fi';
import { Field as LasField } from '@lucidtech/las-sdk-browser';
import { CSS } from '@dnd-kit/utilities';
import { twMerge } from 'tailwind-merge';

import BankNoteIcon from '@assets/icon-bank-note-03.svg?react';
import CalendarIcon from '@assets/icon-calendar.svg?react';
import CheckIcon from '@assets/icon-check-2.svg?react';
import EmptyPromptIcon from '@assets/magic-wand.svg?react';
import EnumIcon from './icon-enum-field.svg?react';
import GridIcon from '@assets/icon-grid.svg?react';
import HelpIcon from '@assets/icon-help.svg?react';
import IconChevronRight from '@assets/icon-chevron-right.svg?react';
import NullTypeIcon from '@assets/icon-edit-05.svg?react';
import NumbersIcon from '@assets/icon-numbers.svg?react';
import PromptIcon from '@assets/magic-wand-filled.svg?react';
import TagIcon from '@assets/icon-tag-01.svg?react';
import TypeSquareIcon from '@assets/icon-type-square.svg?react';
import styles from './FieldConfig.module.scss';
import { Button } from '@components';
import { Cell } from './Cell';
import { DeleteButtonCell } from './DeleteButtonCell';
import { DragHandle } from './DragHandle';
import { ENUM_OPTION_COLORS } from './common';
import { Field, FieldConfigAction, StateField, TypeOption } from '@components';
import { LineItemFields } from './LineItemFields';
import { ReadOnlyInput } from './ReadOnlyInput';
import { ReadOnlyType } from './ReadOnlyType';
import { Row } from './Row';
import { ThresholdInput } from '@features/flows/components/threshold-modal/threshold-input';
import { styled } from '@config/stitches';
import { useSortable } from '@dnd-kit/sortable';

const EnumContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '25px',
  rowGap: '15px',
  alignItems: 'center',
});

export const InnerContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$gray50',
  gap: '$3',
  padding: '15px 30px',
});

export const DraggableItemContainer = styled('li', {
  backgroundColor: 'white',
  flexDirection: 'column',
  display: 'flex',
  borderTop: '1px solid transparent',
  borderBottom: '1px solid transparent',
  '&.active': {
    opacity: 0.5,
    borderTop: '1px dashed $gray500',
    borderBottom: '1px dashed $gray500',
  },
  [`&.dragging ${InnerContainer}`]: {
    margin: '0 -15px -15px -15px',
  },
  '&.dragging': {
    position: 'relative',
    boxShadow: '$md',
    border: '1px solid $gray200',
    padding: '$2',
    borderRadius: '8px',
    overflow: 'hidden',
    opacity: 0.85,
  },
  '&.active:after': {
    content: ' ',
    zIndex: 10,
    display: 'block',
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    opacity: 1,
    backgroundColor: 'rgb(83, 93, 198, 0.05)',
  },
});

const EnumOption = styled('div', {
  display: 'flex',
  gap: '5px',
  width: 'calc(50% - 15px)',
  alignItems: 'center',
});

export const ButtonContainer = styled('div', {
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  color: '#667085',

  '& button': {
    fontSize: '1.3rem',
  },

  '& button:hover': {
    color: '$gray900',
  },
});

export const CollapseButton = styled('button', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundColor: 'transparent',
  color: '$grayblue500',
  alignSelf: 'flex-start',
  padding: 0,
  gap: '$3',

  '& svg': {
    transition: 'transform 200ms ease-in-out',
    width: '1em',
    height: '1em',
  },
  '&[data-collapsed="true"] svg': {
    transform: 'rotate(90deg)',
  },
});

export const fieldTypeToIcon = {
  amount: BankNoteIcon,
  date: CalendarIcon,
  digits: NumbersIcon,
  enum: TagIcon,
  lines: GridIcon,
  numeric: NumbersIcon,
  string: TypeSquareIcon,
};

function FieldTypeIcon(type?: string | null) {
  if (type) {
    const Icon = fieldTypeToIcon[type as LasField['type']];
    return <Icon className="tw-h-[20px] tw-w-[20px] tw-text-gray-500" />;
  } else {
    return <NullTypeIcon className="tw-h-[20px] tw-w-[20px] tw-text-gray-500" />;
  }
}

function FieldTypeMenuItem(type: string, selected: boolean, dispatchType: () => void) {
  return (
    <Menu.Item onClick={dispatchType} rightSection={selected ? <CheckIcon className="tw-text-primary-600" /> : null}>
      <Group>
        {FieldTypeIcon(type)}
        <Text
          className={twMerge('!tw-text-sm !tw-capitalize', selected ? '!tw-text-primary-600' : '!tw-text-gray-900')}
        >
          {type}
        </Text>
      </Group>
    </Menu.Item>
  );
}

export type FieldItemProps = {
  active?: boolean;
  dragging?: boolean;
  disabledTypes?: Array<TypeOption>;
  item: StateField;
  dispatch: React.Dispatch<FieldConfigAction>;
  itemIndex: number;
  onKeyDownHandler: (fieldIndex: number, event: React.KeyboardEvent<HTMLInputElement>) => boolean;
  tryAddingEnumOption: (fieldIndex: number) => void;
  enumInputRefs: MutableRefObject<Array<HTMLInputElement | null>>;
  showConfidences: boolean;
  canEditFields?: boolean;
  enablePromptConfig?: boolean;
} & ComponentProps<typeof DraggableItemContainer>;
export function FieldItem({
  active,
  dragging,
  disabledTypes,
  dispatch,
  enumInputRefs,
  item,
  itemIndex,
  onKeyDownHandler,
  tryAddingEnumOption,
  showConfidences,
  canEditFields,
  enablePromptConfig,
}: FieldItemProps) {
  const {
    collapsed,
    description,
    name: fieldName,
    type,
    id,
    enum: enumOptions,
    enumUuids,
    fields: lineItemFields,
    readOnly,
    isMultivalue,
  } = item;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const [descriptionConfigOpened, setDescriptionConfigOpened] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const { t } = useTranslation();

  const isLineItem = type === 'lines';
  const readOnlyOrPretrainedLineItem = readOnly;
  const isEnum = type === 'enum';
  const missingType = !type;

  return (
    <DraggableItemContainer
      ref={setNodeRef}
      style={style}
      className={dragging ? 'dragging' : active ? 'active' : undefined}
    >
      <div className="tw-group/main tw-flex tw-items-center">
        <DragHandle {...listeners} {...attributes} dragging={dragging} />
        <Row className="tw-flex-1">
          <Cell className="name tw-min-w-[70%]">
            {readOnlyOrPretrainedLineItem ? (
              <ReadOnlyInput name={fieldName} />
            ) : (
              <input
                value={fieldName}
                className="disabled:!tw-bg-gray-50"
                disabled={!canEditFields}
                readOnly={!canEditFields}
                onChange={(e) => dispatch({ type: 'name', index: itemIndex, payload: e.target.value })}
                placeholder={t('models-create:fields.namePlaceholder')}
                aria-label="Field name"
              />
            )}
          </Cell>
          {canEditFields ? (
            <Cell className="type tw-min-w-[16%] tw-gap-8">
              {readOnlyOrPretrainedLineItem ? (
                <ReadOnlyType type={type as LasField['type']} />
              ) : (
                <>
                  <Menu width={200} classNames={{ dropdown: '!tw-rounded-lg' }}>
                    <Menu.Target>
                      <div
                        className={twMerge(
                          'tw-cursor-pointer tw-rounded-lg tw-border-1.5 tw-p-1.5 tw-shadow-sm hover:tw-bg-gray-50',
                          type ? 'tw-border-gray-200' : 'tw-border-error-500'
                        )}
                      >
                        {FieldTypeIcon(type)}
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {['amount', 'string', 'date', 'numeric', 'enum', 'lines'].map((menuType) =>
                        FieldTypeMenuItem(menuType, menuType === type, () => {
                          dispatch({
                            type: 'type',
                            index: itemIndex,
                            payload: menuType as NonNullable<Field['type']>,
                          });
                        })
                      )}
                      <Menu.Divider />
                      <Menu.Item
                        closeMenuOnClick={false}
                        rightSection={
                          <Tooltip label="Set to true if your field has multiple correct values">
                            <div>
                              <HelpIcon className="tw-h-[16px] tw-w-[16px] tw-text-gray-400" />
                            </div>
                          </Tooltip>
                        }
                      >
                        <Tooltip disabled={!(isEnum || isLineItem)} label="Not available for lines or enum type fields">
                          <div>
                            <Group>
                              <Checkbox
                                classNames={{ input: '!tw-h-[20px] !tw-w-[20px] !tw-p-0' }}
                                disabled={isEnum || isLineItem}
                                checked={isEnum || isLineItem ? false : Boolean(isMultivalue)}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  dispatch({
                                    type: 'isMultivalue',
                                    index: itemIndex,
                                    payload: e.currentTarget.checked,
                                  });
                                }}
                              />
                              <Text className="!tw-text-sm !tw-text-gray-900">Multiple values</Text>
                            </Group>
                          </div>
                        </Tooltip>
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                  {!isLineItem ? (
                    <Tooltip
                      disabled={enablePromptConfig}
                      label="Enable LLM for your model in order to configure prompt"
                    >
                      <div>
                        <Popover
                          disabled={!enablePromptConfig}
                          classNames={{ dropdown: '!tw-p-0 !tw-rounded-lg' }}
                          withArrow
                          shadow="md"
                          opened={descriptionConfigOpened}
                          onChange={setDescriptionConfigOpened}
                          width={300}
                        >
                          <Popover.Target>
                            <div
                              className="tw-cursor-pointer tw-rounded-lg tw-border-1.5 tw-border-gray-200 tw-p-1.5 tw-shadow-sm hover:tw-bg-gray-50"
                              onClick={() => setDescriptionConfigOpened(!descriptionConfigOpened)}
                            >
                              {description ? (
                                <PromptIcon className="tw-h-[20px] tw-w-[20px] tw-text-gray-600" />
                              ) : (
                                <EmptyPromptIcon className="tw-h-[20px] tw-w-[20px] tw-text-gray-200" />
                              )}
                            </div>
                          </Popover.Target>
                          <Popover.Dropdown>
                            <div className="tw-space-y-1">
                              <Group className="!tw-gap-1 tw-border-b-1.5 tw-border-gray-200 tw-p-3">
                                <PromptIcon className="tw-h-[18px] tw-w-[18px] tw-text-gray-600" />
                                <Text className="!tw-text-gray-700">LLM prompt optimization</Text>
                              </Group>
                              <div className="tw-space-y-2 tw-border-b-1.5 tw-border-gray-200 tw-p-3">
                                <Text className="!tw-text-sm !tw-text-gray-600">
                                  Provide additional info about this field to improve accuracy from the LLM
                                </Text>
                                <Textarea
                                  autosize
                                  placeholder="Example: The due date of the invoice. Such as: 01.09.2024, 30 days, September 1. 2024"
                                  onChange={(e) =>
                                    dispatch({ type: 'description', index: itemIndex, payload: e.target.value })
                                  }
                                  value={description}
                                />
                              </div>
                              <div className="tw-p-3">
                                <Button
                                  variant="secondary-gray"
                                  size="sm"
                                  onClick={() => setDescriptionConfigOpened(!descriptionConfigOpened)}
                                >
                                  Ok
                                </Button>
                              </div>
                            </div>
                          </Popover.Dropdown>
                        </Popover>
                      </div>
                    </Tooltip>
                  ) : null}
                </>
              )}
            </Cell>
          ) : null}
          {!isLineItem &&
            (showConfidences ? (
              <Cell className="confidence">
                <ThresholdInput
                  value={item.confidence}
                  onChange={(value) => dispatch({ type: 'confidence', index: itemIndex, payload: value })}
                />
              </Cell>
            ) : null)}
          {canEditFields ? (
            <DeleteButtonCell
              onClick={() => dispatch({ type: 'delete', index: itemIndex })}
              aria-label={t('models-create:fields.removeFieldBtnAriaLabel')}
            />
          ) : null}
        </Row>
      </div>
      {isEnum && (
        <InnerContainer>
          <CollapseButton data-collapsed={collapsed} onClick={() => dispatch({ type: 'toggleCollapse', id })}>
            <IconChevronRight />
            Enumerations ({enumOptions?.length || 0})
          </CollapseButton>
          <Collapse in={Boolean(collapsed)} transitionDuration={200} transitionTimingFunction="ease">
            <EnumContainer>
              {enumOptions?.map((option, localOptionIndex) => (
                <EnumOption key={enumUuids[localOptionIndex]}>
                  <div
                    className={styles.enumOptionIcon}
                    style={{
                      backgroundColor: ENUM_OPTION_COLORS[localOptionIndex % ENUM_OPTION_COLORS.length],
                    }}
                  >
                    <EnumIcon />
                  </div>
                  <input
                    value={option}
                    onChange={(e) =>
                      dispatch({
                        type: 'enumChange',
                        fieldIndex: itemIndex,
                        index: localOptionIndex,
                        payload: e.target.value,
                      })
                    }
                  />
                  <div className={styles.enumDeleteButtonContainer}>
                    <Button
                      onClick={() => dispatch({ type: 'enumRemove', index: itemIndex, payload: localOptionIndex })}
                      variant="plain"
                    >
                      <FiTrash2 />
                    </Button>
                  </div>
                </EnumOption>
              ))}
              <EnumOption>
                <div className={styles.enumOptionIcon}>
                  <EnumIcon />
                </div>
                <input
                  onBlur={() => tryAddingEnumOption(itemIndex)}
                  onKeyDown={(event) => onKeyDownHandler(itemIndex, event)}
                  ref={(el) => (enumInputRefs.current[itemIndex] = el)}
                  placeholder="+ Add option"
                />
                <div className={styles.enumDeleteButtonContainer}></div>
              </EnumOption>
            </EnumContainer>
          </Collapse>
        </InnerContainer>
      )}

      {isLineItem && (
        <LineItemFields
          dispatch={dispatch}
          fields={lineItemFields}
          collapsed={collapsed}
          id={id}
          itemIndex={itemIndex}
          showConfidences={showConfidences}
          canEditFields={canEditFields}
          enablePromptConfig={enablePromptConfig}
        />
      )}
    </DraggableItemContainer>
  );
}
