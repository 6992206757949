import type { Organization } from '@lucidtech/las-sdk-browser';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import { useTracker } from './useTracker';

type UsePageTrackerOptions = {
  disableLocalhost?: boolean;
};
export function usePageTracker({ disableLocalhost }: UsePageTrackerOptions = { disableLocalhost: false }) {
  const location = useLocation();
  const queryClient = useQueryClient();
  const { tracker } = useTracker();

  useEffect(() => {
    const isLocalHost = window.location.href.includes('localhost');
    const shouldEnable = (disableLocalhost && !isLocalHost) || !disableLocalhost;

    if (shouldEnable) {
      const organization = queryClient.getQueryData<Organization | undefined>(['organization']);
      tracker.page(location, {
        properties: { organizationId: organization?.organizationId, isAppClient: false },
        groupId: organization?.organizationId,
      });
    }
  }, [location, disableLocalhost, tracker, queryClient]);
}
