import { FiExternalLink } from 'react-icons/fi';
import { Link, LinkProps } from 'react-router-dom';

import styles from './Button.module.scss';

export type ButtonLinkProps = LinkProps & {
  variant?:
    | 'primary'
    | 'primary-dark'
    | 'secondary-gray'
    | 'secondary'
    | 'secondary-transparent'
    | 'plain'
    | 'secondary-gray-destructive';
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  disabled?: boolean;
  external?: boolean;
};

export function ButtonLink({
  className,
  children,
  variant,
  disabled,
  external,
  to,
  state,
  size = 'md',
  ...rest
}: ButtonLinkProps) {
  const classNames = `${styles.button} ${styles[size]} ${variant ? styles[variant] : ''}${
    className ? ` ${className}` : ''
  }`;
  if (disabled) {
    return <a className={classNames} children={children} {...rest} aria-disabled />;
  } else {
    return external && typeof to === 'string' ? (
      <a href={to} className={classNames} target="_blank" rel="noopener noreferrer">
        {children}
        <FiExternalLink />
      </a>
    ) : (
      <Link className={classNames} to={to} state={state} {...rest} children={children} />
    );
  }
}
