import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Menu, MenuProps, Text } from '@mantine/core';

import { useAuthContext, useProfile } from '@hooks';
import { Button } from '@components/Button';
import { AvatarProfile } from './AvatarProfile';
import { styled } from '@config/stitches';
import OrganizationIcon from '@assets/icon-building-06.svg?react';
import ProfileIcon from '@assets/icon-user-03.svg?react';
import SettingsIcon from '@assets/icon-settings.svg?react';
import BillingIcon from '@assets/icon-credit-card-01.svg?react';
import ValidatorIcon from '@assets/icon-cursor-click-01.svg?react';
import ChevronRightIcon from '@assets/icon-chevron-right.svg?react';
import LogoutIcon from '@assets/icon-logout-01.svg?react';
import ChevronDownIcon from '@assets/icon-chevron-down.svg?react';
import { createCredentials } from '@contexts/AuthProvider';
import { CreateOrganizationModal } from '@components';
import { OrganizationMenu } from './OrganizationMenu';
import {
  DEFAULT_REDIRECT_ROUTE,
  LOCAL_STORAGE_AUTH_TOKEN_PREFIX,
  LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY,
} from '@config/app';

const ProfileButton = styled(Button, {
  display: 'flex',
  flexDirection: 'row',
  '& .chevron-down': {
    transition: 'rotate 150ms ease-in-out',
  },
  '&[aria-expanded="true"] .chevron-down': {
    rotate: '180deg',
  },
});

const StyledDropdown = styled(Menu.Dropdown, {
  backgroundColor: 'white',
  padding: 0,
  position: 'absolute',
  border: '1px solid $gray100',
  boxShadow: '$md',
  borderRadius: '8px',
  minWidth: '220px',

  '& .mantine-Menu-arrow': {
    backgroundColor: 'inherit',
    border: '1px solid $gray100',
    zIndex: 1,
  },

  '& .mantine-Menu-divider': {
    margin: 0,
    borderTop: '1px solid $gray100',
  },

  '& .mantine-Menu-item, & .mantine-Menu-itemIcon, & .mantine-Menu-itemLabel': {
    display: 'flex',
    alignItems: 'center',
  },

  '& .mantine-Menu-itemRightSection': {
    display: 'flex',
    alignItems: 'center',
    ml: 'auto',
  },

  '& .mantine-Menu-item.header': {
    backgroundColor: '$primary25',
  },

  '& .mantine-Menu-item': {
    gap: '$2',
    padding: '10px',
    border: 'unset',
    backgroundColor: 'transparent',
    color: '$gray700',
    lineHeight: 'unset',
    width: '100%',
  },

  '& a:hover, & button:hover': {
    color: '$gray900',
    backgroundColor: '$gray50',
  },
});

const ProfileContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  padding: '10px 15px',
  fontSize: '0.9rem',
  minWidth: '200px',
});

const ProfileInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '3px',

  '& .profile-name': {
    fontWeight: 500,
    color: '$gray900',
  },

  '& .profile-email': {
    color: '$gray500',
  },
});

const transitionProps: MenuProps['transitionProps'] = { transition: 'pop' };

type ProfileMenuProps = {
  compact?: boolean;
};

export function ProfileMenu({ compact }: ProfileMenuProps) {
  const { client, jwt, scopes, setCredentials } = useAuthContext();
  const currentClientId = (jwt as any)?.client_id;
  const [createOrganizationModalOpen, setCreateOrganizationModalOpen] = useState(false);
  const [organizationMenuOpen, setOrganizationMenuOpen] = useState(false);
  const hasAccessToValidator = scopes.includes('workflows:read');
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: meData, isLoading: meIsLoading } = useProfile('me');
  const { data: organizationsData } = useQuery<any>({
    queryKey: ['organizations'],
    queryFn: () => client!.makeGetRequest('/organizations'),
  });

  const onSelectOrg = (clientId: string) => {
    window.localStorage.setItem(LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY, clientId);
    const credentials = createCredentials(clientId);
    const hasStoredJWT = window.localStorage.getItem(`${LOCAL_STORAGE_AUTH_TOKEN_PREFIX}/${clientId}`);
    // Previous JWT with possibly valid auth token and/or refresh token exist for this clientId
    if (hasStoredJWT) {
      setCredentials(credentials);
      queryClient.clear();
      // Might be on a organization specific resource like /models/las:model:abc, redirect to safe place
      navigate(DEFAULT_REDIRECT_ROUTE);
    } else {
      credentials.initiateOAuthFlow();
    }
  };

  return (
    <>
      <Menu
        shadow="md"
        position="bottom-end"
        transitionProps={transitionProps}
        unstyled
        onOpen={() => setOrganizationMenuOpen(false)}
      >
        <Menu.Target>
          <ProfileButton variant="plain">
            <AvatarProfile isLoading={meIsLoading} userData={meData} />
            {!compact ? (
              <>
                <div className="tw-flex tw-w-[160px] tw-flex-col tw-items-start">
                  <Text className="!tw-text-xs !tw-font-medium !tw-text-gray-900">
                    {meData?.givenName} {meData?.familyName}
                  </Text>
                  <Text className="!tw-text-xs !tw-text-gray-600">{meData?.email}</Text>
                </div>
                <ChevronDownIcon className="chevron-down" />
              </>
            ) : null}
          </ProfileButton>
        </Menu.Target>

        <StyledDropdown>
          {organizationMenuOpen ? (
            <OrganizationMenu
              goBack={() => setOrganizationMenuOpen(false)}
              currentOrganizationClientId={currentClientId}
              onSelectOrganization={onSelectOrg}
              openModal={() => setCreateOrganizationModalOpen(true)}
              organizations={organizationsData?.organizations || []}
            />
          ) : (
            <>
              <ProfileContainer>
                <AvatarProfile isLoading={meIsLoading} userData={meData} />
                <ProfileInfo>
                  <span className="profile-name">
                    {meData?.givenName} {meData?.familyName}
                  </span>
                  <span className="profile-email">{meData?.email}</span>
                </ProfileInfo>
              </ProfileContainer>
              <Menu.Divider />
              <Menu.Item component={Link} to="/settings/profile" leftSection={<ProfileIcon />}>
                Your profile
              </Menu.Item>
              <Menu.Item component={Link} to="/settings" leftSection={<SettingsIcon />}>
                Settings
              </Menu.Item>
              <Menu.Item component={Link} to="/settings/billing" leftSection={<BillingIcon />}>
                Billing
              </Menu.Item>
              {hasAccessToValidator && (
                <>
                  <Menu.Divider />
                  <Menu.Item component={Link} to="/validator" leftSection={<ValidatorIcon />}>
                    Go to Validator
                  </Menu.Item>
                </>
              )}
              <Menu.Divider />
              <Menu.Item
                leftSection={<OrganizationIcon />}
                rightSection={<ChevronRightIcon />}
                closeMenuOnClick={false}
                onClick={() => setOrganizationMenuOpen(true)}
              >
                Workspaces
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item component={Link} to="/logout" leftSection={<LogoutIcon />}>
                Logout
              </Menu.Item>
            </>
          )}
        </StyledDropdown>
      </Menu>
      <CreateOrganizationModal
        isOpen={createOrganizationModalOpen}
        onRequestClose={() => setCreateOrganizationModalOpen(false)}
      />
    </>
  );
}
