import { Dataset, DatasetList } from '@lucidtech/las-sdk-browser';
import { ReactNode, useState, useContext } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { useAuthContext } from '@hooks/useAuthContext';
import { wait } from '@utils';
import { Toast } from './Toast';
import { DatasetDeleteContext, DatasetDeletionState } from './DatasetDeleteContext';
import { useDatasets } from '@hooks';

const DEFAULT_MAX_RESULTS = 1000;
export interface DatasetDeleteProviderProps {
  children?: ReactNode | null;
}

export function DatasetDeleteProvider({ children }: DatasetDeleteProviderProps) {
  const [datasets, setDatasets] = useState<DatasetDeletionState>({});
  const { client } = useAuthContext();
  const { data } = useDatasets();
  const queryClient = useQueryClient();

  const addDataset = (datasetId: string, retryDocumentsLeft?: number, callback?: () => void) => {
    let documentsLeft =
      retryDocumentsLeft ||
      data?.datasets.find((apiDataset) => apiDataset.datasetId === datasetId)?.numberOfDocuments ||
      0;
    setDatasets((prev) => ({
      ...prev,
      [datasetId]: {
        isLoading: true,
        documentsLeft,
        startingDocuments: documentsLeft,
      },
    }));
    toast(<Toast documentsTotal={documentsLeft} documentsLeft={documentsLeft} />, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: false,
      closeButton: false,
      closeOnClick: false,
      draggable: false,
      toastId: datasetId,
    });

    deleteDataset(datasetId, undefined, callback).catch((error) => {
      setDatasets((prev) => {
        const prevCopy = { ...prev };
        prevCopy[datasetId].error = error;
        prevCopy[datasetId].isLoading = false;
        // feels bad to do this in here but... need updated values for documentsLeft
        toast.update(datasetId, {
          render: ({ closeToast }) => (
            <Toast
              error={error}
              onRetry={() => addDataset(datasetId, prevCopy[datasetId].documentsLeft)}
              closeToast={closeToast}
            />
          ),
        });

        return prevCopy;
      });
    });
  };

  const setNewDataset = (datasetId: string) => {
    setDatasets((prev) => {
      let documentsLeft = prev[datasetId]?.documentsLeft || 0;
      const documentsTotal = prev[datasetId]?.startingDocuments || 0;
      documentsLeft = documentsLeft >= DEFAULT_MAX_RESULTS ? documentsLeft - DEFAULT_MAX_RESULTS : 0;

      if (documentsTotal > 0) {
        queryClient.setQueryData<DatasetList>(['datasets'], (old) => {
          if (old) {
            const oldDatasets = [...old.datasets];
            const currentDataset = oldDatasets.findIndex((dataset) => dataset.datasetId === datasetId);
            if (currentDataset >= 0) {
              oldDatasets[currentDataset] = { ...oldDatasets[currentDataset], numberOfDocuments: documentsLeft };
              return { ...old, datasets: [...oldDatasets] };
            }
          }
          return { datasets: [], nextToken: null };
        });
      }

      toast.update(datasetId, {
        render: () => <Toast documentsTotal={documentsTotal} documentsLeft={documentsLeft} />,
      });

      return { ...prev, [datasetId]: { ...prev[datasetId], documentsLeft } };
    });
  };

  const deleteDataset = async (datasetId: string, retryFrom?: string, callback?: () => void) => {
    if (!datasetId) {
      throw Error('Missing datasetId');
    } else if (datasets[datasetId]?.isLoading) {
      console.warn('Tried to delete a dataset already in progress');
      return;
    }

    const initialNextToken = retryFrom;
    let response = await client!.deleteDocuments({
      datasetId,
      maxResults: DEFAULT_MAX_RESULTS,
      nextToken: initialNextToken,
    });
    setNewDataset(datasetId);

    while (response.nextToken) {
      response = await client!.deleteDocuments({
        datasetId,
        maxResults: DEFAULT_MAX_RESULTS,
        nextToken: response.nextToken,
      });

      setNewDataset(datasetId);
    }

    // wait for updated dataset numberOfDocuments count
    let WAIT_MS = 1000;
    let TOTAL_WAIT = WAIT_MS;
    const MAX_WAIT_MS = 10000;
    // wait before each call attempt
    await wait(WAIT_MS);
    let updatedDatasetInfo = await client!.makeGetRequest<Dataset>(`/datasets/${datasetId}`);

    // wait until we get numberOfDocuments number === 0 as expected, OR we time out
    while (
      updatedDatasetInfo.numberOfDocuments &&
      updatedDatasetInfo.numberOfDocuments > 0 &&
      TOTAL_WAIT < MAX_WAIT_MS
    ) {
      // exponentially back off
      WAIT_MS *= 1.15;
      TOTAL_WAIT += WAIT_MS;

      await wait(WAIT_MS);
      updatedDatasetInfo = await client!.makeGetRequest<Dataset>(`/datasets/${datasetId}`);
    }

    // if the count is still above 0 at this point, error out
    if (updatedDatasetInfo.numberOfDocuments > 0) {
      throw Error('Dataset count not updated in time');
    }

    // otherwise all should be good, delete the dataset and update state
    await client!.deleteDataset(datasetId);
    if (callback) callback();
    setDatasets((prev) => {
      const { [datasetId]: deletedKey, ...remaining } = prev;
      return remaining;
    });
    setTimeout(() => toast.dismiss(datasetId), 500);

    // update react-query cache
    queryClient.setQueryData<DatasetList>(['datasets'], (old) => {
      if (old) {
        const oldDatasets = [...old.datasets];
        const currentDataset = oldDatasets.findIndex((dataset) => dataset.datasetId === datasetId);
        if (currentDataset >= 0) {
          oldDatasets.splice(currentDataset, 1);
          return { ...old, datasets: [...oldDatasets] };
        }
      }
      return { datasets: [], nextToken: null };
    });

    // update organization query to update numberOfDatasetsCreated
    // wait 500ms to deal with async updating in backend
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['organization'] });
    }, 500);
  };

  const values = { datasets: datasets, addDataset: addDataset };

  return <DatasetDeleteContext.Provider value={values}>{children}</DatasetDeleteContext.Provider>;
}

export function useDatasetDeleteContext() {
  const context = useContext(DatasetDeleteContext);
  if (context === undefined) {
    throw new Error('useDatasetDeleteContext must be used within a DatasetDeleteProvider');
  }
  return context;
}
