import { HTMLProps, forwardRef, useCallback, useImperativeHandle, useRef, useEffect, useState, ReactNode } from 'react';
import { ScrollArea } from '@mantine/core';

import { clamp } from '@utils';
import { styled } from '@config/stitches';

const MultiLineInputContainer = styled('div', {
  '&:focus-within': {
    borderColor: '$primary700',
  },
  border: '1px solid $gray200',
  borderRadius: '0.375rem',
  display: 'flex',
  transition: 'border-color 200ms ease-in-out',
  height: 'calc(1.5rem + 1rem + 2px)',
  maxHeight: 'calc(1.5rem + 1rem + 2px)',
  overflow: 'hidden',
  flex: 1,
  position: 'relative',

  '&.multi-enabled': {
    height: 'unset',
    maxHeight: 'calc(4 * 1.5rem + 2px) ',
    minHeight: '3rem',
  },

  '& textarea': {
    display: 'block',
    whiteSpace: 'nowrap',
    border: 'none',
    resize: 'none',
    outline: 'none',
    lineHeight: 1.5,
    minHeight: 0,
    overflow: 'hidden',
    height: '100%',
    maxHeight: 'calc(1.5rem + 1rem)',
    flex: 1,
    minWidth: '100%',
  },
  '&.multi-enabled textarea': {
    backgroundImage: 'linear-gradient(white 50%, $gray25 50%)',
    backgroundSize: '100% 3rem',
    backgroundAttachment: 'local',
    borderRadiusTopLeft: 'unset',
    borderRadiusBottomLeft: 'unset',
    padding: '0 0 0 $2',
    minHeight: '100%',
    lineHeight: '1.5rem',
    maxHeight: 'unset',
  },

  '& .line-numbers': {
    display: 'none',
    overflow: 'hidden',
  },

  '& .line-numbers > div': {
    padding: '0 $2',
    backgroundColor: '$grayblue50',
  },

  '& .line-numbers > div:nth-child(even)': {
    backgroundColor: '$grayblue100',
  },

  '&.multi-enabled .line-numbers': {
    lineHeight: '1.5rem',
    fontSize: '0.8rem',
    textAlign: 'right',
    color: '$gray300',
    display: 'block',
  },
});

const scrollBarStyles = {
  thumb: {
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
  scrollbar: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover .mantineScrollAreaThumb, & .mantineScrollAreaThumb:hover': {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
  },
};

export type MultiLineInputProps = HTMLProps<HTMLTextAreaElement> & {
  maxLines?: number;
  value?: string;
  children?: ReactNode;
};

function getLineNumbers(value: string) {
  const lines = (value || '').split('\n');
  return new Array(lines.length).fill(undefined).map((_, i) => i + 1);
}

export const MultiLineInput = forwardRef<HTMLTextAreaElement, MultiLineInputProps>(
  ({ value = '', maxLines = 4, children, ...rest }, externalTextAreaRef) => {
    const [lineNumbers, setLineNumbers] = useState<Array<number>>([]);
    const [multipleEnabled, setMultipleEnabled] = useState(false);
    const internalTextAreaRef = useRef<HTMLTextAreaElement>(null);
    const numberRef = useRef<HTMLDivElement>(null);

    useImperativeHandle<HTMLTextAreaElement | null, HTMLTextAreaElement | null>(
      externalTextAreaRef,
      () => internalTextAreaRef.current,
      []
    );

    useEffect(() => {
      setLineNumbers(getLineNumbers(value));
      if (!multipleEnabled && value.includes('\n')) {
        setMultipleEnabled(true);
      } else if (multipleEnabled && !value.includes('\n')) {
        setMultipleEnabled(false);
      }
    }, [value, multipleEnabled]);

    // Set new textarea width
    useEffect(() => {
      if (internalTextAreaRef.current) {
        let transitionIntoMultiLine = false;
        let transitionOutOfMultiLine = false;
        if (numberRef.current?.clientWidth === 0 && value.includes('\n')) {
          // On the first transition from single to multi line input,
          // the gutter with line numbers is not visible, so setting the textarea width
          // actually ends up being too wide, causing a horizontal scroll bar on the first time
          // we switch into multi line mode (until writing another value and this updates)
          transitionIntoMultiLine = true;
        } else if (numberRef.current?.clientWidth !== 0 && !value.includes('\n')) {
          transitionOutOfMultiLine = true;
        }

        if (transitionIntoMultiLine || transitionOutOfMultiLine) {
          // We need to first shrink the textarea in order to get the actual content length,
          // otherwise it will grow infinitely and never shrink
          internalTextAreaRef.current.style.width = '1px';
          internalTextAreaRef.current.style.width =
            internalTextAreaRef.current.scrollWidth - (transitionIntoMultiLine ? 24 : 0) + 'px';
        }
      }
    }, [value, internalTextAreaRef]);

    // Listener for scrollarea to keep line numbers scrolling in sync
    const onScrollSync = useCallback(
      (position: { x: number; y: number }) => {
        if (numberRef.current) {
          numberRef.current.scrollTo({ top: position.y });
        }
      },
      [numberRef]
    );

    return (
      <MultiLineInputContainer
        style={multipleEnabled ? { height: `calc(${clamp(lineNumbers.length, 2, maxLines)} * 1.5rem)` } : undefined}
        className={`multi-line${multipleEnabled ? ' multi-enabled' : ''}`}
      >
        {children}
        <div className="line-numbers" ref={numberRef}>
          {lineNumbers.map((lineNumber, index) => (
            <div key={index}>{lineNumber}</div>
          ))}
        </div>

        <ScrollArea
          styles={scrollBarStyles}
          onScrollPositionChange={onScrollSync}
          style={
            multipleEnabled
              ? { height: `calc(${clamp(lineNumbers.length, 1, maxLines)} * 1.5rem + 2px)`, width: '100%' }
              : { height: '100%', width: '100%' }
          }
        >
          <textarea
            style={multipleEnabled ? { height: `calc(${lineNumbers.length} * 1.5rem)` } : undefined}
            ref={internalTextAreaRef}
            value={value}
            {...rest}
          ></textarea>
        </ScrollArea>
      </MultiLineInputContainer>
    );
  }
);
