import { HTMLAttributes } from 'react';
import UserIcon from './placeholder-avatar.jpg';
import styles from './Avatar.module.css';

export function getInitials(name: string): string {
  const initials = name.split(' ').map((namePart) => namePart.charAt(0).toUpperCase());
  // return first and last name initials only
  if (initials.length > 2) {
    return `${initials[0]}${initials[initials.length - 1]}`;
  }
  return initials.slice(0, 2).join('');
}

export type AvatarProps = {
  name?: string | null;
  avatar?: string | null;
  size?: 'xs' | 'xxs' | 'sm' | 'lg' | 'md' | 'xl' | 'xxl';
  className?: string;
  useInitials?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export function Avatar({ name, avatar, className, size = 'md', useInitials = true, children, ...rest }: AvatarProps) {
  const sizeClass = styles[size];

  if (avatar) {
    return (
      <div className={`${styles.avatar} ${sizeClass}${className ? ` ${className}` : ''}`} {...rest}>
        <img src={avatar} alt={`User avatar for ${name}`} className="rounded" />
        {children}
      </div>
    );
  }

  if (name && useInitials) {
    const initials = getInitials(name);
    return (
      <div className={`${styles.avatar} ${sizeClass}${className ? ` ${className}` : ''}`} {...rest}>
        <div className={`${styles.initials} rounded`}>{initials}</div>
        {children}
      </div>
    );
  }

  return (
    <div className={`${styles.avatar} ${sizeClass}${className ? ` ${className}` : ''}`} {...rest}>
      <img src={UserIcon} alt="Default user icon" className="rounded" />
      {children}
    </div>
  );
}
