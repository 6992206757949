import { ButtonHTMLAttributes } from 'react';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { twMerge } from 'tailwind-merge';

const defaultClassName =
  'group-hover/main:tw-opacity-100 tw-self-stretch tw-shrink-0 tw-text-slate-500 tw-cursor-grab tw-w-8 tw-border-none tw-bg-transparent tw-py-0 tw-px-2 tw-flex tw-items-center tw-justify-center tw-opacity-0 tw-transition-opacity tw-duration-200 tw-ease-in-out';

export type DragHandleProps = { dragging?: boolean } & ButtonHTMLAttributes<HTMLButtonElement>;
export function DragHandle({ dragging, className, ...props }: DragHandleProps) {
  const classNames = twMerge([defaultClassName, dragging && 'tw-cursor-grabbing', className]);
  return (
    <button type="button" className={classNames} {...props}>
      <RxDragHandleDots2 className="tw-w-8" />
    </button>
  );
}
