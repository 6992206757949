import { AuthorizationCodeCredentials, PKCEDerived } from '@lucidtech/las-sdk-browser';
import { Token, TokenStorage } from '@lucidtech/las-sdk-browser';
import { LOCAL_STORAGE_AUTH_TOKEN_PREFIX, LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY } from '@config/app';

export class LocalStorage implements TokenStorage<Token> {
  private keyName: string;

  constructor(keyName = 'AuthToken') {
    this.keyName = keyName;
  }

  getPersistentToken(): Token | null {
    const tokenString = window.localStorage.getItem(this.keyName);

    if (!tokenString) {
      return null;
    }

    const { accessToken, expiration, refreshToken } = JSON.parse(tokenString);

    if (!(accessToken && expiration && refreshToken)) {
      return null;
    }

    return new Token(accessToken, expiration, refreshToken);
  }

  setPersistentToken(t: Token): void {
    let token = t;

    const oldToken = window.localStorage.getItem(this.keyName);
    if (oldToken) {
      const refreshToken = JSON.parse(oldToken).refreshToken;
      token = new Token(t.accessToken, t.expiration, refreshToken);
    }

    const tokenString = JSON.stringify(token);
    window.localStorage.setItem(this.keyName, tokenString);
  }
}

export function createCredentials(clientId?: string | null, code?: PKCEDerived): AuthorizationCodeCredentials {
  const clientIdFromLocalStorage = window.localStorage.getItem(LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY);
  const globalClientId = import.meta.env.VITE_GLOBAL_CLIENT_ID;
  const computedClientId = clientId || clientIdFromLocalStorage || globalClientId;
  const storage = new LocalStorage(`${LOCAL_STORAGE_AUTH_TOKEN_PREFIX}/${clientId}`);
  const isGlobalClientId = computedClientId === globalClientId;
  const redirectUri = isGlobalClientId ? import.meta.env.VITE_GLOBAL_REDIRECT_URI : import.meta.env.VITE_REDIRECT_URI;

  const launchUri = (uri: string): void => {
    window.open(uri, '_self');
  };

  return new AuthorizationCodeCredentials(
    import.meta.env.VITE_API_ENDPOINT!,
    computedClientId,
    import.meta.env.VITE_AUTH_ENDPOINT!,
    redirectUri,
    launchUri,
    code,
    storage,
    import.meta.env.VITE_LOGOUT_REDIRECT_URI!
  );
}
