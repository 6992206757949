import { useMemo } from 'react';

import { useOrganizationQuery } from '@hooks';

export function useAPILimit() {
  const { data, isLoading } = useOrganizationQuery();

  const limitData = useMemo(() => {
    const datasets = {
      used: data?.numberOfDatasetsCreated,
      limit: data?.numberOfDatasetsAllowed,
      full: !isLoading && data && data.numberOfDatasetsCreated >= data.numberOfDatasetsAllowed,
    };

    const appClients = {
      used: data?.numberOfAppClientsCreated,
      limit: data?.numberOfAppClientsAllowed,
      full: !isLoading && data && data.numberOfAppClientsCreated >= data.numberOfAppClientsAllowed,
    };

    const models = {
      used: data?.numberOfModelsCreated,
      limit: data?.numberOfModelsAllowed,
      full: !isLoading && data && data.numberOfModelsCreated >= data.numberOfModelsAllowed,
    };

    const users = {
      used: data?.numberOfUsersCreated,
      limit: data?.numberOfUsersAllowed,
      full: !isLoading && data && data.numberOfUsersCreated >= data.numberOfUsersAllowed,
    };

    const dataBundles = {
      used: data?.monthlyNumberOfDataBundlesCreated,
      limit: data?.monthlyNumberOfDataBundlesAllowed,
      full: !isLoading && data && data.monthlyNumberOfDataBundlesCreated >= data.monthlyNumberOfDataBundlesAllowed,
    };

    const documents = {
      used: data?.monthlyNumberOfDocumentsCreated,
      limit: data?.monthlyNumberOfDocumentsAllowed,
      full: !isLoading && data && data.monthlyNumberOfDocumentsCreated >= data.monthlyNumberOfDocumentsAllowed,
    };

    const predictions = {
      used: data?.monthlyNumberOfPredictionsCreated,
      limit: data?.monthlyNumberOfPredictionsAllowed,
      full: !isLoading && data && data.monthlyNumberOfPredictionsCreated >= data.monthlyNumberOfPredictionsAllowed,
    };

    const trainings = {
      used: data?.monthlyNumberOfTrainingsCreated,
      limit: data?.monthlyNumberOfTrainingsAllowed,
      full: !isLoading && data && data.monthlyNumberOfTrainingsCreated >= data.monthlyNumberOfTrainingsAllowed,
    };

    const fieldPredictions = {
      used: data?.monthlyNumberOfFieldPredictionsUsed,
      limit: data?.monthlyNumberOfFieldPredictionsAllowed,
      full:
        !isLoading && data && data.monthlyNumberOfFieldPredictionsUsed >= data.monthlyNumberOfFieldPredictionsAllowed,
    };

    const pagePredictions = {
      // @ts-expect-error
      used: data?.monthlyNumberOfPagePredictionsUsed,
      // @ts-expect-error
      limit: data?.monthlyNumberOfPagePredictionsAllowed,
      // @ts-expect-error
      full: !isLoading && data && data.monthlyNumberOfPagePredictionsUsed >= data.monthlyNumberOfPagePredictionsAllowed,
    };

    const workflows = {
      used: data?.numberOfWorkflowsCreated,
      limit: data?.numberOfWorkflowsAllowed,
      full: !isLoading && data && data.numberOfWorkflowsCreated >= data.numberOfWorkflowsAllowed,
    };

    return {
      isLoading,
      datasets,
      appClients,
      models,
      users,
      dataBundles,
      predictions,
      documents,
      fieldPredictions,
      trainings,
      pagePredictions,
      workflows,
    };
  }, [data, isLoading]);

  return limitData;
}
