import { format, parseISO, parse } from 'date-fns';
import dayjs from 'dayjs';
// @ts-expect-error
import * as chrono from 'chrono-node';

const parser1 = new chrono.Parser();
parser1.pattern = () => {
  return /(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)\s?(\d{1,2})'(\d{2})/i;
};
parser1.extract = (text: string, ref: any, match: any, opt: any) => {
  const formats = ["MMM d''yy", "MMM dd''yy", "MMMd''yy", "MMMdd''yy"];
  for (const format of formats) {
    const parsedDate = dayjs(parse(text, format, Date.now()));
    if (parsedDate.isValid()) {
      return new chrono.ParsedResult({
        ref: ref,
        text: match[0],
        index: match.index,
        start: {
          day: parsedDate.date(),
          month: parsedDate.month() + 1,
          year: parsedDate.year(),
        },
      });
    }
  }
};
const chronoParser = new chrono.Chrono();
chronoParser.parsers.push(parser1);

export function parseDate(value: string | undefined | null): string | undefined {
  if (!value) return;

  try {
    return format(parseISO(value), 'yyyy-MM-dd');
  } catch (error) {
    console.debug('Could not parse date as ISO date', error);
  }

  try {
    const parsedResult = chronoParser.parse(value, { strict: true })?.[0];
    if (parsedResult) {
      return format(parsedResult.date(), 'yyyy-MM-dd');
    }
  } catch (error) {
    console.debug('Could not parse date', error);
  }
}
