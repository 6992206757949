import type { ComponentProps } from 'react';

import { StitchesBadge } from '@components/Badge';
import { styled } from '@config/stitches';
import AlertCircleIcon from '@assets/icon-alert-circle.svg?react';
import ArrowRightIcon from '@assets/icon-arrow-right.svg?react';

const UpgradeBadge = styled(StitchesBadge, {
  backgroundColor: 'white !important',
  color: '$gray800 !important',
  ml: '0.3em',
  fontSize: '0.85rem',
  gap: '0.3em',
  '&:hover': {
    backgroundColor: '$gray50 !important',
  },
  '&:hover svg': {
    transform: 'translateX(1px)',
  },
  '& svg': {
    transition: 'transform 100ms ease',
    width: '1em',
    height: '1em',
    color: '$gray400',
  },
});

const BadgeContainer = styled(StitchesBadge, {
  backgroundColor: '$error50 !important',
  color: '$rose700 !important',
  alignSelf: 'center',
  fontSize: '0.85rem',
  gap: '0.4em',
  padding: '5px 5px 5px 8px',
  '&.vertical': {
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '8px 5px',
    [`& ${UpgradeBadge}`]: {
      borderRadius: '4px',
    },
  },
});

const StyledAlertIcon = styled(AlertCircleIcon, {
  width: '1.4em',
  height: '1.4em',
  flexShrink: 0,
});

const ContentContainer = styled('span', {
  display: 'flex',
  gap: '0.3em',
  alignItems: 'center',
});

export type ErrorContactBadgeProps = ComponentProps<typeof BadgeContainer> & { direction?: 'vertical' };

export function ErrorContactBadge({ children, direction, ...props }: ErrorContactBadgeProps) {
  return (
    <BadgeContainer className={direction ? 'vertical' : undefined} {...props}>
      <ContentContainer>
        <StyledAlertIcon />
        {children}
      </ContentContainer>
      <a href="mailto:support@cradl.ai">
        <UpgradeBadge>
          Contact support@cradl.ai to increase your limit
          <ArrowRightIcon />
        </UpgradeBadge>
      </a>
    </BadgeContainer>
  );
}
