/**
 * Return array of scopes from JWT
 * @param jwt
 * @returns
 */
export function getScopes(jwt: unknown) {
  const scopes = (jwt as any)?.scope || '';
  if (typeof scopes !== 'string') {
    return [];
  } else {
    const splitScopes = scopes.split(' ').map((scope) => (scope.includes('/') ? scope.split('/')[1] : scope)) || [];
    return splitScopes;
  }
}
