import { HTMLProps, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type SectionHeaderProps = {
  title: string;
  subTitle?: string;
  children?: ReactNode;
  button?: ReactNode;
} & HTMLProps<HTMLElement>;

export function SectionHeader({ title, subTitle, className, button, children, ...rest }: SectionHeaderProps) {
  return (
    <header
      className={twMerge('tw-flex tw-items-end tw-justify-between tw-border-b tw-border-slate-200 tw-pb-7', className)}
      {...rest}
    >
      <div className="tw-flex tw-flex-col tw-gap-4">
        <h1 className="tw-text-2xl tw-font-medium tw-text-slate-900">{title}</h1>
        {subTitle ? <p className="tw-text-slate-600">{subTitle}</p> : null}
      </div>
      {button || null}
    </header>
  );
}
