export type BaseLoggerConstructor = {
  identifier?: string;
};

export abstract class BaseLogger {
  identifier?: string;
  constructor(options?: BaseLoggerConstructor) {
    this.identifier = options?.identifier;
  }
  abstract child(identifier: BaseLoggerConstructor['identifier']): BaseLogger;
  abstract debug(...args: Array<any>): void;
  abstract error(...args: Array<any>): void;
  abstract info(...args: Array<any>): void;
  abstract log(...args: Array<any>): void;
  abstract warn(...args: Array<any>): void;
}
