import { HTMLAttributes } from 'react';

import styles from './Progress.module.css';

export type ProgressProps = HTMLAttributes<HTMLDivElement> & {
  value?: number;
  max?: number;
  percent?: number;
  color: 'error' | 'primary' | 'error-soft' | 'success' | 'success-soft' | 'warn' | 'success-bright' | 'warn-bright';
};

export function Progress({ className, color = 'primary', value = 0, max = 100, percent, ...rest }: ProgressProps) {
  const classNames = `${styles.container} ${className ? className : ''}`;
  let width = 0;
  if (percent) {
    width = percent;
  } else {
    width = (100 / (max || 1)) * value;
  }

  width = width > 100 ? 100 : width;
  width = width < 0 ? 0 : width;
  return (
    <div className={classNames} {...rest}>
      <span style={{ width: `${width}%`, backgroundColor: `var(--color-${color})` }} className={styles.bar} />
    </div>
  );
}
