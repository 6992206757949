import { AppClient, User } from '@lucidtech/las-sdk-browser';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useAuthContext, useProfile, useUsers } from '@hooks';
import { isUser } from '@utils';
import { getAllDateFormats } from '@utils/date-formatting';
import { Avatar } from '../Avatar';
import { styled } from '@config/stitches';

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '$gray500',
  fontSize: '0.8rem',
});

interface Resource {
  createdBy: string | null;
  createdTime: string | null;
  updatedBy: string | null;
  updatedTime: string | null;
}
export type ResourceCreationInfoProps = {
  resource?: Resource;
  readOnly?: boolean;
};
export function ResourceCreationInfo({ resource, readOnly }: ResourceCreationInfoProps) {
  const { client } = useAuthContext();

  const { data: users } = useUsers();
  const { data: appClients } = useQuery({ queryKey: ['appClients'], queryFn: () => client!.listAppClients() });

  const [createdByEntity, updatedByEntity] = useMemo(() => {
    let createdByEntity: undefined | Partial<User & AppClient>;
    let updatedByEntity: undefined | Partial<User & AppClient>;

    if (resource && users && appClients) {
      if (resource.createdBy) {
        if (isUser(resource.createdBy)) {
          createdByEntity = users.users.find((user) => user.userId === resource.createdBy);
        } else {
          createdByEntity = appClients.appClients.find((appClient) => appClient.appClientId === resource.createdBy);
        }
      }

      if (resource.updatedBy) {
        if (isUser(resource.updatedBy)) {
          updatedByEntity = users.users.find((user) => user.userId === resource.updatedBy);
        } else {
          updatedByEntity = appClients.appClients.find((appClient) => appClient.appClientId === resource.updatedBy);
        }
      }
    }

    if (resource?.updatedTime && resource.updatedBy === null) {
      updatedByEntity = createdByEntity;
    }

    return [createdByEntity, updatedByEntity];
  }, [resource, users, appClients]);

  const { iso, relative, string } = getAllDateFormats(resource?.updatedTime || resource?.createdTime);
  const entity = resource?.updatedTime ? updatedByEntity : createdByEntity;
  const { data: profile } = useProfile(entity?.profileId);
  const profileName = `${profile?.givenName || ''}${profile?.familyName ? ` ${profile?.familyName}` : ''}`;

  return (
    <Container>
      {!readOnly && (
        <Avatar
          name={profileName || entity?.name}
          size="xs"
          className="mr-2"
          avatar={profile?.picture}
          title={profileName || entity?.name || entity?.userId || entity?.appClientId}
        />
      )}

      <time dateTime={iso} title={string}>
        {resource?.updatedTime || readOnly ? 'Updated' : 'Created'} {relative}
      </time>
    </Container>
  );
}
