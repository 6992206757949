import { User } from '@lucidtech/las-sdk-browser';
import { ReactNode } from 'react';
import { Tooltip } from '@mantine/core';

import { styled } from '@config/stitches';
import { CopyId, UserItem } from '@components';
import { getAllDateFormats } from '@utils/date-formatting';

export const Aside = styled('aside', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$grayblue25',
  minWidth: '250px',
  maxWidth: '300px',
  borderLeft: '1px solid $gray200',
  '@bp1': {
    width: '250px',
  },
  '@bp3': {
    width: '300px',
  },
});

export const Section = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  borderBottom: '1px solid $gray200',
  '& h2': {
    color: '$gray600',
    fontSize: '0.85rem',
    fontWeight: 600,
    fontStyle: 'normal',
    marginBottom: '$3',
  },
});

const DateInfo = styled('time', {
  display: 'flex',
  gap: '$2',
  alignItems: 'center',
  color: '$gray500',
  '& svg': {
    width: '22px',
    height: '22px',
  },
  fontSize: '0.85rem',
  fontWeight: 500,
});

const StyledFieldList = styled('ul', {
  display: 'flex',
  gap: '$2',
  flexWrap: 'wrap',
  '& li': {
    backgroundColor: '$gray100',
    color: '$gray500',
    fontSize: '0.75rem',
    padding: '$1 $2',
    borderRadius: '100px',
    fontWeight: 500,
  },
});

export type FieldListProps = {
  fields: Array<string>;
};

export function FieldList({ fields }: FieldListProps) {
  return (
    <Section>
      <h2>Labels</h2>
      <StyledFieldList>
        {fields.map((key) => (
          <li key={key}>{key}</li>
        ))}
      </StyledFieldList>
    </Section>
  );
}

export type ResourceIdProps = {
  id: string;
  title: string;
};

export function ResourceId({ title, id }: ResourceIdProps) {
  return (
    <Section>
      <h2>{title}</h2>
      <CopyId text={id} />
    </Section>
  );
}

export type DateSectionProps = {
  date?: ReturnType<typeof getAllDateFormats>;
  icon: ReactNode;
  display?: 'string' | 'iso' | 'relative';
  label: string;
};

export function DateSection({ date, icon, display, label }: DateSectionProps) {
  return (
    <Section>
      <h2>{label}</h2>
      <Tooltip label={date?.string} disabled={!date} withArrow>
        <DateInfo dateTime={date?.iso}>
          {icon} {date?.[display || 'relative'] || 'N/A'}
        </DateInfo>
      </Tooltip>
    </Section>
  );
}

export type CreatedUserProps = {
  user?: User;
};

export function CreatedUser({ user }: CreatedUserProps) {
  return (
    <Section>
      <h2>Created by</h2>
      {user ? (
        <Tooltip label={user.userId} withArrow>
          <UserItem avatarSize="xs" entity={user} css={{ color: '$gray500', fontWeight: 500 }} />
        </Tooltip>
      ) : (
        'Cradl AI'
      )}
    </Section>
  );
}
