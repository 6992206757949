import { HTMLAttributes } from 'react';
import styles from './IconBadge.module.scss';

export type IconBadgeProps = {
  variant?: 'warning' | 'error' | 'primary' | 'info' | 'success' | 'default';
} & HTMLAttributes<HTMLSpanElement>;

export function IconBadge({ className, variant = 'primary', children, ...rest }: IconBadgeProps) {
  const variantClass = variant ? ` ${styles[variant]}` : '';
  const classNames = `${styles.badge}${className ? ` ${className}` : ''}${variantClass}`;
  return (
    <span {...rest} className={classNames}>
      <span className={styles.inner}>{children}</span>
    </span>
  );
}
