import { ComponentProps } from 'react';
import { styled } from '@config/stitches';

export const NewCard = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  borderRadius: '8px',
  border: '1px solid $gray200',
  variants: {
    shadow: {
      xs: {
        boxShadow: '$xs',
      },
      sm: {
        boxShadow: '$sm',
      },
      md: {
        boxShadow: '$md',
      },
      lg: {
        boxShadow: '$lg',
      },
      xl: {
        boxShadow: '$xl',
      },
    },
    padding: {
      sm: {
        padding: '$0',
      },
      md: {
        padding: '$3',
      },
      lg: {
        padding: '24px',
      },
      xl: {
        padding: '$5',
      },
    },
  },
});

export type NewCardProps = ComponentProps<typeof NewCard>;
