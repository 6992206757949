import { HTMLProps } from 'react';
import { twMerge } from 'tailwind-merge';

// export const Row = styled('div', {
//   display: 'flex',
//   gap: '$3',
//   '& .name': {
//     flexBasis: '30%',
//   },
//   '& .description': {
//     flexBasis: '30%',
//   },
//   '& .type': {
//     flexBasis: '30%',
//     maxWidth: '23ch',
//   },
//   '& .button': {
//     minWidth: '35px',
//     maxWidth: '35px',
//   },
// });

const defaultClassNames = 'tw-flex tw-gap-3';

export type RowProps = HTMLProps<HTMLDivElement>;
export function Row({ className, ...rest }: RowProps) {
  const classNames = twMerge([defaultClassNames, className]);
  return <div className={classNames} {...rest} />;
}
