import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { styled } from '@config/stitches';

import ModelsIcon from '@assets/icon-models.svg?react';
import APIIcon from '@assets/icon-api.svg?react';
import FlowIcon from '@assets/icon-data-flow.svg?react';

const NavigationLink = styled(NavLink, {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  fontWeight: 500,
  lineHeight: 1,
  color: '$gray400',
  '&:link, &:visited': {
    color: '$gray400',
  },
  '&:hover, &[aria-current="page"]': {
    color: '$gray25',
  },
  '& svg': {
    width: '1.2em',
    height: '1.2em',
  },
});

export function NavigationLinks() {
  const { t } = useTranslation('navigation');

  return (
    <ul className="tw-mx-20 tw-flex tw-flex-1 tw-gap-6">
      <li>
        <NavigationLink to="/flows">
          <FlowIcon /> {t('flows')}
        </NavigationLink>
      </li>
      <li>
        <NavigationLink to="/models">
          <ModelsIcon /> {t('models')}
        </NavigationLink>
      </li>
      <li>
        <NavigationLink to="/appclients">
          <APIIcon />
          {t('appClients')}
        </NavigationLink>
      </li>
    </ul>
  );
}
