import { useEffect } from 'react';
import { useAuthContext } from '@hooks/useAuthContext';
import { useProfile } from './useProfile';

/**
 * Hook to identify the user to Chatlio live-chat once a user is logged in
 * @returns
 */
export function useIdentifyToChatlio() {
  const { isLoggedIn, client } = useAuthContext();
  const { data } = useProfile('me', {
    enabled: isLoggedIn && client !== null,
  });

  useEffect(() => {
    if (isLoggedIn && data) {
      window._chatlio &&
        window._chatlio.identify(data.profileId, {
          name: `${data.givenName}${data.familyName ? ` ${data.familyName}` : ''}`,
          email: data.email,
        });
    }
  }, [isLoggedIn, data]);
}
