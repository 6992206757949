import { Menu, MenuDropdownProps, MenuProps, MenuTargetProps } from '@mantine/core';

import { styled } from '@config/stitches';

const StyledDropdown = styled(Menu.Dropdown, {
  padding: 0,
  position: 'absolute',
  boxShadow: '0 1px 3px rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0px 20px 25px -5px, rgb(0 0 0 / 4%) 0px 10px 10px -5px',
  borderRadius: '4px',
  '& .mantine-Menu-arrow': {
    backgroundColor: 'inherit',
    zIndex: 1,
  },
  '& .mantine-Menu-divider': {
    margin: '$1 0px',
  },
  '& .mantine-Menu-item, & .mantine-Menu-itemIcon, & .mantine-Menu-itemLabel': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .mantine-Menu-item': {
    gap: '$2',
    padding: '10px',
    backgroundColor: 'transparent',
    lineHeight: 1,
  },
  variants: {
    variant: {
      light: {
        backgroundColor: 'white',
        border: '1px solid $gray300',
        '& .mantine-Menu-arrow': {
          border: '1px solid $gray300',
        },
        '& .mantine-Menu-divider': {
          borderTop: '1px solid $gray300',
        },
        '& a:link, & a:visited': {
          color: '$gray900',
        },
        '& a:hover, & .mantine-Menu-item[data-hovered="true"]': {
          color: '$primary600',
        },
        '& .mantine-Menu-item': {
          color: '$gray900',
          width: '100%',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'light',
  },
});

const transitionProps: MenuProps['transitionProps'] = { transition: 'pop' };

export type MantineDropdownProps = {
  target: MenuTargetProps['children'];
  children: MenuDropdownProps['children'];
} & MenuProps;
export function MantineDropdown({ children, target, ...rest }: MantineDropdownProps) {
  return (
    <Menu shadow="md" position="bottom-end" transitionProps={transitionProps} unstyled {...rest}>
      <Menu.Target>{target}</Menu.Target>
      <StyledDropdown>{children}</StyledDropdown>
    </Menu>
  );
}

export const MenuItem = Menu.Item;
export const MenuDivider = Menu.Divider;
