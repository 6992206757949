import { ComponentProps, HTMLAttributes, useRef } from 'react';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useFocusRing } from '@react-aria/focus';
import { useToggleState } from '@react-stately/toggle';
import { useCheckbox } from '@react-aria/checkbox';
import type { ToggleProps } from '@react-types/checkbox';

import IconCheck from '@assets/icon-check.svg?react';
import { styled } from '@config/stitches';

const CheckboxContainer = styled('label', {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'center',
  border: '1px solid $gray300',
  height: '1.3em',
  width: '1.3em',
  padding: '3px',
  margin: 0,
  backgroundColor: 'white',
  borderRadius: '6px',
  color: 'white',
  flexShrink: 0,
  transition: 'border-color 100ms ease-in-out, background-color 100ms ease-in-out, color 100ms ease-in-out',
  '&[data-checked="true"]': {
    backgroundColor: '$primary50',
    borderColor: '$primary600',
    color: '$primary600',
  },
  '&[data-focus-visible="true"]': {
    boxShadow: '0px 0px 0px 4px rgba(142, 166, 255, 0.3)',
    backgroundColor: 'white',
  },
  '& svg': {
    width: '100%',
    height: '100%',
  },
  '&:not([aria-disabled="true"]):hover': {
    backgroundColor: '$primary100',
    borderColor: '$primary600',
  },
  '&[aria-invalid="true"]': {
    borderColor: '$error600',
  },
  '&[aria-disabled="true"]': {
    backgroundColor: '$gray100',
    cursor: 'not-allowed',
  },
  defaultVariants: {
    variant: 'box',
  },
  variants: {
    scheme: {
      success: {
        '&[data-checked="true"]': {
          backgroundColor: '$success50',
          borderColor: '$success600',
          color: '$success600',
        },
        '&[data-focus-visible="true"]': {
          boxShadow: '0px 0px 0px 4px rgba(142, 166, 255, 0.298)',
          backgroundColor: 'white',
        },
        '&:not([aria-disabled="true"]):hover': {
          backgroundColor: '$success100',
          borderColor: '$success600',
        },
      },
    },
    variant: {
      box: {
        borderRadius: '6px',
      },
      round: {
        borderRadius: '50%',
      },
    },
  },
});

export type CheckboxProps = ToggleProps &
  Pick<HTMLAttributes<HTMLLabelElement>, 'className'> & { invalid?: boolean } & Pick<
    ComponentProps<typeof CheckboxContainer>,
    'css' | 'scheme'
  >;
export function Checkbox({ className, css, scheme, invalid, ...rest }: CheckboxProps) {
  const state = useToggleState(rest);
  const ref = useRef<HTMLInputElement>(null);
  const { inputProps } = useCheckbox(rest, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <CheckboxContainer
      className={className}
      css={css}
      data-checked={state.isSelected}
      aria-disabled={rest.isDisabled}
      data-focus-visible={isFocusVisible}
      aria-invalid={invalid}
      scheme={scheme}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      {state.isSelected ? <IconCheck aria-hidden="true" /> : null}
      {rest.children}
    </CheckboxContainer>
  );
}
