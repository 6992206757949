import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, Outlet, useParams } from 'react-router-dom';

import { Box, Button, Loading, SimpleAlert } from '@components';
import { useAuthContext } from '@hooks';
import { FlowWorkflowContext } from '../../contexts';

/**
 * Route component, should be used for all :flowId/* routes. Will handle loading and
 * checking workflows, and whether :flowId exist, showing appropriate actions and errors.
 * @returns
 */
export function FlowWorkflowGuard() {
  const { client } = useAuthContext();
  const { flowId } = useParams<{ flowId: string }>();

  const {
    data: workflowData,
    isLoading: workflowIsLoading,
    error,
    refetch,
  } = useQuery({ queryKey: ['workflows'], queryFn: () => client!.listWorkflows() });

  const workflow = useMemo(
    () => workflowData?.workflows.find((workflow) => workflow.workflowId === flowId),
    [workflowData, flowId]
  );

  if (workflowIsLoading) {
    return <Loading>Loading workflows...</Loading>;
  }

  if (error && !workflowIsLoading) {
    return (
      <Box flex css={{ maxWidth: '400px', justifyContent: 'center', mx: 'auto', height: '100%', alignItems: 'center' }}>
        <SimpleAlert>
          <Box flex css={{ gap: '$2', alignItems: 'center', '& button': { fontSize: '0.8rem', padding: '$2 $3' } }}>
            Error loading flows <Button onClick={() => refetch()}>Retry?</Button>
          </Box>
        </SimpleAlert>
      </Box>
    );
  }

  if (!workflow) {
    return (
      <Box
        flex
        flexDirection="column"
        css={{
          maxWidth: '400px',
          justifyContent: 'center',
          mx: 'auto',
          height: '100%',
          gap: '1em',
          alignItems: 'center',
          '& a': { fontSize: '0.8em' },
        }}
      >
        <SimpleAlert variant="warning">Sorry, this flow does not exist</SimpleAlert>
        <Link to="/flows">Go back to flow list</Link>
      </Box>
    );
  }

  return (
    <FlowWorkflowContext.Provider value={workflow}>
      <Outlet />
    </FlowWorkflowContext.Provider>
  );
}
