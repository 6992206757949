import type { PredictionResponse } from '@lucidtech/las-sdk-browser';
import { ComponentProps, Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from '@mantine/core';

import { PredictionsLoader } from './PredictionsLoader';
import { NoPredictions } from './NoPredictions';
import { Select, StitchesBadge, Switch } from '@components';
import { NoPredictionsYet } from './NoPredictionsYet';
import { DEFAULT_CONFIDENCE_GOOD_THRESHOLD, LOCAL_STORAGE_VIEW_AS_JSON_KEY } from '@config/app';
import { styled } from '@config/stitches';
import { LabelSettings, normalizePredictionResponse, SimplePrediction } from './normalizePredictionResponse';
import { JSONView } from './JSONView';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  maxWidth: '100%',

  '& .body': {
    padding: '$4',
    position: 'relative',
    flex: 1,
  },

  '.predictions': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: '16px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
  },
});

const Header = styled('header', {
  padding: '$4 $5',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '$gray25',
  borderBottom: '1px solid $gray200',

  '& h2': {
    fontSize: '0.9rem',
  },

  '& .jsonSelect': {
    display: 'flex',
    alignItems: 'center',
    gap: '$2',

    '& label': {
      margin: 0,
      color: '$gray700',
      fontSize: '0.8rem',
      fontWeight: 500,
    },
  },
});

const PredictionItem = styled('div', {
  '& .predictionLabel': {
    fontWeight: 400,
    fontSize: '0.8rem',
    marginBottom: '$1',
    color: '$grayblue500',
  },

  '& button': {
    width: '100%',
    padding: '0 $2 0 0',
  },

  '& button > div:first-of-type': {
    flex: 1,
  },

  '& button + ul li': {
    padding: '0 calc(1.75em + 8px) 0 0',
  },

  '& ul li span': {
    flexShrink: 0,
    flexBasis: 'auto',
  },

  '& .predictionValue': {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '$2',
    padding: '$2 0 $2 $2',
    marginBottom: 0,
    color: '$gray900',
    fontSize: '0.9rem',
  },

  '& .raw-value': {
    whiteSpace: 'pre-wrap',
  },
});

const EmptyPrediction = styled('div', {
  padding: '$2 0 $2 $2',
  border: '1px solid $gray200',
  borderRadius: '0.375rem',
  backgroundColor: '$gray50',
  color: '$gray300',
  fontSize: '0.9rem',
});

const RowItem = styled(Accordion.Item, {
  borderBottom: 'none',
  '&[data-active="true"] + .mantine-Accordion-item': {
    borderTop: '1px solid $gray200',
  },
  '& .mantine-Accordion-control': {
    backgroundColor: '$gray50',
    color: '$gray500',
    justifyContent: 'flex-start',
    padding: '$1',
    fontSize: '0.7rem',
    borderBottom: '1px solid $gray200',
  },
  '& .mantine-Accordion-control div.mantine-Accordion-chevron': {
    flex: 0,
    marginRight: '$1',
  },
});
const StyledAccordion = styled(Accordion, {
  borderRadius: '6px',
  border: '1px solid $gray200',
});

const getPercentage = (confidenceValue: number): string => (confidenceValue * 100).toFixed(2);

type ConfidenceProps = {
  confidence: number;
  variant: 'success' | 'error';
};
function Confidence({ confidence, variant = 'success' }: ConfidenceProps) {
  return (
    <StitchesBadge variant={variant} css={{ fontSize: '0.75rem', fontWeight: 400 }}>
      {getPercentage(confidence)}%
    </StitchesBadge>
  );
}

const predictionLoaders = [<PredictionsLoader key="1" />, <PredictionsLoader key="2" />, <PredictionsLoader key="3" />];

function getDefaultJsonViewPreferences() {
  const savedViewJson = localStorage.getItem(LOCAL_STORAGE_VIEW_AS_JSON_KEY);
  return savedViewJson === 'true';
}

function formatItem(item?: SimplePrediction) {
  return (
    <span className="predictionValue">
      <span className="raw-value">{item?.value}</span>
      <Confidence
        variant={(item?.confidence || 0) >= DEFAULT_CONFIDENCE_GOOD_THRESHOLD / 100 ? 'success' : 'error'}
        confidence={item?.confidence || 0}
      />
    </span>
  );
}

export type PredictionCardProps = {
  isLoading?: boolean;
  predictionResponse?: PredictionResponse;
  /**
   * Fields to show/hide, whether a prediction exists or not.
   * Ex: { 'account_id': true, 'line_items': { 'product_name': true, 'qty': true, 'price': true } }
   */
  fields?: LabelSettings;
} & ComponentProps<typeof Container>;
export function PredictionCard({ isLoading, predictionResponse, fields = {}, ...rest }: PredictionCardProps) {
  // Attempt to load JSON view preference from local storage,
  // otherwise set to default value
  const [viewJson, setViewJson] = useState(getDefaultJsonViewPreferences);

  const { t } = useTranslation('testModel');

  const onViewJsonChange = (checked: boolean) => {
    localStorage.setItem(LOCAL_STORAGE_VIEW_AS_JSON_KEY, checked.toString());
    setViewJson(checked);
  };

  const fieldPredictions = useMemo(() => {
    return predictionResponse ? normalizePredictionResponse(predictionResponse, fields) : {};
  }, [predictionResponse, fields]);

  return (
    <Container {...rest}>
      <Header>
        <h2>{t('predictionsHeader')}</h2>
        <div className="jsonSelect">
          <Switch id="showJson" checked={viewJson} onChange={onViewJsonChange} />
          <label htmlFor="showJson">{t('jsonSwitchLabel')}</label>
        </div>
      </Header>

      <div className="body">
        {!isLoading && !predictionResponse && <NoPredictionsYet />}
        {!isLoading && predictionResponse && predictionResponse.predictions?.length === 0 && !viewJson && (
          <NoPredictions />
        )}
        <div className="predictions">
          {isLoading && viewJson && <PredictionsLoader json />}
          {isLoading && !viewJson && predictionLoaders}
          {!isLoading && viewJson && predictionResponse && (
            <JSONView json={predictionResponse} goodConfidenceThreshold={DEFAULT_CONFIDENCE_GOOD_THRESHOLD} />
          )}
          {!isLoading &&
            !viewJson &&
            Object.entries(fieldPredictions).map(([field, predictions]) => {
              const isLineItemField = predictions.some(
                (prediction) => !Object.hasOwn(prediction, 'value') && !Object.hasOwn(prediction, 'confidence')
              );

              if (isLineItemField) {
                return (
                  <PredictionItem key={`${predictionResponse?.predictionId}-${field}`}>
                    <label htmlFor={field} className="predictionLabel">
                      {field}
                    </label>
                    <StyledAccordion
                      chevronPosition="left"
                      multiple
                      defaultValue={predictions.map((_item, lineIndex) => `row-${lineIndex + 1}`)}
                    >
                      {predictions.map((row, lineNumber) => (
                        <RowItem value={`row-${lineNumber + 1}`} key={`row-${lineNumber + 1}`}>
                          <Accordion.Control>Row {lineNumber + 1}</Accordion.Control>
                          <Accordion.Panel>
                            {Object.entries(row).map(([lineItemField, lineItemPredictions]) => (
                              <Fragment key={`row-${lineNumber}-${lineItemField}`}>
                                <label htmlFor={lineItemField} className="predictionLabel">
                                  {lineItemField}
                                </label>
                                {lineItemPredictions.length === 0 ? (
                                  <EmptyPrediction>No predictions</EmptyPrediction>
                                ) : (
                                  <Select
                                    id={lineItemField}
                                    options={lineItemPredictions as Array<SimplePrediction>}
                                    itemDisplayFormat={formatItem}
                                    selectedItemDisplayFormat={formatItem}
                                    initialSelectedItem={lineItemPredictions[0]}
                                  />
                                )}
                              </Fragment>
                            ))}
                          </Accordion.Panel>
                        </RowItem>
                      ))}
                    </StyledAccordion>
                  </PredictionItem>
                );
              } else {
                return (
                  <PredictionItem key={`${predictionResponse?.predictionId}-${field}`}>
                    <label htmlFor={field} className="predictionLabel">
                      {field}
                    </label>
                    {predictions.length === 0 ? (
                      <EmptyPrediction>No predictions</EmptyPrediction>
                    ) : (
                      <Select
                        id={field}
                        options={predictions as Array<SimplePrediction>}
                        itemDisplayFormat={formatItem}
                        selectedItemDisplayFormat={formatItem}
                        initialSelectedItem={predictions[0] as SimplePrediction | undefined}
                      />
                    )}
                  </PredictionItem>
                );
              }
            })}
        </div>
      </div>
    </Container>
  );
}
