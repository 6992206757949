import { FiPlus } from 'react-icons/fi';
import { ButtonLink, PaddingContainer } from '@components';
import { FlowListHeader } from '../flow-list/flow-list-header';

import Illustration from './flow-access-illustration.svg?react';
import DataFlow from '@assets/icon-data-flow.svg?react';

export function RequestAccess() {
  return (
    <PaddingContainer>
      <FlowListHeader className="tw-mb-5" showButton={false} />
      <div className="tw-flex tw-max-w-6xl tw-rounded-lg tw-border tw-border-slate-200 tw-p-12 tw-pr-0 tw-shadow-md">
        <div className="tw-flex tw-w-80 tw-flex-col tw-gap-4 tw-self-start">
          <figure className="tw-inline-flex tw-items-center tw-justify-center tw-self-start tw-rounded-lg tw-border tw-border-slate-200 tw-p-3">
            <DataFlow className="tw-h-5 tw-w-5" />
          </figure>
          <header className="tw-mb-3 tw-flex tw-flex-col tw-gap-2">
            <h2>Flows is available by request</h2>
            <p className="tw-text-sm tw-text-slate-600">
              Flow builder with Human Validation UI is currently only available upon request.
            </p>
          </header>
          <ButtonLink className="tw-self-start" to="https://form.typeform.com/to/rB5CDehK" target="cradl-flow-access">
            <FiPlus /> Request access
          </ButtonLink>
        </div>
        <Illustration className="tw-ml-auto" />
      </div>
    </PaddingContainer>
  );
}
