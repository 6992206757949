const API_ERROR_REGEX =
  /\[(?:InternalServerError|BadRequest|NotFound|UnsupportedMediaType|Forbidden|GatewayTimeout)\]\s*/;

function removeApiErrorFormatting(errorMessage: string): string {
  if (typeof errorMessage !== 'string') return 'Could not parse error message';
  return errorMessage.replace(API_ERROR_REGEX, '');
}

export function parseAxiosError(error: any): string {
  try {
    if (error?.response?.data?.message?.startsWith('[GatewayTimeout]')) {
      return 'API request timed out';
    }
    if (error?.response?.data?.message) {
      return removeApiErrorFormatting(error.response.data.message);
    } else if (error?.request?.statusText) {
      return (error.request as XMLHttpRequest).statusText;
    } else if (error?.message) {
      return `Unknown API error: ${error.message}`;
    }
  } catch (e) {
    console.debug('Unknown API error:', e);
  }

  return 'Unknown API error';
}
