import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { MockedRequest } from 'msw';
import { MantineProvider } from '@mantine/core';

import mantineThemeOverride from './mantine.theme';

import '/node_modules/flag-icons/css/flag-icons.min.css';
// Global CSS rules
// This needs to be imported before the App in order to keep global CSS from overriding
// styles
import './index.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { globalStyles } from '@config/stitches';

import { App } from './App';
import { AuthProvider } from '@contexts/AuthProvider';
import { DEFAULT_STALE_TIME } from '@config/app';
import * as Sentry from '@sentry/react';

let sentryIntegrations = [
  Sentry.browserTracingIntegration(),
  Sentry.replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
  }),
  Sentry.reactRouterV6BrowserTracingIntegration({
    useEffect: React.useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
  }),
];

if (import.meta.env.VITE_SENTRY_PROFILING === 'true') {
  console.log('Enabling Sentry profiling');
  sentryIntegrations.push(Sentry.browserProfilingIntegration());
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: sentryIntegrations,
  // Performance Monitoring
  tracesSampleRate: 1.0,
  tracePropagationTargets: import.meta.env.VITE_SENTRY_TARGETS?.split(','),
  tunnel: import.meta.env.VITE_SENTRY_TUNNEL,
  // Session Replay
  replaysSessionSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
  replaysOnErrorSampleRate: 1.0,
  // Profiling
  profilesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE),
});

globalStyles();

// i18n configuration for translations/strings
import './locales/i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_STALE_TIME,
    },
  },
});

function renderApp() {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <MantineProvider theme={mantineThemeOverride}>
        <AuthProvider>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </BrowserRouter>
        </AuthProvider>
      </MantineProvider>
    </React.StrictMode>
  );
}

// If we're running in development mode, AND session is set to be mocked,
// enable mock service workers (see https://mswjs.io/)
// This avoids importing any mock handlers/fixtures except when truly needed
const shouldMock = sessionStorage.getItem('mock') === 'true';
if (import.meta.env.DEV && shouldMock) {
  const allowUnmocked = sessionStorage.getItem('mock-allow-unmocked') === 'true';
  import('./mocks/browser')
    .then(({ worker }) => {
      worker.start({
        quiet: true,
        onUnhandledRequest(req: MockedRequest) {
          const apiEndPoint = import.meta.env.VITE_API_ENDPOINT;
          const reqIsApi = apiEndPoint.includes(req.url.hostname);
          if (shouldMock && reqIsApi && !allowUnmocked) {
            throw Error('Unhandled request');
          }
        },
      });
    })
    .then(() => {
      renderApp();
    });
} else {
  renderApp();
}
