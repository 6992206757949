import { ComponentProps } from 'react';
import { styled } from '@config/stitches';

export type StitchesBadgeProps = ComponentProps<typeof BadgeBase>;

const BadgeBase = styled('span', {
  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: '100px',
  padding: '3px 7px',
  fontWeight: 500,
  defaultVariants: {
    variant: 'default',
  },
  variants: {
    iconOnly: {
      true: {
        padding: '4px',
      },
    },
    size: {
      xs: {
        padding: '4px',
        '& svg': {
          width: '12px',
          height: '12px',
        },
      },
      sm: {
        padding: '4px',
        '& svg': {
          width: '16px',
          height: '16px',
        },
      },
    },
    variant: {
      default: {
        backgroundColor: '$gray200',
        color: '$gray700',
      },
      info: {
        backgroundColor: '$primary50',
        color: '$primary700',
      },
      success: {
        backgroundColor: '$success50',
        color: '$success700',
      },
      'success-teal': {
        backgroundColor: '$teal500',
        color: 'white',
      },
      'primary-indigo': {
        backgroundColor: '$indigo700',
        color: 'white',
      },
      'primary-dark': {
        backgroundColor: '$grayblue900',
        color: 'white',
      },
      warning: {
        backgroundColor: '$warning50',
        color: '$warning700',
      },
      error: {
        backgroundColor: '$error50',
        color: '$error700',
      },
    },
  },
});

export { BadgeBase as StitchesBadge };
