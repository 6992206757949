import { BaseLogger, BaseLoggerConstructor } from './BaseLogger';

export class SimpleLogger extends BaseLogger {
  child(identifier: BaseLoggerConstructor['identifier']) {
    const newIdentifier = this.identifier ? `${this.identifier}: ${identifier}` : identifier;
    return new SimpleLogger({ identifier: newIdentifier });
  }

  get prefix() {
    return this.identifier ? `[${this.identifier}]` : '';
  }

  debug(...args: Array<any>) {
    console.debug(this.prefix, ...args);
  }

  error(...args: Array<any>) {
    console.error(this.prefix, ...args);
  }

  info(...args: Array<any>) {
    console.info(this.prefix, ...args);
  }

  log(...args: Array<any>) {
    console.log(this.prefix, ...args);
  }

  warn(...args: Array<any>) {
    console.warn(this.prefix, ...args);
  }
}
