import { createContext } from 'react';

export interface FlowTraining {
  dataBundleIsLoading: boolean;
  trainingId: string | null;
}

export interface FlowTrainingContextReturnValues {
  flows: Record<string, FlowTraining>;
  train: (modelId: string, datasetIds: Array<string>, name?: string, metadata?: any) => Promise<void>;
}

export const FlowTrainingContext = createContext<FlowTrainingContextReturnValues | undefined>(undefined);
