import { DataBundle } from '@lucidtech/las-sdk-browser';
import { useState, useEffect, useRef } from 'react';
import { FiBarChart2 } from 'react-icons/fi';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Card, Progress } from '@components';
import styles from './LoadingEstimation.module.scss';
import { IconBadge } from '@components/Badge/IconBadge';
import { clamp } from '@utils';

const ESTIMATED_DOCUMENTS_PER_SECOND = 850;
// rough constant time overhead for >= 1000 documents
const CONSTANT_SECONDS = 60;
// when document count is under 1000
const CONSTANT_SECONDS_LOW_DOCUMENT_COUNT = 10;

export type LoadingEstimationProps = {
  dataBundle: DataBundle;
};

export const LoadingEstimation = ({ dataBundle }: LoadingEstimationProps) => {
  const [countdown, setCountdown] = useState<undefined | number>(undefined);
  const intervalId = useRef<ReturnType<typeof setInterval> | null>(null);
  const { t } = useTranslation('models');
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (dataBundle && !intervalId.current) {
      const documentCount = dataBundle.datasets.reduce((acc, dataset) => acc + dataset.numberOfDocuments, 0) || 0;
      const CONSTANT_TIME = documentCount >= 1000 ? CONSTANT_SECONDS : CONSTANT_SECONDS_LOW_DOCUMENT_COUNT;
      const originalSecondsToComplete = CONSTANT_TIME + documentCount / ESTIMATED_DOCUMENTS_PER_SECOND;
      let secondsToComplete = originalSecondsToComplete;

      // we might not be viewing this immediately after creation of the data bundle,
      // so we should compensate based on the createdTime
      const now = dayjs();
      const created = dayjs(dataBundle.createdTime);

      if (created.isBefore(now)) {
        const differenceInSeconds = now.unix() - created.unix();
        secondsToComplete =
          differenceInSeconds > secondsToComplete ? 0 : Math.ceil(secondsToComplete - differenceInSeconds);
      }

      // if there is time left
      if (secondsToComplete > 0) {
        // set initial countdown
        setCountdown(secondsToComplete);
        const percentPerSecond = 100 / (originalSecondsToComplete || 1);
        const elapsedSeconds = originalSecondsToComplete - secondsToComplete;
        setPercent(percentPerSecond * elapsedSeconds);
        // start timer to decrement countdown every second
        intervalId.current = setInterval(() => {
          setCountdown((prev) => (prev && prev > 0 ? prev - 1 : 0));
          setPercent((prev) => clamp(prev < 100 ? prev + percentPerSecond : 100, 0, 100));
        }, 1000);
      } else {
        setPercent(100);
      }
    }
  }, [dataBundle, intervalId]);

  // clear interval if we reach zero
  useEffect(() => {
    if (countdown && countdown <= 0) {
      intervalId.current && clearInterval(intervalId.current);
    }
  }, [countdown]);

  useEffect(() => {
    return () => {
      intervalId.current && clearInterval(intervalId.current);
    };
  }, [intervalId]);

  const minutes = countdown && countdown >= 0 ? Math.floor(countdown / 60) : 0;
  const seconds = countdown && countdown >= 0 ? Math.floor(countdown % 60) : 0;

  return (
    <Card className={styles.container}>
      <div>
        <IconBadge variant="info">
          <FiBarChart2 />
        </IconBadge>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.header}>
          <h3>{t('dataBundles.processingHeader')}</h3>
        </div>
        <p>{t('dataBundles.processingText')}</p>
        <div className={styles.progressContainer}>
          <Progress percent={percent} color="primary" />
          <span>{Math.round(percent)}%</span>
        </div>
        <p>
          <span className={styles.estimate}>{t('dataBundles.processingEstimate')}</span>
          {countdown && countdown >= 0 ? (
            <>
              <span className="mr-2">{t('dataBundles.processingMinute', { count: minutes })}</span>
              <span>{t('dataBundles.processingSecond', { count: seconds })}</span>
            </>
          ) : (
            <span>{t('dataBundles.processingOverdueText')}</span>
          )}
        </p>
      </div>
    </Card>
  );
};
