import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Layout } from '@components';
import { FlowWorkflowGuard } from './views/flow-workflow-guard';
import { FlowAccessGuard, RequestAccess } from './views/flow-access-guard';

const FlowList = lazy(() => import('./views/flow-list'));
const FlowTemplates = lazy(() => import('./views/flow-templates'));
const FlowDetails = lazy(() => import('./views/flow-details'));
const FlowNew = lazy(() => import('./views/flow-new'));

export function FlowRoutes() {
  return (
    <Routes>
      <Route element={<FlowAccessGuard />}>
        <Route element={<Layout />}>
          <Route index element={<FlowList />} />
          <Route path="templates" element={<FlowTemplates />} />
        </Route>
        <Route path="new" element={<FlowNew />} />
        <Route element={<FlowWorkflowGuard />}>
          <Route path=":flowId/*" element={<FlowDetails />} />
        </Route>
      </Route>
      <Route element={<Layout />}>
        <Route path="access" element={<RequestAccess />} />
      </Route>
    </Routes>
  );
}
