import { SimpleAlert } from '@components/Alert';
import { ComponentType } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Button } from '../Button';
import errorIcon from './assets/error-icon.svg';
import styles from './ErrorBoundary.module.scss';
import * as Sentry from '@sentry/react';

type SentryErrorFallbackProps = Omit<FallbackProps, 'resetErrorBoundary'> & {
  componentStack: string;
  eventId: string;
  resetError: () => void;
};

type ErrorFallbackProps = SentryErrorFallbackProps & {
  showStack?: boolean;
};

function ErrorFallback({ error, resetError, showStack }: ErrorFallbackProps) {
  return (
    <div role="alert" className={styles.container}>
      <img src={errorIcon} alt="Error icon" className={styles.icon} />
      <h2 className={styles.header}>Error loading page</h2>
      <p className={styles.text}>Something went wrong.</p>
      <Button onClick={resetError} className={styles.button} type="button">
        Reload page
      </Button>
      {showStack && (
        <details>
          <summary>Show error message</summary>
          <SimpleAlert variant="error">
            <pre>{error.message}</pre>
          </SimpleAlert>
        </details>
      )}
    </div>
  );
}

export type ErrorBoundaryProps = Sentry.ErrorBoundaryProps & {
  errorComponent?: ComponentType<ErrorFallbackProps>;
};

export function ErrorBoundary({ children, errorComponent: ErrorDisplay = ErrorFallback, ...rest }: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={(props: SentryErrorFallbackProps) => <ErrorDisplay showStack {...props} />}
      {...rest}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
