import { ReactNode } from 'react';

import { Spinner } from '../';
import { styled } from '@config/stitches';

const LoadingContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  fontSize: '2rem',
  gap: '1rem',
  '& small': {
    fontSize: '0.9rem',
    color: '$gray500',
  },
});

export type LoadingProps = {
  children?: ReactNode;
};
export function Loading({ children }: LoadingProps) {
  return (
    <LoadingContainer>
      <Spinner />
      <small>{children}</small>
    </LoadingContainer>
  );
}
