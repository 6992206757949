import ContentLoader from 'react-content-loader';

import styles from './PredictionCard.module.scss';

export type PredictionsLoaderProps = {
  json?: boolean;
};
export function PredictionsLoader({ json }: PredictionsLoaderProps) {
  if (json) {
    return (
      <div className="flex col">
        <ContentLoader
          speed={2}
          width={100}
          height={14}
          viewBox={`0 0 100 14`}
          backgroundColor="rgba(227, 235, 246, 0.466)"
          foregroundColor="rgba(191, 213, 243, 0.445)"
          className={styles.jsonLoaderRow}
        >
          <rect x="0" y="0" rx="5" ry="5" width={100} height={14} />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width={200}
          height={14}
          viewBox={`0 0 200 14`}
          backgroundColor="rgba(227, 235, 246, 0.466)"
          foregroundColor="rgba(191, 213, 243, 0.445)"
          className={styles.jsonLoaderRow}
        >
          <rect x="8" y="0" rx="5" ry="5" width={200} height={14} />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width={150}
          height={14}
          viewBox={`0 0 150 14`}
          backgroundColor="rgba(227, 235, 246, 0.466)"
          foregroundColor="rgba(191, 213, 243, 0.445)"
          className={styles.jsonLoaderRow}
        >
          <rect x="8" y="0" rx="5" ry="5" width={150} height={14} />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width={190}
          height={14}
          viewBox={`0 0 190 14`}
          backgroundColor="rgba(227, 235, 246, 0.466)"
          foregroundColor="rgba(191, 213, 243, 0.445)"
          className={styles.jsonLoaderRow}
        >
          <rect x="16" y="0" rx="5" ry="5" width={190} height={14} />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width={80}
          height={14}
          viewBox={`0 0 80 14`}
          backgroundColor="rgba(227, 235, 246, 0.466)"
          foregroundColor="rgba(191, 213, 243, 0.445)"
          className={styles.jsonLoaderRow}
        >
          <rect x="8" y="0" rx="5" ry="5" width={80} height={14} />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width={145}
          height={14}
          viewBox={`0 0 145 14`}
          backgroundColor="rgba(227, 235, 246, 0.466)"
          foregroundColor="rgba(191, 213, 243, 0.445)"
          className={styles.jsonLoaderRow}
        >
          <rect x="8" y="0" rx="5" ry="5" width={145} height={14} />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width={75}
          height={14}
          viewBox={`0 0 75 14`}
          backgroundColor="rgba(227, 235, 246, 0.466)"
          foregroundColor="rgba(191, 213, 243, 0.445)"
          className={styles.jsonLoaderRow}
        >
          <rect x="0" y="0" rx="5" ry="5" width={75} height={14} />
        </ContentLoader>
      </div>
    );
  }
  return (
    <div>
      <ContentLoader
        preserveAspectRatio="none"
        speed={2}
        width={150}
        height={17}
        viewBox={`0 0 150 17`}
        backgroundColor="rgba(227, 235, 246, 0.466)"
        foregroundColor="rgba(191, 213, 243, 0.445)"
        className={styles.predictionLabel}
      >
        <rect x="0" y="0" rx="5" ry="5" width={150} height={17} />
      </ContentLoader>
      <ContentLoader
        preserveAspectRatio="none"
        speed={2}
        width="100%"
        height={35}
        viewBox={`0 0 150 35`}
        backgroundColor="rgba(227, 235, 246, 0.466)"
        foregroundColor="rgba(191, 213, 243, 0.445)"
      >
        <rect x="0" y="0" rx="5" ry="5" width={150} height={35} />
      </ContentLoader>
    </div>
  );
}
