import { ComponentProps } from '@stitches/react';
import type * as Stitches from '@stitches/react';

import { config, keyframes, styled } from '@config/stitches';

const barAnimation = keyframes({
  '0%': {
    backgroundPosition: '0 0',
  },
  '100%': {
    backgroundPosition: '50px 50px',
  },
});
const BarContainer = styled('div', {
  display: 'flex',
  backgroundColor: '$primary50',
  borderRadius: '100px',
  height: '10px',
  overflow: 'hidden',
  '& .animated:after': {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundImage: `linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    )`,
    zIndex: 1,
    backgroundSize: '50px 50px',
    overflow: 'hidden',
    animation: `${barAnimation} 3s linear infinite`,
  },
});

const BarFill = styled('span', {
  position: 'relative',
  display: 'block',
  height: '100%',
  borderRadius: '100px',
  overflow: 'hidden',
  transition: 'width 500ms ease',
});

type PercentValue = {
  percent: number;
  value?: never;
  max?: never;
};

type NumericValue = {
  percent?: never;
  value: number;
  max: number;
};

export type StitchesPropgressProps = (PercentValue | NumericValue) & { animated?: boolean } & ComponentProps<
    typeof BarContainer
  > & {
    barColor?: Stitches.CSS<typeof config>['color'];
    backgroundColor?: Stitches.CSS<typeof config>['backgroundColor'];
  };

export function StitchesProgress({
  percent,
  value,
  max,
  animated,
  barColor = '$primary600',
  backgroundColor = '$primary50',
  css,
  ...rest
}: StitchesPropgressProps) {
  let width = 0;
  if (percent === undefined) {
    width = (100 / (max || 100)) * (value || 0);
  } else {
    width = percent;
  }

  return (
    <BarContainer {...rest} css={{ ...css, backgroundColor }}>
      <BarFill
        className={animated ? 'animated' : undefined}
        style={{ width: `${width}%` }}
        css={{ backgroundColor: barColor }}
      />
    </BarContainer>
  );
}
