import ContentLoader from 'react-content-loader';

export function LoadingVariationCell() {
  const width = 35;
  const height = 23;
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="rgba(227, 235, 246, 0.466)"
      foregroundColor="rgba(191, 213, 243, 0.445)"
    >
      <rect x="0" y="0" rx="5" ry="5" width={width} height={height} />
    </ContentLoader>
  );
}
