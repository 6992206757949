import { styled } from '@config/stitches';

export const PaddingContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FCFCFD',
  padding: '$6 $6 $8 $6',
  '@bp1': {
    padding: '$4 $4 $8 $4',
  },
  '@bp2': {
    padding: '$5 $6 $8 $6',
  },
  '@bp3': {
    padding: '$6 $6 $8 $6',
  },
  '@bp4': {
    padding: '$6 $6 $8 $6',
  },
});
