import { HTMLAttributes, ReactNode } from 'react';
import { FiAlertTriangle, FiInfo } from 'react-icons/fi';
import { NewBadge } from '../Badge';

import styles from './Alert.module.scss';

export type AlertProps = {
  variant?: 'error' | 'warning';
  badgeContent?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export function Alert({ variant = 'warning', className, children, badgeContent, ...rest }: AlertProps) {
  const classNames = `${styles[variant]} ${className ? className : ''}`;
  return (
    <div {...rest} className={classNames}>
      <NewBadge variant={variant}>{badgeContent}</NewBadge>
      {children}
    </div>
  );
}

const SIMPLE_ALERT_ICONS = {
  error: FiAlertTriangle,
  warning: FiAlertTriangle,
  info: FiInfo,
};

export type SimpleAlertProps = {
  variant?: 'error' | 'warning' | 'info';
} & HTMLAttributes<HTMLDivElement>;

export function SimpleAlert({ variant = 'error', children, className, ...rest }: SimpleAlertProps) {
  const classNames = `${styles.simple} ${styles[`simple${variant}`]} ${className ? className : ''}`;
  const Icon = SIMPLE_ALERT_ICONS[variant];
  return (
    <div {...rest} className={classNames}>
      <Icon className={styles.simpleIcon} />
      {children}
    </div>
  );
}
