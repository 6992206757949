import { Link, Outlet, useParams } from 'react-router-dom';

import { Box, Button, Loading, SimpleAlert } from '@components';
import { useModels } from '@hooks';
import { useMemo } from 'react';
import { ModelContext } from '@contexts';

/**
 * Route component, should be used for all :modelId/* routes. Will handle loading and
 * checking models, and whether :modelId exist, showing appropriate actions and errors.
 * @returns
 */
export function ModelGuard() {
  const { modelId } = useParams<{ modelId: string }>();
  const { data, isLoading, error, refetch } = useModels();

  const model = useMemo(() => data?.models.find((model) => model.modelId === modelId), [data, modelId]);

  if (isLoading) {
    return <Loading>Loading models</Loading>;
  }

  if (error && !isLoading) {
    return (
      <Box flex css={{ maxWidth: '400px', justifyContent: 'center', mx: 'auto', height: '100%', alignItems: 'center' }}>
        <SimpleAlert>
          <Box flex css={{ gap: '$2', alignItems: 'center', '& button': { fontSize: '0.8rem', padding: '$2 $3' } }}>
            Error loading models <Button onClick={() => refetch()}>Retry?</Button>
          </Box>
        </SimpleAlert>
      </Box>
    );
  }

  if (!model) {
    return (
      <Box
        flex
        flexDirection="column"
        css={{
          maxWidth: '400px',
          justifyContent: 'center',
          mx: 'auto',
          height: '100%',
          gap: '1em',
          alignItems: 'center',
          '& a': { fontSize: '0.8em' },
        }}
      >
        <SimpleAlert variant="warning">Sorry, this model does not exist</SimpleAlert>
        <Link to="/models">Go back to model list</Link>
      </Box>
    );
  }

  return (
    <ModelContext.Provider value={model}>
      <Outlet />
    </ModelContext.Provider>
  );
}
