import { createStitches, PropertyValue } from '@stitches/react';

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } = createStitches({
  theme: {
    colors: {
      blue50: '#EFF8FF',
      blue500: '#2E90FA',
      blue700: '#175CD3',
      blue900: '#194185',
      bluedark50: '#EFF4FF',
      bluedark100: '#D1E0FF',
      bluedark500: '#2970FF',
      bluedark900: '#00359E',
      bluelight50: '#F0F9FF',
      bluelight200: '#B9E6FE',
      bluelight700: '#026AA2',
      error25: '#FFFBFA',
      error50: '#FEF3F2',
      error100: '#FEE4E2',
      error200: '#FECDCA',
      error300: '#FDA29B',
      error500: '#F04438',
      error600: '#D92D20',
      error700: '#B42318',
      gray25: '#FCFCFD',
      gray50: '#F9FAFB',
      gray100: '#F2F4F7',
      gray200: '#EAECF0',
      gray300: '#D0D5DD',
      gray400: '#98A2B3',
      gray500: '#667085',
      gray600: '#475467',
      gray700: '#344054',
      gray800: '#1D2939',
      gray900: '#101828',
      grayblue25: '#FCFCFD',
      grayblue50: '#F8F9FC',
      grayblue100: '#EAECF5',
      grayblue500: '#4E5BA6',
      grayblue600: '#3E4784',
      grayblue700: '#363F72',
      grayblue800: '#293056',
      grayblue900: '#101323',
      graycool25: '#FCFCFD',
      graycool50: '#F9F9FB',
      graycool100: '#EFF1F5',
      graycool500: '#5D6B98',
      graycool700: '#404968',
      graymodern400: '#9AA4B2',
      grayneutral25: '#FCFCFD',
      grayneutral50: '#F9FAFB',
      grayneutral500: '#6C737F',
      green300: '#73E2A3',
      green500: '#16B364',
      indigo25: '#F5F8FF',
      indigo50: '#EEF4FF',
      indigo100: '#E0EAFF',
      indigo300: '#A4BCFD',
      indigo500: '#6172F3',
      indigo700: '#3538CD',
      indigo900: '#2D3282',
      orange50: '#FEF6EE',
      orange500: '#EF6820',
      orange600: '#E62E05',
      orange700: '#B93815',
      rose400: '#FD6F8E',
      rose500: '#F63D68',
      rose700: '#C01048',
      success25: '#F6FEF9',
      success50: '#ECFDF3',
      success100: '#D1FADF',
      success300: '#6CE9A6',
      success500: '#12B76A',
      success600: '#039855',
      success700: '#027A48',
      success800: '#05603A',
      teal50: '#F0FDF9',
      teal100: '#CCFBEF',
      teal500: '#15B79E',
      teal600: '#0E9384',
      teal900: '#134E48',
      primary25: '#FAFAFF',
      primary50: '#F5F7FF',
      primary100: '#EBEBFF',
      primary200: '#E9D7FE',
      primary300: '#BBC1FB',
      primary500: '#9E77ED',
      primary600: '#535DC6',
      primary700: '#5456F6',
      purple50: '#F4F3FF',
      purple200: '#D9D6FE',
      violet50: '#F5F3FF',
      violet200: '#DDD6FE',
      violet500: '#875BF7',
      violet700: '#5925DC',
      warning25: '#FFFCF5',
      warning50: '#FFFAEB',
      warning100: '#FEF0C7',
      warning300: '#FEC84B',
      warning500: '#F79009',
      warning600: '#DC6803',
      warning700: '#B54708',
      warning800: '#93370D',
    },
    fonts: {
      default:
        'Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
      mono: 'Roboto Mono, monospace',
    },
    shadows: {
      xs: '0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.05)',
      sm: '0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 10px 15px -5px, rgba(0, 0, 0, 0.04) 0px 7px 7px -5px',
      md: '0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
      lg: '0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 28px 23px -7px, rgba(0, 0, 0, 0.04) 0px 12px 12px -7px',
      xl: '0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 36px 28px -7px, rgba(0, 0, 0, 0.04) 0px 17px 17px -7px',
      focus: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px $gray100',
    },
    space: {
      0: '0px',
      1: '4px',
      2: '8px',
      3: '12px',
      4: '16px',
      5: '32px',
      6: '48px',
      7: '64px',
      8: '80px',
      9: '128px',
      10: '180px',
    },
  },
  media: {
    bp1: '(min-width: 480px)',
    bp2: '(min-width: 800px)',
    bp3: '(min-width: 1400px)',
    bp4: '(min-width: 1900px)',
  },
  prefix: 'cradl-app__',
  utils: {
    m: (value: PropertyValue<'margin'>) => ({
      margin: value,
    }),
    mt: (value: PropertyValue<'marginTop'>) => ({
      marginTop: value,
    }),
    mr: (value: PropertyValue<'marginRight'>) => ({
      marginRight: value,
    }),
    mb: (value: PropertyValue<'marginBottom'>) => ({
      marginBottom: value,
    }),
    ml: (value: PropertyValue<'marginLeft'>) => ({
      marginLeft: value,
    }),
    mx: (value: PropertyValue<'marginLeft'>) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: PropertyValue<'marginTop'>) => ({
      marginTop: value,
      marginBottom: value,
    }),
    pt: (value: PropertyValue<'paddingTop'>) => ({
      paddingTop: value,
    }),
    pr: (value: PropertyValue<'paddingRight'>) => ({
      paddingRight: value,
    }),
    pb: (value: PropertyValue<'paddingBottom'>) => ({
      paddingBottom: value,
    }),
    pl: (value: PropertyValue<'paddingLeft'>) => ({
      paddingLeft: value,
    }),
    px: (value: PropertyValue<'paddingLeft'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: PropertyValue<'paddingTop'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
  },
});

export const globalStyles = globalCss({
  dd: {
    marginInlineStart: 0,
  },
});
