import { Link } from 'react-router-dom';

import { StitchesBadge } from '@components';
import { useAPILimit } from '@hooks';
import { css, styled } from '@config/stitches';
import AlertSquareIcon from '@assets/icon-alert-square.svg?react';
import ArrowRightIcon from '@assets/icon-arrow-right.svg?react';
import XIcon from '@assets/icon-x-thin.svg?react';
import { useState } from 'react';

const BannerContainerCommon = css({
  width: '100%',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  gap: '0.6em',
  color: '$gray900',
  alignItems: 'center',
  padding: '0.5em',
  fontSize: '0.9rem',
  flexWrap: 'wrap',
  '& .icon': {
    ml: '2em',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    gap: '0.3em',
  },
  '& .icon svg': {
    width: '1.3em',
    height: '1.3em',
  },
  '& .details': {
    color: '$gray500',
    fontSize: '0.85rem',
    '& span': {
      fontWeight: 500,
      color: '$gray900',
    },
  },
});

const WarningContainer = styled('div', BannerContainerCommon, {
  backgroundColor: '$warning25',
  borderBottom: '1px solid $warning300',
  '& .icon': {
    color: '$warning800',
  },
  '& .icon svg': {
    color: '$warning500',
  },
});

const LimitReachedContainer = styled('div', BannerContainerCommon, {
  backgroundColor: '$error50',
  borderBottom: '1px solid $error300',
  '& .icon': {
    color: '$rose700',
  },
  '& .icon svg': {
    color: '$rose500',
  },
});

const XButton = styled('button', {
  color: '$gray500',
  position: 'absolute',
  right: '1em',
  display: 'flex',
  lineHeight: 'unset',
  alignItems: 'center',
  padding: 0,
  '&:hover': {
    color: '$gray900',
  },
  backgroundColor: 'transparent',
  '& svg': {
    width: '1.1rem',
    height: '1.1rem',
  },
});

const UpgradeBadge = styled(StitchesBadge, {
  color: '$gray800',
  gap: '0.3em',
  padding: '6px 10px',
  fontSize: '0.85rem',
  fontWeight: 500,
  border: '1px solid $gray200 !important',
  backgroundColor: 'white !important',
  mr: '2em',
  '&:hover': {
    backgroundColor: '$gray50 !important',
    '& svg': {
      transform: 'translateX(1px)',
    },
  },
  '& svg': {
    color: '$gray400',
  },
});

type HideFn = {
  onHide: () => void;
};

type WarningBannerProps = {
  used: number;
  limit: number;
} & HideFn;

const WarningBanner = ({ used, limit, onHide }: WarningBannerProps) => (
  <WarningContainer>
    <span className="icon">
      <AlertSquareIcon />
      Usage limit approaching!
    </span>
    <span className="details">
      You have used <span>{used}</span> of {limit} predictions included this month.
    </span>
    <Link to="/settings/plans">
      <UpgradeBadge>
        Upgrade <ArrowRightIcon />
      </UpgradeBadge>
    </Link>
    <XButton onClick={onHide}>
      <XIcon />
    </XButton>
  </WarningContainer>
);

type LimitReachedBannerProps = {
  limit: number;
} & HideFn;

const LimitReachedBanner = ({ limit, onHide }: LimitReachedBannerProps) => (
  <LimitReachedContainer>
    <span className="icon">
      <AlertSquareIcon />
      Usage limit reached!
    </span>
    <span className="details">
      You have used all <span>{limit}</span> predictions included this month.
    </span>
    <Link to="/settings/plans">
      <UpgradeBadge>
        Upgrade <ArrowRightIcon />
      </UpgradeBadge>
    </Link>
    <XButton onClick={onHide}>
      <XIcon />
    </XButton>
  </LimitReachedContainer>
);

const LOCAL_STORAGE_PREDICTION_LIMIT_WARNING_KEY = 'cradl-prediction-limit-warning-banner-dismissed';
const LOCAL_STORAGE_PREDICTION_LIMIT_REACHED_KEY = 'cradl-prediction-limit-reached-banner-dismissed';

export type BannerProps = {
  warningThresholdPercent?: number;
};
export function Banner({ warningThresholdPercent = 80 }: BannerProps) {
  const [warningHidden, setWarningHidden] = useState(
    () => window.localStorage.getItem(LOCAL_STORAGE_PREDICTION_LIMIT_WARNING_KEY) === 'true'
  );
  const [limitReachedHidden, setLimitReachedHidden] = useState(
    () => window.localStorage.getItem(LOCAL_STORAGE_PREDICTION_LIMIT_REACHED_KEY) === 'true'
  );

  const { pagePredictions, isLoading } = useAPILimit();

  // Unsure what to show until loading is finished
  if (isLoading) {
    return null;
  }

  // No point doing anything else, we know both are going to be hidden
  if (warningHidden && limitReachedHidden) {
    return null;
  }

  const hideWarning = () => {
    setWarningHidden(true);
    window.localStorage.setItem(LOCAL_STORAGE_PREDICTION_LIMIT_WARNING_KEY, 'true');
  };

  const hideLimitReached = () => {
    setLimitReachedHidden(true);
    window.localStorage.setItem(LOCAL_STORAGE_PREDICTION_LIMIT_REACHED_KEY, 'true');
  };

  const closeToPredictionLimit =
    isLoading || pagePredictions.full
      ? false
      : (100 / (pagePredictions?.limit || 1)) * (pagePredictions?.used || 0) >= warningThresholdPercent;

  const shouldShowWarning = closeToPredictionLimit && !warningHidden;
  const shouldShowLimitReached = pagePredictions.full && !limitReachedHidden;

  if (shouldShowLimitReached) {
    return <LimitReachedBanner limit={pagePredictions?.limit || 0} onHide={hideLimitReached} />;
  }

  if (shouldShowWarning) {
    return <WarningBanner used={pagePredictions?.used || 0} limit={pagePredictions?.limit || 0} onHide={hideWarning} />;
  }

  return null;
}
