import { DoughnutChart, NewCard } from '@components';
import { css, styled, theme } from '@config/stitches';

const chart = css({
  width: '120px',
  height: '120px',
});

const Container = styled(NewCard, {
  flexDirection: 'row',
  display: 'flex',
  flex: '1 1 275px',
  minWidth: '275px',
  gap: '$3',
  '& h3': {
    color: '$gray500',
    fontSize: '0.8rem',
  },
  '& .description': {
    color: '$gray500',
    fontSize: '0.8rem',
  },
});

const DonutContainer = styled('div', {
  position: 'relative',
  fontSize: '1.5rem',
  alignSelf: 'flex-start',
  display: 'inline-flex',
  '& > span': {
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 500,
    lineHeight: 1,
  },
});

const TextContainer = styled('div', {
  gap: '$2',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});

const ResultText = styled('p', {
  color: '$gray900',
  fontSize: '1.1rem',
  fontWeight: 500,
});

const MutedText = styled('p', {
  color: '$gray500',
  fontSize: '0.8rem',
});

const good = theme.colors.teal500.computedValue;
const ok = theme.colors.warning300.computedValue;
const bad = theme.colors.rose400.computedValue;

export type DataQualityCardProps = {
  score?: number;
  loading?: boolean;
};

export function DataQualityCard({ score = 0, loading = true }: DataQualityCardProps) {
  const fillColor = score >= 80 ? good : score >= 50 ? ok : bad;

  return (
    <Container padding="lg" shadow="xs">
      <DonutContainer>
        <DoughnutChart
          value={score}
          title="Data quality score"
          className={chart()}
          fillColor={fillColor}
          strokeWidth={12}
        />
        <span>{score}%</span>
      </DonutContainer>
      <TextContainer>
        <h3>Data quality score</h3>
        {loading && <p className="description">Your data quality score is being calculated</p>}

        <ResultText>
          {!loading && 80 <= score && <span>Good</span>}
          {!loading && 50 <= score && score < 80 && <span>Medium</span>}
          {!loading && score < 50 && <span>Low</span>}
        </ResultText>

        <MutedText>
          {!loading && 80 <= score && <span>Your data quality is good, and you can now train your model.</span>}
          {!loading && 50 <= score && score < 80 && <span>Your model can be trained, but results may vary.</span>}
          {!loading && score < 50 && <span>Your data issues must be fixed before you can start training.</span>}
        </MutedText>
      </TextContainer>
    </Container>
  );
}
