import { ReactNode, FunctionComponent, useEffect, SVGProps } from 'react';
import { Text, Group, Menu, Drawer, Tooltip, Progress } from '@mantine/core';
import { twMerge } from 'tailwind-merge';
import { useDisclosure } from '@mantine/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

import ApiIcon from '@assets/api-icon.svg?react';
import ArrowIcon from '@assets/icon-chevron-selector-vertical.svg?react';
import CalendarIcon from '@assets/calendar-icon.svg?react';
import ChatSupportIcon from '@assets/chat-support-icon.svg?react';
import CheckIcon from '@assets/icon-check-2.svg?react';
import DocumentationIcon from '@assets/documentation-icon.svg?react';
import FlowsIcon from '@assets/icon-data-flow.svg?react';
import FullLogo from '@assets/logo-full.svg?react';
import HelpIcon from '@assets/help-icon.svg?react';
import LinkIcon from '@assets/icon-link-external.svg?react';
import ModelIcon from '@assets/icon-container.svg?react';
import OrganizationIcon from '@assets/organization-icon.svg?react';
import SettingsIcon from '@assets/settings-icon.svg?react';
import StarsIcon from '@assets/stars-icon.svg?react';
import TemplatesIcon from '@assets/templates-icon.svg?react';
import { ProfileMenu } from '@components/TopBar/ProfileMenu';
import { createCredentials } from '@contexts/AuthProvider';
import {
  GettingStartedStep,
  useAuthContext,
  useGettingStarted,
  useOrganizationQuery,
  useTracker,
  useUnmount,
} from '@hooks';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  DEFAULT_REDIRECT_ROUTE,
  LOCAL_STORAGE_AUTH_TOKEN_PREFIX,
  LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY,
} from '@config/app';
import { ButtonLink, VIDEO_KEY } from '@components';
import { useTailwindConfig } from '@hooks/useTailwindConfig';

type SidebarItemProps = {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  display: string;
  onClick: () => void;
  rightSection?: ReactNode;
  RightIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  selected?: boolean;
};

function SidebarItem({ Icon, display, onClick, rightSection, RightIcon, selected }: SidebarItemProps) {
  return (
    <Group
      key={display}
      className={twMerge(
        'tw-cursor-pointer tw-rounded-lg tw-p-3 tw-text-gray-600 hover:tw-bg-gray-50 hover:tw-text-primary-600',
        selected ? 'tw-bg-primary-50' : ''
      )}
      onClick={onClick}
    >
      <Icon className="tw-h-[24px] tw-w-[24px]" />
      <Text className="!tw-text-md !tw-font-medium">{display}</Text>
      <div className="tw-flex tw-grow tw-justify-end">
        {RightIcon ? <RightIcon className="tw-h-[24px] tw-w-[24px]" /> : null}
        {rightSection ? <>{rightSection}</> : null}
      </div>
    </Group>
  );
}

function OrganizationMenu() {
  const { client, jwt, scopes, setCredentials } = useAuthContext();
  const currentClientId = (jwt as any)?.client_id;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: currentOrganization } = useOrganizationQuery();
  const { data: organizationsData } = useQuery<any>({
    queryKey: ['organizations'],
    queryFn: () => client!.makeGetRequest('/organizations'),
  });

  const onSelectOrganization = (clientId: string) => {
    window.localStorage.setItem(LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY, clientId);
    const credentials = createCredentials(clientId);
    const hasStoredJWT = window.localStorage.getItem(`${LOCAL_STORAGE_AUTH_TOKEN_PREFIX}/${clientId}`);
    // Previous JWT with possibly valid auth token and/or refresh token exist for this clientId
    if (hasStoredJWT) {
      setCredentials(credentials);
      queryClient.clear();
      // Might be on a organization specific resource like /models/las:model:abc, redirect to safe place
      navigate(DEFAULT_REDIRECT_ROUTE);
    } else {
      credentials.initiateOAuthFlow();
    }
  };

  return (
    <Menu>
      <Menu.Target>
        <div className="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-gap-3 tw-rounded-lg tw-border-1.5 tw-border-gray-200 tw-p-2 tw-shadow-sm hover:tw-bg-gray-50">
          <Group>
            <div className="tw-rounded-lg tw-border-2 tw-border-gray-300 tw-p-1">
              <OrganizationIcon />
            </div>
            <Text className="!tw-text-md !tw-font-medium !tw-text-gray-900">{currentOrganization?.name}</Text>
          </Group>
          <ArrowIcon className="tw-text-gray-400" />
        </div>
      </Menu.Target>

      <Menu.Dropdown>
        {(organizationsData?.organizations ?? []).map((organization: any) => {
          return (
            <Menu.Item
              key={organization.organizationId}
              onClick={() => onSelectOrganization(organization.clientId)}
              rightSection={
                organization.clientId === currentClientId ? (
                  <div className="tw-rounded-xl tw-border-1.5 tw-border-success-200 tw-bg-success-25 tw-px-1">
                    <Text className="!tw-text-xs !tw-text-success-500">Current</Text>
                  </div>
                ) : null
              }
            >
              <div className="tw-p-0.5">
                <Text className="!tw-text-sm !tw-font-medium !tw-text-gray-600 hover:!tw-text-gray-900">
                  {organization?.name}
                </Text>
              </div>
            </Menu.Item>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
}

type GettingStartedProps = {
  steps: GettingStartedStep[];
};

export function GettingStarted({ steps }: GettingStartedProps) {
  return (
    <div className="tw-space-y-2">
      {steps.map((step) => {
        return (
          <Tooltip key={step.name} label={step.tooltip}>
            <Group>
              {step.completed ? (
                <>
                  <div className="tw-flex tw-h-[20px] tw-w-[20px] tw-items-center tw-justify-center tw-rounded-full tw-bg-success-500 tw-p-1">
                    <CheckIcon className="tw-text-white" />
                  </div>
                  <Text className="!tw-text-sm !tw-text-gray-400 !tw-line-through">{step.name}</Text>
                </>
              ) : (
                <>
                  <div className="tw-flex tw-h-[20px] tw-w-[20px] tw-items-center tw-justify-center tw-rounded-full tw-border-1.5 tw-border-gray-500 tw-bg-gray-50 tw-p-1" />
                  <Text className="!tw-text-sm !tw-text-gray-500">{step.name}</Text>
                </>
              )}
            </Group>
          </Tooltip>
        );
      })}
    </div>
  );
}

type HelpSlideOutProps = {
  opened: boolean;
  onClose: () => void;
};

function HelpSlideOut({ opened, onClose }: HelpSlideOutProps) {
  const { tracker } = useTracker();
  const { steps, numCompletedSteps } = useGettingStarted();

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position="right"
      title={<Text className="!tw-text-xl !tw-font-medium !tw-text-grayblue-900">Help</Text>}
    >
      <div className="tw-space-y-5">
        <video
          className="tw-rounded-lg"
          controls
          muted
          poster="https://docs.cradl.ai/video/ai_studio_thumb.png"
          onPlay={(event) => {
            tracker.track('Home Video Played', {
              properties: {},
            });
          }}
        >
          <source src="https://docs.cradl.ai/video/cradl-in-3-min.mp4" type="video/mp4" />
        </video>
        <Group className="!tw-justify-between">
          <Text className="!tw-text-gray-700">Getting started</Text>
          <div className="tw-rounded-lg tw-border-1.5 tw-border-gray-300 tw-bg-primary-25 tw-px-1.5 tw-py-0.5">
            <Text className="!tw-text-xs !tw-text-primary-700">
              {numCompletedSteps}/{steps.length} completed
            </Text>
          </div>
        </Group>
        <div className="tw-rounded-lg tw-border-1.5 tw-border-gray-200 tw-p-3">
          <GettingStarted steps={steps} />
        </div>
        <div className="tw-space-y-2 tw-rounded-lg tw-bg-gray-50 tw-p-5">
          <Text className="!tw-text-lg !tw-font-medium !tw-text-gray-900">Want hands-on help?</Text>
          <Text className="!tw-text-md !tw-text-gray-600">
            Meet with a product expert to help set-up your first automation project in no-time.
          </Text>
          <div className="tw-py-3">
            <ButtonLink variant="secondary-gray" to="https://www.cradl.ai/contact" target="_blank">
              <CalendarIcon />
              Book a session
            </ButtonLink>
          </div>
        </div>
        <div>
          <SidebarItem
            Icon={DocumentationIcon}
            display="Documentation"
            onClick={() => window.open('https://docs.cradl.ai', '_blank')}
            RightIcon={() => <LinkIcon className="tw-h-[22px] tw-w-[22px] tw-stroke-1" />}
          />
          <SidebarItem
            Icon={ChatSupportIcon}
            display="Chat Support"
            onClick={() => window._chatlio?.show({ expanded: true })}
          />
        </div>
      </div>
    </Drawer>
  );
}

type SidebarProps = {
  children: ReactNode;
};

export function Sidebar({ children }: SidebarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { tracker } = useTracker();
  const { data: organization, isNewOrganization } = useOrganizationQuery();
  const [helpOpened, { open: openHelp, close: closeHelp }] = useDisclosure(false);
  const tailwindConfig = useTailwindConfig();
  // @ts-expect-error
  const colors = tailwindConfig.theme.colors;
  const { steps, numCompletedSteps } = useGettingStarted();

  useEffect(() => {
    window._chatlio?.hide();
  }, []);

  useUnmount(() => {
    window._chatlio?.show();
  });

  // @ts-expect-error
  const currentUsage = organization?.monthlyNumberOfPagePredictionsUsed ?? 0;
  // @ts-expect-error
  const usageLimit = organization?.monthlyNumberOfPagePredictionsAllowed ?? 100;

  const planHeader = usageLimit == 100 ? 'Free Plan' : '🚀 Test credits';
  const planDescription = usageLimit == 100 ? 'Predictions this month' : 'You have been granted test credits';

  const gettingStartedProgress = (
    <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-rounded-full tw-border-1.5 tw-border-success-200 tw-bg-success-50 tw-px-1.5 tw-py-0.5">
      <StarsIcon className="tw-text-success-500" />
      <Text className="!tw-text-xs !tw-text-success-700">
        {numCompletedSteps}/{steps.length}
      </Text>
    </div>
  );

  return (
    <>
      <HelpSlideOut opened={helpOpened} onClose={closeHelp} />
      <div className="tw-flex tw-flex-row">
        <div className="tw-sticky tw-top-0 tw-flex tw-h-[100vh] tw-w-[320px] tw-border-r-1.5 tw-border-gray-200 tw-bg-white">
          <div className="tw-flex tw-grow tw-flex-col tw-justify-between">
            <div>
              <div className="tw-flex tw-flex-row tw-items-center tw-border-b-1.5 tw-border-gray-200 tw-px-9 tw-py-5">
                <FullLogo className="tw-h-[40px] tw-cursor-pointer" onClick={() => navigate('/')} />
              </div>
              <div className="tw-mt-5 tw-p-2">
                <div className="tw-p-3">
                  <OrganizationMenu />
                </div>
                <div className="tw-px-3">
                  <SidebarItem
                    Icon={isNewOrganization ? ModelIcon : FlowsIcon}
                    display={isNewOrganization ? 'Models' : 'Flows'}
                    onClick={() => navigate('/flows')}
                    selected={location.pathname === '/flows'}
                  />
                  <SidebarItem
                    Icon={TemplatesIcon}
                    display="Templates"
                    onClick={() => navigate('/flows/templates')}
                    selected={location.pathname === '/flows/templates'}
                  />
                  {isNewOrganization ? null : (
                    <SidebarItem
                      Icon={ModelIcon}
                      display="Models"
                      onClick={() => navigate('/models')}
                      selected={location.pathname === '/models'}
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="tw-p-5">
                <SidebarItem
                  Icon={ApiIcon}
                  display="API"
                  onClick={() => navigate('/appclients')}
                  selected={location.pathname === '/appclients'}
                />
                <SidebarItem
                  Icon={SettingsIcon}
                  display="Settings"
                  onClick={() => navigate('/settings/profile')}
                  selected={location.pathname.startsWith('/settings')}
                />
                <SidebarItem
                  Icon={HelpIcon}
                  display="Help & First Steps"
                  onClick={() => {
                    openHelp();
                    localStorage.setItem(VIDEO_KEY, 'true');
                    window.dispatchEvent(new Event('storage'));
                    tracker.track('HelpSlideOut Opened', {
                      properties: {
                        organizationId: organization?.organizationId,
                      },
                    });
                  }}
                  rightSection={gettingStartedProgress}
                />
                {organization?.planId === 'las:organization:cradl/las:plan:free-usd-monthly' ? (
                  <div className="tw-space-y-2 tw-rounded-lg tw-bg-gray-50 tw-p-5">
                    <div className="tw-space-y-0.5">
                      <Text className="!tw-font-medium !tw-text-gray-900">{planHeader}</Text>
                      <Text className="!tw-text-sm !tw-text-gray-600">{planDescription}</Text>
                    </div>
                    <Group className="tw-pt-2">
                      <Progress
                        className="tw-grow"
                        value={(currentUsage * 100) / usageLimit}
                        color={colors.primary[600]}
                      />
                      <Group className="!tw-gap-0">
                        <Text className="!tw-text-xs !tw-text-gray-900">{currentUsage}</Text>
                        <Text className="!tw-text-xs !tw-text-gray-500">/{usageLimit}</Text>
                      </Group>
                    </Group>
                    <ButtonLink
                      className="!tw-border-none !tw-bg-transparent !tw-px-0 !tw-py-3 !tw-text-primary-700 !tw-shadow-none hover:!tw-bg-gray-100 hover:!tw-text-gray-900"
                      to="/settings/billing"
                    >
                      <div className="tw-flex tw-grow">Upgrade</div>
                    </ButtonLink>
                  </div>
                ) : null}
              </div>
              <div className="tw-flex tw-flex-row tw-items-center tw-border-t-1.5 tw-border-gray-200 tw-p-5">
                <ProfileMenu />
              </div>
            </div>
          </div>
        </div>
        {children}
      </div>
    </>
  );
}
