/** @type {import('tailwindcss').Config} */
import type { Config } from 'tailwindcss';
export default {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  darkMode: 'class',
  theme: {
    extend: {
      keyframes: {
        slideDown: {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        slideUp: {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
      },
      animation: {
        slideDown: 'slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1)',
        slideUp: 'slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1)',
      },
      borderWidth: {
        '1.5': '1.5px',
      },
      colors: {
        primary: {
          25: '#FCFAFF',
          50: '#F9F5FF',
          100: '#F4EBFF',
          200: '#E9D7FE',
          300: '#D6BBFB',
          400: '#AEABFF',
          500: '#9793FB',
          600: '#7A75FF',
          700: '#5A57B9',
          800: '#53389E',
          900: '#42307D',
          950: '#2C1C5F',
        },
        gray: {
          25: '#FCFCFD',
          50: '#F9FAFB',
          100: '#F2F4F7',
          200: '#EAECF0',
          300: '#D0D5DD',
          400: '#98A2B3',
          500: '#667085',
          600: '#475467',
          700: '#344054',
          800: '#182230',
          900: '#101828',
          950: '#0C111D',
        },
        grayblue: {
          25: '#FCFCFD',
          50: '#F8F9FC',
          100: '#EAECF5',
          200: '#D5D9EB',
          400: '#717BBC',
          500: '#4E5BA6',
          600: '#3E4784',
          700: '#363F72',
          800: '#293056',
          900: '#101323',
        },
        graycool: {
          25: '#FCFCFD',
          50: '#F9F9FB',
          100: '#EFF1F5',
          500: '#5D6B98',
          700: '#404968',
        },
        error: {
          25: '#FFFBFA',
          50: '#FEF3F2',
          100: '#FEE4E2',
          200: '#FECDCA',
          300: '#FDA29B',
          500: '#F04438',
          600: '#D92D20',
          700: '#B42318',
          900: '#7A271A',
        },
        rose: {
          50: '#FFF1F3',
          600: '#E31B54',
        },
        success: {
          25: '#F6FEF9',
          50: '#ECFDF3',
          100: '#D1FADF',
          200: '#ABEFC6',
          300: '#75E0A7',
          500: '#12B76A',
          600: '#039855',
          700: '#027A48',
          800: '#05603A',
          950: '#053321',
        },
        warning: {
          50: '#FFFAEB',
          100: '#FEF0C7',
          200: '#FEDF89',
          300: '#FEC84B',
          500: '#F79009',
          600: '#DC6803',
          700: '#B54708',
        },
        indigo: {
          25: '#F5F8FF',
          300: '#A4BCFD',
        },
        teal: {
          500: '#15B79E',
          600: '#0E9384',
        },
      },
    },
    fontFamily: {
      sans: [
        'Inter',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'sans-serif',
      ],
      serif: ['Merriweather', 'serif'],
    },
  },
  plugins: [],
  prefix: 'tw-',
} satisfies Config;
