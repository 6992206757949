import { FiCheck } from 'react-icons/fi';
import { useRef } from 'react';

import { Button } from '@components';
import { useCopy } from '@hooks';
import { styled } from '@config/stitches';
import CopyIcon from '@assets/icon-copy.svg?react';

const StyledCopyContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$1',
  '& > span': {
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    fontFamily: '$mono',
    fontSize: '0.8rem',
    backgroundColor: '$grayblue50',
    padding: '$1',
    color: '$gray600',
    whiteSpace: 'nowrap',
  },
  '& button': {
    padding: '$1',
    background: 'white',
    border: '1px solid $gray300',
    borderRadius: '4px',
    height: '0.9em',
    width: '0.9em',
    boxSizing: 'content-box',
    color: '$gray500',
  },
  '& .overflowing:after': {
    pointerEvents: 'none',
    content: '',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    background: 'linear-gradient(to right, transparent 230px, white)',
  },
});

type CopyIdProps = {
  text: string;
};
export function CopyId({ text }: CopyIdProps) {
  const { copied, onCopy } = useCopy(text);
  const container = useRef<HTMLSpanElement>(null);
  const innerContent = useRef<HTMLSpanElement>(null);
  const overflowing = (container.current?.offsetWidth || 0) < (innerContent.current?.offsetWidth || 0);

  return (
    <StyledCopyContainer>
      <span className={overflowing ? 'overflowing' : undefined} ref={container}>
        <span ref={innerContent}>{text}</span>
      </span>
      <Button
        variant="secondary-gray"
        aria-live="assertive"
        onClick={() => onCopy()}
        aria-label={copied ? 'Copy ID' : 'Copied!'}
      >
        {copied ? <FiCheck /> : <CopyIcon />}
      </Button>
    </StyledCopyContainer>
  );
}
