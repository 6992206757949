import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AppClientList } from '@lucidtech/las-sdk-browser';
import { useAuthContext } from './useAuthContext';

/**
 * Hook to get app-clients data from API, using useQuery and its cache.
 * @returns
 */
export function useAppClients(
  options?: Omit<UseQueryOptions<AppClientList, unknown, AppClientList, (string | undefined)[]>, 'queryKey' | 'queryFn'>
) {
  const { client } = useAuthContext();
  const useQueryResult = useQuery({ queryKey: ['appClients'], queryFn: () => client!.listAppClients(), ...options });
  return useQueryResult;
}
