import { HTMLAttributes, ReactNode, Ref } from 'react';
import { useSelect, UseSelectStateChange } from 'downshift';

import styles from './Select.module.scss';
import { FiChevronDown } from 'react-icons/fi';

export type SelectProps<T> = HTMLAttributes<HTMLDivElement> & {
  selectedItem?: T | null;
  defaultSelectedItem?: T;
  initialSelectedItem?: T;
  handleSelectedItemChange?: (changes: UseSelectStateChange<T>) => void;
  options: Array<T>;
  itemDisplayFormat?: (item: T | undefined) => ReactNode;
  disabledItemDisplayFormat?: (item: T | undefined) => ReactNode;
  selectedItemDisplayFormat?: (item: T | undefined) => ReactNode;
  innerRef?: Ref<HTMLButtonElement>;
  innerTabIndex?: number;
  ref?: Ref<HTMLDivElement>;
  disabled?: boolean;
  disabledComponent?: ReactNode;
  compact?: boolean;
  error?: boolean;
  placeholder?: string;
  itemDisabledConditional?: (item: T) => boolean;
  dropdownPosition?: 'top' | 'bottom';
};

function defaultSelectedItemDisplayFormat<T>(item: T): ReactNode {
  if (typeof item === 'string') {
    return item;
  }
  if (typeof item === 'object') {
    const coercedItem = item as any;
    if (coercedItem.hasOwnProperty('name')) {
      return coercedItem.name;
    }
    if (coercedItem.hasOwnProperty('display')) {
      return coercedItem.display;
    }
    return JSON.stringify(coercedItem);
  }
  return '';
}

const defaultItemDisplayFormat = <T,>(item: T | undefined) => {
  if (typeof item === 'string') {
    return item;
  }

  if (typeof item === 'object') {
    const coercedItem = item as unknown as any;
    if (coercedItem.hasOwnProperty('name')) {
      return coercedItem.name! // eslint-disable-line
    }
    if (coercedItem.hasOwnProperty('display')) {
      return coercedItem.display! // eslint-disable-line
    }
    return JSON.stringify(coercedItem);
  }
  return '';
};

const Select = <T,>({
  disabled,
  disabledComponent,
  selectedItem,
  handleSelectedItemChange,
  options,
  disabledItemDisplayFormat,
  itemDisplayFormat = defaultItemDisplayFormat,
  selectedItemDisplayFormat = defaultSelectedItemDisplayFormat,
  className,
  innerRef,
  ref,
  id,
  placeholder,
  innerTabIndex,
  compact,
  error,
  itemDisabledConditional,
  dropdownPosition,
  defaultSelectedItem,
  initialSelectedItem,
  ...divProps
}: SelectProps<T>): JSX.Element => {
  const {
    isOpen,
    selectedItem: derivedSelectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect<T>({
    items: options,
    selectedItem,
    onSelectedItemChange: handleSelectedItemChange,
    initialSelectedItem,
    id,
  });

  const classNames = `${styles['cursor']} ${styles['button']} ${selectedItem ? '' : styles['placeholder']} ${
    error ? styles.error : ''
  }`;

  const toggleButtonProps = getToggleButtonProps({
    placeholder,
    ref: innerRef,
    disabled,
  });

  const containerClasses = `${styles.container} ${className ? className : ''} ${compact ? styles.compact : ''} ${
    dropdownPosition === 'top' ? styles.top : ''
  }`;

  return (
    <div {...divProps} className={containerClasses} ref={ref}>
      <button type="button" {...toggleButtonProps} className={classNames} tabIndex={innerTabIndex || 0}>
        <div className={styles.buttonContent}>
          {disabled && disabledComponent
            ? disabledComponent
            : derivedSelectedItem
            ? selectedItemDisplayFormat(derivedSelectedItem)
            : placeholder}
        </div>
        <div className={styles['icon-group']}>
          <FiChevronDown />
        </div>
      </button>
      <ul {...getMenuProps()} className={`${styles.menu} ${isOpen ? 'open' : ''}`}>
        {isOpen &&
          options.map((item, index) => {
            const disabled = itemDisabledConditional && itemDisabledConditional(item);
            const classNames = `${highlightedIndex === index ? styles['highlighted'] : ''} ${
              disabled ? styles.disabled : ''
            }`;
            const disabledDisplayFn = disabledItemDisplayFormat || itemDisplayFormat;
            return (
              <li
                key={`${itemDisplayFormat(item)}${index}`}
                className={classNames}
                data-selected={selectedItem === item}
                aria-disabled={disabled}
                {...getItemProps({ item, index, disabled })}
              >
                {disabled ? disabledDisplayFn(item) : itemDisplayFormat(item)}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export { Select };
