import { Dispatch, useState } from 'react';
import { FieldConfigAction, LineItemField } from '../fieldConfigReducer';
import { Button, PointerSensor } from '@components';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CollapseButton, InnerContainer } from '../FieldItem';
import IconChevronRight from '@assets/icon-chevron-right.svg?react';
import { DndContext, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { Collapse, Text } from '@mantine/core';
import { DraggableLineItemField } from './DraggableLineItemField';
import { FiPlus } from 'react-icons/fi';

export type LineItemFieldProps = {
  fields: Array<LineItemField>;
  dispatch: Dispatch<FieldConfigAction>;
  itemIndex: number;
  collapsed?: boolean;
  id: string;
  showConfidences: boolean;
  canEditFields?: boolean;
  enablePromptConfig?: boolean;
};

export function LineItemFields({
  fields,
  dispatch,
  itemIndex,
  collapsed,
  id,
  showConfidences,
  canEditFields,
  enablePromptConfig,
}: LineItemFieldProps) {
  const [activeId, setActiveId] = useState<string | null>(null);

  function handleDragStart(event: any) {
    setActiveId(event.active.id as string);
  }

  const sensors = useSensors(useSensor(PointerSensor));

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id !== over.id) {
      dispatch({ type: 'moveLineItem', parentIndex: itemIndex, id1: active.id, id2: over.id });
    }
    setActiveId(null);
  }

  return (
    <InnerContainer>
      <CollapseButton data-collapsed={!collapsed} onClick={() => dispatch({ type: 'toggleCollapse', id })}>
        <IconChevronRight />
        <Text className="!tw-text-sm !tw-font-medium !tw-text-gray-700">Columns ({fields.length || 0})</Text>
      </CollapseButton>
      <Collapse in={Boolean(collapsed)} transitionDuration={200} transitionTimingFunction="ease">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <SortableContext items={fields} strategy={verticalListSortingStrategy}>
            <ol className="tw-flex tw-flex-col tw-gap-2">
              {fields.map((lineItemField, lineItemIndex) => {
                const { id: lineItemId } = lineItemField;
                return (
                  <DraggableLineItemField
                    dispatch={dispatch}
                    isLastItem={false}
                    item={lineItemField}
                    itemIndex={lineItemIndex}
                    parentIndex={itemIndex}
                    active={lineItemId === activeId}
                    key={lineItemId}
                    showConfidences={showConfidences}
                    canEditFields={canEditFields}
                    enablePromptConfig={enablePromptConfig}
                  />
                );
              })}
            </ol>
          </SortableContext>
        </DndContext>
        {canEditFields ? (
          <Button
            className="!tw-mt-3"
            variant="borderless"
            onClick={() => dispatch({ type: 'addLineItemField', parentIndex: itemIndex })}
          >
            <FiPlus /> Add new column
          </Button>
        ) : null}
      </Collapse>
    </InnerContainer>
  );
}
