import { FormEventHandler, useState, MouseEvent, KeyboardEvent } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { Button, Modal, ModalProps, NewCard, Spinner } from '@components';
import { createCredentials } from '@contexts/AuthProvider';
import { useAuthContext, useLogger } from '@hooks';
import { escapeJSONString, wait } from '@utils';
import { LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY, SESSION_STORAGE_CREATED_ORGANIZATION_CLIENT_ID_KEY } from '@config/app';
import { styled } from '@config/stitches';
import IconBuilding from '@assets/icon-building-02.svg?react';

const Icon = styled('figure', {
  display: 'flex',
  justifyContent: 'center',
  justifySelf: 'flex-start',
  alignItems: 'center',
  alignSelf: 'flex-start',
  padding: '1em',
  color: '$primary600',
  backgroundColor: '$primary50',
  borderRadius: '10px',
  '& svg': {
    width: '1.3rem',
    height: '1.3rem',
  },
});

const CreateCard = styled(NewCard, {
  minWidth: 'min(350px, 100vw)',
  gap: '1.3rem',
  '& h1': {
    fontSize: '1.3rem',
    fontWeight: 500,
  },
  '& label': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    fontWeight: 500,
  },
});

export type CreateOrganizationModalProps = Omit<ModalProps, 'children'>;
export function CreateOrganizationModal({ onRequestClose, ...rest }: CreateOrganizationModalProps) {
  const { logger } = useLogger();
  const { client } = useAuthContext();
  const [waiting, setWaiting] = useState(false);
  const { t } = useTranslation('organizations');

  const [organizationName, setOrganizationName] = useState('');

  const { mutate: createOrganization, isPending: isCreating } = useMutation({
    mutationFn: ({ name }: { name: string }) => client!.makePostRequest('/organizations', { name }),
    onSuccess: async (newOrganization: any) => {
      // Artificial wait time when creating organization
      await wait(15000);
      // Set newly created organization's clientId, in order to wait for API key activation
      // when switching to new organization later
      sessionStorage.setItem(SESSION_STORAGE_CREATED_ORGANIZATION_CLIENT_ID_KEY, newOrganization?.clientId);
      logger.debug(`Set new organization clientId ${newOrganization?.clientId}`);
      window.localStorage.setItem(LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY, newOrganization?.clientId);
      const credentials = createCredentials(newOrganization?.clientId);
      credentials.initiateOAuthFlow();
    },
    onError: () => {
      setWaiting(false);
    },
  });

  const onCreate: FormEventHandler<HTMLFormElement> = (event) => {
    setWaiting(true);
    event.preventDefault();
    const organizationNameSanitized = escapeJSONString(organizationName);
    createOrganization({ name: organizationNameSanitized });
  };

  const handleOnRequestClose = (event: MouseEvent<Element, globalThis.MouseEvent> | KeyboardEvent<Element>) => {
    if (isCreating || waiting) return;
    if (onRequestClose) onRequestClose(event);
  };

  return (
    <Modal {...rest} onRequestClose={handleOnRequestClose}>
      <form id="createOrganization" onSubmit={onCreate}>
        <CreateCard padding="lg" shadow="xs">
          <Icon>
            <IconBuilding />
          </Icon>
          <h1>{t('createTitle')}</h1>
          <label>
            {t('createNameLabel')}
            <input
              name="organizationName"
              placeholder={t('createNamePlaceholder')}
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
            />
          </label>
          <Button type="submit" disabled={!organizationName || isCreating || waiting}>
            {isCreating || waiting ? (
              <>
                <Spinner />
                {t('createButtonNewLoadingText')}
              </>
            ) : (
              t('createButtonNewText')
            )}
          </Button>
        </CreateCard>
      </form>
    </Modal>
  );
}
