import ArrowUp from '@assets/icon-chevron-up-double.svg?react';
import { clamp } from '@utils';
import { DEFAULT_CONFIDENCE_THRESHOLD } from '@components';

export type ThresholdInputProps = {
  value: number | undefined;
  onChange: (value: number) => void;
};

export function ThresholdInput({ value, onChange }: ThresholdInputProps) {
  return (
    <div className="tw-flex tw-min-w-[110px] tw-items-center tw-gap-2 tw-self-center tw-rounded-lg tw-border tw-border-slate-200 tw-bg-white tw-px-2 tw-py-1.5">
      <ArrowUp className="tw-h-4 tw-w-4 tw-flex-shrink-0 tw-rotate-180 tw-self-center tw-text-warning-500" />
      <input
        style={{ border: 'unset' }}
        className="!tw-m-0 !tw-h-auto !tw-p-0 !tw-leading-4"
        type="number"
        placeholder={DEFAULT_CONFIDENCE_THRESHOLD.toString()}
        value={value}
        max={100}
        min={0}
        step={1}
        onChange={(e) => onChange(Number(e.target.value))}
        onBlur={(e) => onChange(clamp(Number(e.target.value), 0, 100))}
      />
      %
    </div>
  );
}
