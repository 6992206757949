import { FiBookOpen, FiMessageCircle, FiVideo, FiZap } from 'react-icons/fi';

import { Button } from '..';
import { BOOK_DEMO_URL } from '@config/app';
import { styled } from '@config/stitches';

const MenuItem = styled('li', {
  display: 'flex',
  alignItems: 'center',
  color: '$gray400',
  '&:hover': {
    color: '$gray25',
  },
  '&.bordered': {
    borderTop: '1px solid $gray500',
  },
  '& svg': {
    marginRight: '13px',
    width: '15px',
    height: '15px',
  },
  '& a:link, & a:visited, & button': {
    transition: 'color 200ms ease-in-out',
    padding: '10px 13px 10px 13px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'start',
    color: '$gray400',
    gap: 0,
    fontWeight: 400,
  },
  '& a:hover, .button:hover': {
    color: '$gray25',
  },
});

export function HelpMenu() {
  const openChat = () => window?._chatlio.open();

  return (
    <ul>
      <MenuItem>
        <a href="http://docs.cradl.ai/" target="cradl-docs">
          <FiBookOpen />
          Developer docs
        </a>
      </MenuItem>
      <MenuItem className="bordered">
        <a href={BOOK_DEMO_URL} target="cradl-book-help">
          <FiVideo />
          Book free onboarding
        </a>
      </MenuItem>
      <MenuItem className="bordered">
        <Button variant="plain" onClick={openChat}>
          <FiMessageCircle />
          Chat with us
        </Button>
      </MenuItem>
    </ul>
  );
}
