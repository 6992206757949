import { HTMLAttributes } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button, FeaturedIcon } from '@components';
import FlowIcon from '@assets/icon-flow-03.svg?react';
import HelpIcon from '@assets/icon-help-octagon.svg?react';
import CodeIcon from '@assets/icon-code-02.svg?react';

const ONBOARDING_USE_CASE_OPTIONS = [
  { label: 'Automate internal document workflows', Icon: FlowIcon },
  {
    label: 'Add intelligent document parsing to my SaaS/product',
    Icon: CodeIcon,
  },
  {
    label: 'Other/not sure',
    Icon: HelpIcon,
  },
];

const ONBOARDING_COUNT_OPTIONS = ['0 - 100', '100-500', '500-1000', '1000+'];

export type OnboardingFormStep2Values = {
  useCase: string;
  documentCount: string;
};

export type OnboardingFormStep2Props = {
  goToNextStep: (data: any) => void;
} & HTMLAttributes<HTMLDivElement>;

export function OnboardingFormStep2({ goToNextStep, ...rest }: OnboardingFormStep2Props) {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<OnboardingFormStep2Values>({
    mode: 'all',
  });

  const useCase = watch('useCase');
  const documentCount = watch('documentCount');

  const onSubmit: SubmitHandler<any> = (data) => {
    goToNextStep(data);
  };

  return (
    <div {...rest}>
      <h2>Help us customize your workspace</h2>
      <p className="tw-text-slate-500">This will help us support you appropriately as you get started.</p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="tw-my-5 tw-flex tw-flex-col tw-gap-5">
          <legend className="tw-mb-4 tw-font-medium">How do you plan to use Cradl AI?</legend>
          <ol className="tw-flex tw-flex-col tw-gap-1.5">
            {ONBOARDING_USE_CASE_OPTIONS.map(({ label, Icon }) => (
              <li key={label}>
                <label
                  key={label}
                  aria-selected={useCase === label ? 'true' : 'false'}
                  aria-invalid={errors.useCase ? 'true' : 'false'}
                  className="tw-flex tw-w-full tw-items-center tw-gap-3 tw-rounded-xl tw-border tw-border-slate-200 tw-bg-white tw-p-3 tw-font-medium tw-ring-primary-500 aria-[invalid='true']:tw-border-error-300 aria-[selected='true']:tw-border-primary-500 aria-[selected='true']:tw-shadow-md aria-[selected='true']:tw-ring-1"
                >
                  <FeaturedIcon
                    icon={<Icon className="tw-h-4 tw-w-4 tw-text-primary-600" />}
                    size="sm"
                    variant="default"
                    className="!tw-bg-gray-50"
                  />
                  {label}
                  <input
                    className="tw-h-[1px] tw-w-[1px] tw-opacity-0"
                    type="radio"
                    value={label}
                    {...register('useCase', { required: true })}
                  />
                </label>
              </li>
            ))}
          </ol>
          <legend className="tw-font-medium">How many documents do you plan to process each month?</legend>
          <ol className="tw-flex tw-justify-start tw-gap-3">
            {ONBOARDING_COUNT_OPTIONS.map((label) => (
              <li key={label}>
                <label
                  key={label}
                  aria-selected={documentCount === label ? 'true' : 'false'}
                  aria-invalid={errors.documentCount ? 'true' : 'false'}
                  className="tw-rounded-xl tw-border tw-border-slate-200 tw-bg-white tw-p-3 tw-font-medium tw-ring-primary-500 aria-[invalid='true']:tw-border-error-300 aria-[selected='true']:tw-border-primary-500 aria-[selected='true']:tw-shadow-md aria-[selected='true']:tw-ring-1"
                >
                  {label}
                  <input
                    className="tw-h-[1px] tw-w-[1px] tw-opacity-0"
                    type="radio"
                    value={label}
                    {...register('documentCount', { required: true })}
                  />
                </label>
              </li>
            ))}
          </ol>
        </fieldset>

        <Button className="tw-w-full" type="submit">
          Finish setup -&gt;
        </Button>
      </form>
    </div>
  );
}
