import { MINIMUM_REQUIRED_DOCUMENTS_TO_TRAIN } from '@config/app';
import { useModels, useOrganizationQuery } from '@hooks';
import { useListWorkflows } from '@features/flows/views/flow-list/useListWorkflows';

export type GettingStartedStep = {
  completed: boolean;
  name: string;
  tooltip: string;
};

export function useGettingStarted() {
  const { data: models } = useModels();
  const { data: workflows } = useListWorkflows();
  const { data: organization } = useOrganizationQuery();

  const steps: GettingStartedStep[] = [];

  let createdModelStep: GettingStartedStep = {
    name: 'Create a model',
    tooltip: 'Go to models or use a template to create a model',
    completed: false,
  };
  const hasCreatedModel = Boolean(organization?.numberOfModelsCreated);
  if (hasCreatedModel) {
    createdModelStep.completed = true;
  }
  steps.push(createdModelStep);

  let trainModelStep: GettingStartedStep = {
    name: 'Train your model',
    tooltip: `Go to your model and open the training panel. Annotate minimum ${MINIMUM_REQUIRED_DOCUMENTS_TO_TRAIN} documents to start model training`,
    completed: false,
  };
  const createdModels = models?.models?.filter((model) => model.organizationId !== 'las:organization:cradl');
  const hasTrainedModel = createdModels?.find((model) => model.status === 'active');
  if (hasTrainedModel) {
    trainModelStep.completed = true;
  }
  steps.push(trainModelStep);

  let runModelStep: GettingStartedStep = {
    name: 'Run your model',
    tooltip: 'Go to your model, open the runs panel and upload a document to see your model in action',
    completed: false,
  };

  let hasRunModel = false;
  hasRunModel ||= Boolean(organization?.monthlyNumberOfWorkflowExecutionsCreated);
  if (!hasRunModel) {
    for (const usage of Object.values(organization?.monthlyUsageSummary?.usage ?? {})) {
      // @ts-ignore
      hasRunModel ||= Boolean(usage?.workflowExecutionsCreated);
    }
  }
  if (hasRunModel) {
    runModelStep.completed = true;
  }
  steps.push(runModelStep);

  let addModelTriggerStep: GettingStartedStep = {
    name: 'Add a trigger to your model',
    tooltip: 'Go to your model and configure a trigger',
    completed: false,
  };
  const hasAddedModelTrigger = Boolean(
    workflows?.find(
      (workflow) =>
        // @ts-expect-error
        workflow.emailConfig?.email ||
        Boolean(Object.values(workflow.metadata?.input ?? {}).find((value) => Boolean(value)))
    )
  );
  if (hasAddedModelTrigger) {
    addModelTriggerStep.completed = true;
  }
  steps.push(addModelTriggerStep);

  let addModelExportStep: GettingStartedStep = {
    name: 'Add an export destination to your model',
    tooltip: 'Go to your model and configure an export destination',
    completed: false,
  };
  const hasAddedModelExport = Boolean(
    workflows?.find(
      (workflow) =>
        workflow.metadata?.outputWebhookUri ||
        Boolean(Object.values(workflow.metadata?.output ?? {}).find((value) => Boolean(value)))
    )
  );
  if (hasAddedModelExport) {
    addModelExportStep.completed = true;
  }
  steps.push(addModelExportStep);

  const numCompletedSteps = steps.filter((step) => step.completed).length;

  return { steps, numCompletedSteps };
}
