import { useTranslation } from 'react-i18next';
import { styled } from '@config/stitches';

const NoPredictionsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& div': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    maxWidth: '250px',
  },
  '& h2': {
    color: '$gray700',
    fontWeight: 500,
    fontSize: '1.2rem',
  },
  '& p': {
    color: '$gray500',
  },
});

export function NoPredictionsYet() {
  const { t } = useTranslation('testModel');
  return (
    <NoPredictionsContainer>
      <div>
        <h2>{t('predictionsEmptyStateHeader')}</h2>
        <p>{t('predictionsEmptyStateText')}</p>
      </div>
    </NoPredictionsContainer>
  );
}
