import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { DatasetList } from '@lucidtech/las-sdk-browser';
import { useAuthContext } from './useAuthContext';

/**
 * Hook to get datasets data from API, using useQuery and its cache.
 * @returns
 */
export function useDatasets(
  options?: Omit<
    UseQueryOptions<DatasetList | undefined, unknown, DatasetList | undefined, string[]>,
    'queryKey' | 'queryFn'
  >
) {
  const { client } = useAuthContext();
  const useQueryResult = useQuery({ queryKey: ['datasets'], queryFn: () => client?.listDatasets(), ...options });
  return useQueryResult;
}
