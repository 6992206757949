import { ReactNode, useContext, useMemo } from 'react';

import { LoggerContext, LoggerContextValues } from './LoggerContext';
import { SimpleLogger } from '../../logger/SimpleLogger';

export interface LoggerProviderProps {
  children?: ReactNode | null;
  identifier?: string;
}

export function LoggerProvider({ children, identifier }: LoggerProviderProps) {
  const parentContext = useContext(LoggerContext);
  const value: LoggerContextValues = useMemo(() => {
    const logger = parentContext?.logger ? parentContext.logger.child(identifier) : new SimpleLogger({ identifier });
    return { logger };
  }, [identifier, parentContext]);

  return <LoggerContext.Provider value={value}>{children}</LoggerContext.Provider>;
}
