import { HTMLAttributes } from 'react';

import styles from './Card.module.css';

export type CardBodyProps = HTMLAttributes<HTMLDivElement>;

export function CardBody({ className, ...rest }: CardBodyProps) {
  const classNames = `${styles.body} ${className ? ` ${className}` : ''}`;
  return <div className={classNames} {...rest} />;
}
