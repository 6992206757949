import produce from 'immer';
import type { Organization } from '@lucidtech/las-sdk-browser';

/**
 * TODO: Remove this when underlying issue is fixed: Since we are hiding appClients
 * without a secret (to prevent deletion of the default login client), the appClient limit count is off.
 *
 * The problem is that we do not have access to what kind of appClients has been created in the organization count.
 * For now, assume most people will not be creating additional non-secret appclients, and just do a "appclients - 1"
 * transformation? This will then be off if somebody creates additional non-secret clients.
 *
 * @param response
 */
export function transformOrganizationResponse(response: Organization): Organization {
  return produce(response, (draft) => {
    draft.numberOfAppClientsAllowed = draft.numberOfAppClientsAllowed - 1;
    draft.numberOfAppClientsCreated = draft.numberOfAppClientsCreated - 1;
  });
}
