import { PredictionResponse } from '@lucidtech/las-sdk-browser';
import { DEFAULT_CONFIDENCE_GOOD_THRESHOLD } from '@config/app';
import { styled } from '@config/stitches';
import { useMemo } from 'react';
import { Button, StitchesBadge } from '@components';
import { useCopy } from '@hooks';
import { FiCheck, FiCopy } from 'react-icons/fi';

const JSONViewContainer = styled('pre', {
  position: 'relative',
  overflow: 'auto',
  maxWidth: '100%',
  width: '100%',
  fontSize: '0.7rem',
  margin: 0,
  paddingTop: '$3',

  '& .highlightJson': {
    color: '$primary700',
  },
});

const CopyButton = styled('div', {
  position: 'sticky',
  top: 0,
  zIndex: 2,

  '& button': {
    width: '30px',
    height: '30px',
    position: 'absolute',
    right: 0,
    top: 0,
    color: '$gray500',
  },

  '& svg': {
    width: '18px',
    height: '18px',
  },

  '&:hover svg': {
    color: '$primary700',
  },
});

export type JSONViewProps = {
  json: Record<any, any>;
  /**
   * Minimum prediction confidence to be considered as a "good" confidence.
   * Range 0 to 100
   * */
  goodConfidenceThreshold?: number;
};

const splitRegex =
  /("confidence": (?:\d\.\d+|\d)|"label":|"value":|"page":|"location":|"rawValue":|"isEdited":|"automated":|"pages":)/;
const confidenceRegex = /(?:"confidence":.*?)(\d\.\d+|\d)/;

export function JSONView({ json, goodConfidenceThreshold = DEFAULT_CONFIDENCE_GOOD_THRESHOLD }: JSONViewProps) {
  const { copied, onCopy } = useCopy(JSON.stringify(json, null, 2));

  const highlighted = useMemo(() => {
    const asText = JSON.stringify(json, null, 2);
    return asText.split(splitRegex).map((textPart, i) => {
      if (splitRegex.test(textPart)) {
        const confidenceMatch = textPart.match(confidenceRegex);
        if (confidenceMatch) {
          const confidence = Number(confidenceMatch[1]) || 0;
          const good = confidence >= goodConfidenceThreshold / 100;
          return (
            <span key={`${json.predictionId}-${i}-${goodConfidenceThreshold}`}>
              <span className="highlightJson">"confidence":</span>
              <StitchesBadge variant={good ? 'success' : 'error'} className="ml-1">
                {confidence}
              </StitchesBadge>
            </span>
          );
        }
        return (
          <span key={`${json.predictionId}-${i}-${goodConfidenceThreshold}`} className="highlightJson">
            {textPart}
          </span>
        );
      }

      return textPart;
    });
  }, [json, goodConfidenceThreshold]);

  return (
    <JSONViewContainer>
      <CopyButton>
        <Button variant="plain" onClick={() => onCopy()}>
          {copied ? <FiCheck /> : <FiCopy />}
        </Button>
      </CopyButton>
      {highlighted}
    </JSONViewContainer>
  );
}
