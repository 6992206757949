import { Alert, Button } from '@components';
import { FiArrowRight } from 'react-icons/fi';

export function ErrorMessage() {
  return (
    <Alert variant="error" badgeContent="There was an error creating a data report using your current data">
      <div className="flex justify-between flex-1">
        Get in touch with support using the live chat
        <Button variant="plain" className="ml-3" onClick={() => window?._chatlio?.open()}>
          Chat with us <FiArrowRight />
        </Button>
      </div>
    </Alert>
  );
}
