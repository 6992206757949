import { HTMLAttributes } from 'react';

import styles from './Card.module.css';

export type CardProps = {
  variant?: 'primary' | 'primary-soft' | 'secondary';
} & HTMLAttributes<HTMLDivElement>;

export function Card({ className, variant, ...rest }: CardProps) {
  const variantClass = variant ? ` ${styles[variant]}` : '';
  const classNames = `${styles.card}${className ? ` ${className}` : ''}${variantClass}`;
  return <div className={classNames} {...rest} />;
}
