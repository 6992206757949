import { ComponentProps } from 'react';
import ContentLoader from 'react-content-loader';

import { styled } from '@config/stitches';
import { Entity, useProfile } from '@hooks';
import { Avatar, AvatarProps } from '@components';
import { Tooltip } from '@mantine/core';

const LoadingProfile = () => (
  <ContentLoader
    speed={2}
    width={150}
    height={30}
    viewBox="0 0 150 30"
    backgroundColor="rgba(227, 235, 246, 0.466)"
    foregroundColor="rgba(191, 213, 243, 0.445)"
  >
    <circle cx="15" cy="15" r="15" />
    <rect x="43" y="7.5" rx="2" ry="2" width="45" height="15" />
    <rect x="96" y="7.5" rx="2" ry="2" width="55" height="15" />
  </ContentLoader>
);

const StyledUserContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$3',
});

export type UserItemProps = {
  hideName?: boolean;
  entity?: Entity | null;
  avatarSize?: AvatarProps['size'];
} & ComponentProps<typeof StyledUserContainer>;

export function UserItem({ entity, hideName, avatarSize = 'sm', ...rest }: UserItemProps) {
  const { data: profile, isLoading } = useProfile(entity?.profileId);
  const name =
    entity?.profileId && profile
      ? `${profile?.givenName || ''} ${profile?.familyName || ''}`
      : entity?.name || entity?.userId || entity?.appClientId;

  return (
    <StyledUserContainer {...rest}>
      {isLoading ? (
        <LoadingProfile />
      ) : (
        <>
          <Tooltip label={name}>
            <div>
              <Avatar size={avatarSize} name={name} avatar={profile?.picture} />
            </div>
          </Tooltip>
          {!hideName && <span className="name">{name}</span>}
        </>
      )}
    </StyledUserContainer>
  );
}
