import { HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { FiUserPlus } from 'react-icons/fi';
import Tippy from '@tippyjs/react';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/border.css';

import { ProfileMenu } from './ProfileMenu';
import IconSettings from '@assets/icon-settings.svg?react';
import IconHelp from '@assets/icon-help.svg?react';
import styles from './TopBar.module.scss';
import { HelpMenu } from './HelpMenu';
import { ButtonLink } from '../Button';
import { styled } from '@config/stitches';
import { Logo } from '@components/Logo';
import { NavigationLinks } from './NavigationLinks';

export type TopBarProps = HTMLAttributes<HTMLBaseElement>;

const NavContainer = styled('nav', {
  position: 'relative',
  display: 'flex',
  backgroundColor: '$gray900',
  borderBottom: '1px solid $gray300',
  color: '$gray25',
  alignItems: 'center',
  '@bp1': {
    padding: '$3 $3 $3 $4',
  },
  '@bp2': {
    padding: '$3 $3 $3 $7',
  },
  '@bp3': {
    padding: '$3 $3 $3 $9',
  },
  '@bp4': {
    padding: '$3 $3 $3 $10',
  },
  '& svg': {
    flexShrink: 0,
  },
});

const ExtraNav = styled('section', {
  display: 'flex',
  alignItems: 'center',
  paddingRight: '25px',
  marginRight: '25px',
  marginLeft: 'auto',
  gap: '18px',
  borderRight: ' 1px solid $gray500',
  '@media (max-width: 1200px)': {
    flexWrap: 'wrap',
  },

  '& .tippy-content': {
    padding: 0,
  },

  '& .tippy-box': {
    border: '1px solid $gray500',
    borderRadius: '4px',
    backgroundColor: '$gray900',
  },

  '& .tippy-arrow': {
    color: '$gray900',
    borderColor: '$gray500',
  },
});

const TopBarLogo = styled(Logo, {
  height: '32px',
  width: 'auto',
});

export function TopBar(props: TopBarProps) {
  return (
    <NavContainer {...props}>
      <Link to="/" state={{ ignoreRedirect: true }}>
        <TopBarLogo variant="dark" />
      </Link>
      <NavigationLinks />
      <ExtraNav>
        <ButtonLink variant="secondary-transparent" to="/settings/workspace" state={{ shouldOpenInvite: true }}>
          <FiUserPlus className="mr-2" />
          Invite your team
        </ButtonLink>
        <div className="flex align-center">
          <Tippy content={<HelpMenu />} interactive={true} animation="shift-away">
            <div className={`${styles.iconLink} ${styles.helpIcon}`}>
              <IconHelp className={styles.icon} /> Help
            </div>
          </Tippy>
        </div>
        <Link to="/settings" className={styles.iconLink}>
          <IconSettings className={styles.icon} /> Settings
        </Link>
      </ExtraNav>
      <ProfileMenu />
    </NavContainer>
  );
}
