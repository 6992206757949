import { MutableRefObject, useRef } from 'react';

export function useLazyRef<T>(initFunction: () => T) {
  // not initialized yet
  const ref = useRef<T | null>(null);
  // if it's not initialized (1st render)
  if (ref.current === null) {
    // we initialize it
    ref.current = initFunction();
  }
  // new we return the initialized ref
  return ref as MutableRefObject<T>;
}
