import { createContext } from 'react';
import { Client } from '@lucidtech/las-sdk-browser';
import { AuthorizationCodeCredentials } from '@lucidtech/las-sdk-browser';

export interface AuthContextValues {
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  client: Client | null;
  setCredentials: (credentials: AuthorizationCodeCredentials) => void;
  credentials: AuthorizationCodeCredentials | null;
  jwt: unknown | null;
  scopes: Array<string>;
}

export const AuthContext = createContext<AuthContextValues | undefined>(undefined);
