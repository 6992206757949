import type { ComponentProps } from 'react';

import { styled } from '@config/stitches';

export const Divider = styled('hr', {
  backgroundColor: 'unset',
  color: 'unset',
  margin: '2em 0',
  height: '1px',
  border: 'none',
  borderTop: '1px solid $gray200',
  variants: {
    size: {
      md: {
        margin: '2em 0',
      },
      sm: {
        margin: '1.5em 0',
      },
      xs: {
        margin: '1em',
      },
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export type DividerProps = ComponentProps<typeof Divider>;
