import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useAuthContext } from './useAuthContext';
import { transformOrganizationResponse } from '@transformers';
import { Organization } from '@lucidtech/las-sdk-browser';

export type UseOrganizationQueryOptions = Omit<
  UseQueryOptions<Organization, unknown, Organization, string[]>,
  'queryKey' | 'queryFn'
>;
export function useOrganizationQuery(options?: UseOrganizationQueryOptions) {
  const { client } = useAuthContext();
  const queryData = useQuery({
    queryKey: ['organization'],
    queryFn: () => client!.getOrganization('me'),
    ...options,
    select: transformOrganizationResponse,
  });
  // @ts-expect-error
  const createdTime = new Date(queryData.data?.createdTime);
  const cutoff = new Date('2024-05-15');
  const isNewOrganization = createdTime >= cutoff;
  return {
    ...queryData,
    isNewOrganization,
  };
}
