import { createContext } from 'react';

export type DatasetDeletionState = Record<
  string,
  {
    isLoading: boolean;
    error?: string;
    documentsLeft: number;
    startingDocuments: number;
  }
>;

export type DatasetDeletionValues = {
  datasets: DatasetDeletionState;
  addDataset: (datasetId: string, retryDocumentsLeft?: number, callback?: () => void) => void;
};

export const DatasetDeleteContext = createContext<DatasetDeletionValues | undefined>(undefined);
