import type { Organization, PrivateProfile, UpdateProfileOptions } from '@lucidtech/las-sdk-browser';
import { P, match } from 'ts-pattern';
import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { APIErrorAlert, Loading, Logo } from '@components';
import {
  LOCAL_STORAGE_AUTH_TOKEN_PREFIX,
  LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY,
  LOCAL_STORAGE_LOGIN_ORGANIZATION_ID,
  SESSION_STORAGE_CREATED_ORGANIZATION_CLIENT_ID_KEY,
} from '@config/app';
import { createCredentials } from '@contexts/AuthProvider';
import { useAuthContext } from '@hooks';
import { wait } from '@utils';

import onboardingBackground from './onboarding-bg.svg';
import { StepIndicator } from './step-indicator';
import { OnboardingFormStep1 } from './onboarding-form-step-1';
import { OnboardingFormStep2 } from './onboarding-form-step-2';

type OnboardingValues = {
  givenName: string;
  familyName: string;
  companyName: string;
  companyWebsite: string;
  acceptedTerms: boolean;
  useCase: string;
  documentCount: string;
};

export type OnboardingFormProps = {
  profileData: PrivateProfile;
};

export function OnboardingForm({ profileData }: OnboardingFormProps) {
  const [formState, setFormState] = useState<Partial<OnboardingValues>>({});
  const [step, setStep] = useState<1 | 2>(1);
  const [loading, setLoading] = useState(false);
  const { client, setCredentials } = useAuthContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: organizationsList } = useQuery({
    queryKey: ['organizations'],
    queryFn: () => client!.makeGetRequest('/organizations'),
  });

  const organization = useMemo(() => {
    const organizationId = localStorage.getItem(LOCAL_STORAGE_LOGIN_ORGANIZATION_ID);
    if (organizationId) {
      // @ts-expect-error
      return organizationsList?.organizations.find((organization) => organization.organizationId === organizationId);
    }
    // @ts-expect-error
  }, [organizationsList?.organizations]);

  const initialValues = useMemo(() => {
    return {
      givenName: profileData.givenName ?? '',
      familyName: profileData.familyName ?? '',
      companyName: '',
      companyWebsite: '',
      acceptedTerms: false,
    };
  }, [profileData]);

  const onboard = useMutation({
    mutationFn: async ({ clientId, userData }: { clientId: string; userData: UpdateProfileOptions }) => {
      await client!.makePatchRequest('/profiles/me', userData);
      return clientId;
    },
    onSuccess: (clientId) => {
      window.localStorage.setItem(LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY, clientId);
      localStorage.removeItem(LOCAL_STORAGE_LOGIN_ORGANIZATION_ID);
      const credentials = createCredentials(clientId);
      const hasStoredJWT = window.localStorage.getItem(`${LOCAL_STORAGE_AUTH_TOKEN_PREFIX}/${clientId}`);
      // Previous JWT with possibly valid auth token and/or refresh token exist for this clientId
      if (hasStoredJWT) {
        setCredentials(credentials);
        queryClient.clear();
        navigate('/models');
      } else {
        credentials.initiateOAuthFlow();
      }
    },
  });

  const onboardAndCreateOrganization = useMutation({
    mutationFn: async ({
      userData,
      organizationName,
    }: {
      userData: UpdateProfileOptions;
      organizationName: string;
    }) => {
      await client!.makePatchRequest('/profiles/me', userData);
      const organization = await client!.makePostRequest<Organization>('/organizations', { name: organizationName });
      return { organization };
    },
    onSuccess: async ({ organization }) => {
      // Will always be not null, as we just created the organization,
      // but in order to satisfy Typescript and be 100% sure, let's check anyway
      if (organization.clientId) {
        // Artificial wait time when creating organization
        await wait(4000);
        // Set newly created organization's clientId, in order to wait for API key activation
        // when switching to new organization later
        sessionStorage.setItem(SESSION_STORAGE_CREATED_ORGANIZATION_CLIENT_ID_KEY, organization.clientId);
        window.localStorage.setItem(LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY, organization.clientId);
        const credentials = createCredentials(organization.clientId);
        credentials.initiateOAuthFlow();
      }
    },
    onError: () => {
      setLoading(false);
    },
  });

  const goToNextStep = (data: Partial<OnboardingValues>) => {
    setFormState({ ...formState, ...data });
    setStep(2);
  };

  const finishSetup = useCallback(
    (data: Partial<OnboardingValues>) => {
      const finalState = { ...formState, ...data };
      setFormState(finalState);
      setLoading(true);

      const payload = {
        metadata: {
          onboarding: {
            description: finalState.useCase,
            documentCount: finalState.documentCount,
            acceptedTerms: finalState.acceptedTerms,
            companyName: finalState.companyName,
            companyWebsite: finalState.companyWebsite,
          },
        },
        givenName: finalState.givenName,
        familyName: finalState.familyName,
      };

      if (organization) {
        onboard.mutate({ clientId: organization.clientId, userData: payload });
      } else {
        onboardAndCreateOrganization.mutate({ userData: payload, organizationName: finalState.companyName ?? '' });
      }
    },
    [formState, onboard, onboardAndCreateOrganization, organization]
  );

  return (
    <div className="tw-bg-[linear-gradient(white, #F4F3FA)] tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-pt-24">
      <div
        style={{
          backgroundImage: `url('${onboardingBackground}')`,
          backgroundPosition: '50% 50%',
        }}
        className="tw-pointer-events-none tw-absolute tw-top-[-50%] tw-h-full tw-w-full  tw-bg-no-repeat"
      />
      <div className="tw-z-10 tw-flex tw-flex-col tw-items-center tw-gap-12">
        <Logo className="tw-h-10" />
        <div className="tw-min-w-[500px] tw-rounded-lg tw-border tw-border-slate-200 tw-bg-white tw-p-6 tw-shadow-md">
          <StepIndicator max={2} current={step} className="tw-mb-5" />
          {match({ step, loading, error: onboardAndCreateOrganization.error })
            .with({ loading: true }, () => (
              <div className="tw-py-12">
                <Loading>Setting up workspace...</Loading>
              </div>
            ))
            .with({ error: P.not(P.nullish), loading: false }, ({ error }) => <APIErrorAlert axiosError={error} />)
            .with({ step: 1, loading: false, error: P.nullish }, () => (
              <OnboardingFormStep1 initialValues={initialValues} goToNextStep={goToNextStep} />
            ))
            .with({ step: 2, loading: false, error: P.nullish }, () => (
              <OnboardingFormStep2 goToNextStep={finishSetup} />
            ))
            .otherwise(() => null)}
        </div>
      </div>
    </div>
  );
}
