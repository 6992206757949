import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from '@hooks';

export function useListWorkflows() {
  const { client } = useAuthContext();
  const queryResult = useQuery({
    queryKey: ['workflows'],
    queryFn: () => client!.listWorkflows(),
    select: (data) => {
      return data.workflows.filter((workflow) => workflow.metadata?.appClientId);
    },
  });

  return queryResult;
}
