import { ButtonHTMLAttributes, ForwardedRef, forwardRef, MouseEvent } from 'react';

import styles from './Button.module.scss';
import { useLocation } from 'react-router-dom';
import { useTracker } from '@hooks';

export type ButtonProps = {
  variant?:
    | 'primary'
    | 'primary-dark'
    | 'primary-destructive'
    | 'secondary'
    | 'secondary-gray'
    | 'secondary-dark'
    | 'secondary-gray-destructive'
    | 'secondary-transparent'
    | 'plain'
    | 'borderless'
    | 'action';
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  buttonEventName?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

function ForwardRefButton(
  { className, variant, size = 'md', buttonEventName, ...rest }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const { tracker } = useTracker();
  const location = useLocation();
  const variantClassName = variant ? ` ${styles[variant]}` : '';
  const extraClassNames = className ? ` ${className}` : '';
  const classNames = `${styles.button} ${variantClassName} ${styles[size]} ${extraClassNames}`;

  if (buttonEventName && rest.onClick) {
    const oldOnClick = rest.onClick;
    delete rest.onClick;
    const onClick = (event: MouseEvent<HTMLButtonElement>) => {
      oldOnClick?.(event);
      tracker.track('Button Clicked', {
        properties: {
          name: buttonEventName,
          url: location.pathname,
        },
      });
    };
    return <button className={classNames} ref={ref} {...rest} onClick={onClick} />;
  } else {
    return <button className={classNames} ref={ref} {...rest} />;
  }
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(ForwardRefButton);
