import type { ComponentProps } from 'react';
import { Model } from '@lucidtech/las-sdk-browser';

import { styled } from '@config/stitches';

const StatusBadge = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: '100px',
  padding: '3px 7px',
  fontWeight: 500,
  justifySelf: 'flex-start',
  flex: 0,
  gap: '6px',
  whiteSpace: 'nowrap',
  fontSize: '0.8rem',
  '& .indicator': {
    display: 'inline-block',
    width: '0.45em',
    height: '0.45em',
    borderRadius: '50%',
  },
  variants: {
    variant: {
      active: {
        backgroundColor: '$success50',
        color: '$success700',
        '& .indicator': {
          backgroundColor: '$success700',
        },
      },
      inactive: {
        backgroundColor: '$grayneutral25',
        color: '$graycool700',
        '& .indicator': {
          backgroundColor: '$gray400',
        },
      },
    },
  },
});

export type DeployedStatusBadgeProps = {
  model: Model;
  noTrainings?: boolean;
} & ComponentProps<typeof StatusBadge>;
export function DeployedStatusBadge({ model, noTrainings, ...rest }: DeployedStatusBadgeProps) {
  const statusText = model.status === 'active' ? (noTrainings ? 'Pre-trained' : 'Deployed') : 'Inactive';
  return (
    <StatusBadge variant={model.status} {...rest}>
      <span className="indicator" />
      {statusText}
    </StatusBadge>
  );
}
