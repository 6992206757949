import { ReactNode, useEffect, useMemo, useState } from 'react';
import { AuthorizationCodeCredentials, Client } from '@lucidtech/las-sdk-browser';
import jwt_decode from 'jwt-decode';

import { createCredentials } from './auth';
import { Loading } from '@components';
import { AuthContext, AuthContextValues } from './AuthContext';
import { LOCAL_STORAGE_AUTH_TOKEN_PREFIX, LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY } from '@config/app';
import { getScopes } from '@utils/getScopes';

export interface AuthProviderProps {
  children?: ReactNode | null;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isChecking, setChecking] = useState(true);
  const [client, setClient] = useState<Client | null>(null);
  const [credentials, setCredentials] = useState<AuthorizationCodeCredentials | null>(null);

  const jwt = useMemo(() => {
    if (isLoggedIn && credentials) {
      const lastActiveClientId =
        window.localStorage.getItem(LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY) || import.meta.env.VITE_GLOBAL_CLIENT_ID;
      const token = window.localStorage.getItem(`${LOCAL_STORAGE_AUTH_TOKEN_PREFIX}/${lastActiveClientId}`);
      if (token) {
        try {
          const decodedToken = jwt_decode(token);
          return decodedToken;
        } catch (error) {
          console.error(error);
        }
      }
    }
    return null;
  }, [isLoggedIn, credentials]);

  const scopes = useMemo(() => {
    const scopes = getScopes(jwt);
    return scopes;
  }, [jwt]);

  // check if user already has a token
  useEffect(() => {
    const lastActiveClientId =
      window.localStorage.getItem(LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY) || import.meta.env.VITE_GLOBAL_CLIENT_ID;
    const token = window.localStorage.getItem(`${LOCAL_STORAGE_AUTH_TOKEN_PREFIX}/${lastActiveClientId}`);
    if (token && !isLoggedIn) {
      const credentials = createCredentials(lastActiveClientId);
      setCredentials(credentials);
    } else {
      setChecking(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (credentials) {
      setClient(new Client(credentials));
      credentials
        .getAccessToken()
        .then(() => {
          setIsLoggedIn(true);
        })
        .catch((error: any) => {
          console.error('Error receiving access token', error);
          const lastActiveClientId =
            window.localStorage.getItem(LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY) || import.meta.env.VITE_GLOBAL_CLIENT_ID;
          window.localStorage.removeItem(`${LOCAL_STORAGE_AUTH_TOKEN_PREFIX}/${lastActiveClientId}`);
          setIsLoggedIn(false);
        })
        .finally(() => {
          setChecking(false);
        });
    }
  }, [credentials, setClient]);

  const value: AuthContextValues = { client, isLoggedIn, setIsLoggedIn, credentials, setCredentials, jwt, scopes };

  if (isChecking) {
    return <Loading />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
