import { ComponentProps, ReactNode } from 'react';
import { Box, NewAlert } from '@components';

import AlertTriangleIcon from '@assets/icon-alert-triangle.svg?react';
import { parseAxiosError } from '@utils/parseAxiosError';

export type APIErrorAlertProps = Omit<ComponentProps<typeof NewAlert>, 'children'> & {
  axiosError?: any;
  children?: ReactNode;
};
export function APIErrorAlert({ children, css, axiosError, ...props }: APIErrorAlertProps) {
  const errorString = axiosError ? parseAxiosError(axiosError) : null;
  return (
    <NewAlert
      variant="error"
      css={{ display: 'flex', flexDirection: 'row', gap: '0.5em', padding: '0.5em', fontSize: '0.85rem', ...css }}
      {...props}
    >
      <Box flex css={{ alignItems: 'center', width: '1.2em', height: '1.5em' }}>
        <AlertTriangleIcon />
      </Box>
      <Box flex flexDirection="column" css={{ gap: '0.5em' }}>
        <span>{errorString}</span>
        {children && <span>{children}</span>}
      </Box>
    </NewAlert>
  );
}
