import { Accordion } from '@mantine/core';

import { styled } from '@config/stitches';

export const AccordionItem = styled(Accordion.Item, {
  '&:not(:last-child)': {
    borderBottom: '1px solid $gray200',
  },
  '& .mantine-Accordion-content': {
    padding: '$5',
    color: '$gray500',
    fontSize: '0.9rem',
    backgroundColor: 'transparent',
    borderTop: '1px solid $gray200',
  },
});

export const AccordionControl = styled(Accordion.Control, {
  backgroundColor: 'transparent',
  padding: '$4',
  color: '$gray900',
  fontWeight: 500,
  display: 'flex',
  width: '100%',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  textAlign: 'left',
  border: 'none',
  gap: '20px',
  '& .mantine-Accordion-label svg': {
    width: '1.5em',
    height: '1.5em',
  },
  '& .mantine-Accordion-label': {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    gap: '$3',
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .mantine-Accordion-chevron': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 200ms ease',
    width: '24px',
    minWidth: '24px',
  },
  '& .mantine-Accordion-chevron[data-rotate="true"]': {
    transform: 'rotate(180deg)',
  },
});
