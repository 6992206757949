import type { MantineThemeOverride } from '@mantine/core';

const theme: MantineThemeOverride = {
  fontFamily:
    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  components: {
    Select: {
      styles: {
        input: {
          '&:focus': {
            borderColor: 'var(--cradl-app__-colors-primary700)',
          },
        },
        item: {
          '&[data-selected]': {
            backgroundColor: 'var(--cradl-app__-colors-primary700)',
          },
          '&[data-selected][data-hovered]': {
            backgroundColor: 'var(--cradl-app__-colors-primary700)',
          },
        },
      },
    },
    Radio: {
      styles: {
        root: {
          display: 'flex',
          alignItems: 'center',
        },
        label: {
          cursor: 'pointer',
        },
        radio: {
          cursor: 'pointer',
          '&:checked': {
            borderColor: '#535DC6',
            backgroundColor: '#535DC6',
          },
        },
      },
    },
    ScrollArea: {
      styles: {
        thumb: {
          backgroundColor: 'var(--cradl-app__-colors-gray200)',
        },
        scrollbar: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:hover .mantineScrollAreaThumb, & .mantineScrollAreaThumb:hover': {
            backgroundColor: 'var(--cradl-app__-colors-gray300)',
          },
        },
      },
    },
  },
};

export default theme;
