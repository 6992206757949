import { useProfile } from '@hooks';
import { match } from 'ts-pattern';
import { Outlet } from 'react-router-dom';

import { OnboardingForm } from '../views';
import { Button, Loading, SimpleAlert } from '@components';

export function OnboardingGuard() {
  const { data: profileData, error, refetch, isLoading: userIsLoading } = useProfile('me');

  if (!profileData && error) {
    return (
      <SimpleAlert>
        An error occured loading user information.{' '}
        <Button variant="primary" onClick={() => refetch()}>
          Retry?
        </Button>
      </SimpleAlert>
    );
  }

  if (userIsLoading || !profileData) {
    return <Loading />;
  }

  const hasOnboarded = Boolean(profileData?.metadata?.onboarding);

  return match(hasOnboarded)
    .with(true, () => <Outlet />)
    .with(false, () => <OnboardingForm profileData={profileData} />)
    .exhaustive();
}
