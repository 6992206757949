import { styled } from '@config/stitches';

export const Container = styled('div', {
  display: 'flex',
});

export const LeftContainer = styled('section', {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

export const TopContainer = styled('div', {
  paddingTop: '$5',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '$10',
  borderBottom: '1px solid $gray200',
  '@bp1': {
    paddingLeft: '$4',
  },
  '@bp2': {
    paddingLeft: '$7',
  },
  '@bp3': {
    paddingLeft: '$9',
  },
  '@bp4': {
    paddingLeft: '$10',
  },
});

export const ContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '$5',
  paddingLeft: '$10',
  paddingRight: '$5',
  paddingBottom: '$8',
  '@bp1': {
    paddingLeft: '$4',
  },
  '@bp2': {
    paddingLeft: '$7',
  },
  '@bp3': {
    paddingLeft: '$9',
  },
  '@bp4': {
    paddingLeft: '$10',
  },
});
