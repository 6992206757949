import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import appClients from './en/appClients.json';
import common from './en/common.json';
import datasets from './en/datasets.json';
import errorCodes from './en/errorCodes.json';
import flowsList from './en/flows-list.json';
import models from './en/models.json';
import modelsDelete from './en/models-delete.json';
import modelsIntegrate from './en/models-integrate.json';
import modelsOverview from './en/models-overview.json';
import modelsPretrained from './en/models-pretrained.json';
import modelsCreate from './en/models-create.json';
import navigation from './en/navigation.json';
import onboarding from './en/onboarding.json';
import organizations from './en/organizations.json';
import settingsBilling from './en/settings-billing.json';
import settingsNavigation from './en/settings-navigation.json';
import settingsProfile from './en/settings-profile.json';
import settingsUpgrade from './en/settings-upgrade.json';
import settingsWorkspace from './en/settings-workspace.json';
import signin from './en/signin.json';
import testModel from './en/test-model.json';
import wizard from './en/training-wizard.json';
import workflowExecutionsDelete from './en/workflowExecutions-delete.json';

export const resources = {
  en: {
    appClients,
    common,
    datasets,
    errorCodes,
    'flows-list': flowsList,
    models,
    navigation,
    onboarding,
    organizations,
    'settings-billing': settingsBilling,
    'settings-navigation': settingsNavigation,
    'settings-profile': settingsProfile,
    'settings-upgrade': settingsUpgrade,
    'settings-workspace': settingsWorkspace,
    signin,
    testModel,
    wizard,
    'models-delete': modelsDelete,
    'models-integrate': modelsIntegrate,
    'models-overview': modelsOverview,
    'models-pretrained': modelsPretrained,
    'models-create': modelsCreate,
    'workflowExecutions-delete': workflowExecutionsDelete,
  },
} as const;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    ns: [
      'appClients',
      'billing',
      'common',
      'datasets',
      'errorCodes',
      'models',
      'models-delete',
      'models-create',
      'models-integrate',
      'models-overview',
      'models-pretrained',
      'navigation',
      'onboarding',
      'organizations',
      'settings-billing',
      'settings-navigation',
      'settings-profile',
      'settings-upgrade',
      'settings-workspace',
      'signin',
      'testModel',
      'wizard',
      'workflowExecutions-delete',
    ],
    interpolation: {
      escapeValue: false, // react takes care of escaping
    },
  });

export default i18n;
