import { useCallback, useEffect, useRef, useState } from 'react';

export function useCopy(text: string) {
  const [copied, setCopied] = useState(false);
  const timeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const onCopy = useCallback(
    (input?: string) => {
      navigator.clipboard.writeText(input || text || '');
      setCopied(true);
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        setCopied(false);
      }, 1200);
    },
    [text]
  );

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return { copied, onCopy };
}
