import { HTMLAttributes } from 'react';

import styles from './Card.module.css';

export type CardFooterProps = HTMLAttributes<HTMLDivElement>;

export function CardFooter({ className, ...rest }: CardFooterProps) {
  const classNames = `${styles.footer}${className ? ` ${className}` : ''}`;
  return <div className={classNames} {...rest} />;
}
