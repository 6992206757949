import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '@hooks';

export function FlowAccessGuard() {
  const { scopes } = useAuthContext();

  // @TODO: Get access information from something other than scopes?
  const doesNotHaveFlowAccess = !scopes.includes('workflows:write');

  if (doesNotHaveFlowAccess) return <Navigate to="/flows/access" replace />;

  return <Outlet />;
}
