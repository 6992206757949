import { useTranslation } from 'react-i18next';
import { ToastContentProps } from 'react-toastify';

import { Progress, Button, Spinner } from '@components';
import 'react-toastify/dist/ReactToastify.min.css';
import styles from './Toast.module.css';

type ErrorProps = {
  error?: string;
  onRetry?: () => void;
} & Pick<ToastContentProps, 'closeToast'>;

export type ToastProps = {
  documentsTotal?: number;
  documentsLeft?: number;
} & Partial<ToastContentProps> &
  ErrorProps;

function ErrorDisplay({ error, onRetry, closeToast }: ErrorProps) {
  const { t } = useTranslation('datasets');
  return (
    <div className={styles.container}>
      <h2 className={styles.header}>{t('listDatasets.errorDuringDeletion')}</h2>
      <div>
        <Button onClick={onRetry} className="mr-3">
          {t('listDatasets.errorRetryBtn')}
        </Button>
        <Button variant="secondary-gray" onClick={closeToast}>
          {t('listDatasets.errorCloseBtn')}
        </Button>
      </div>
    </div>
  );
}

export function Toast({ closeToast, toastProps, onRetry, error, documentsLeft = 0, documentsTotal = 1 }: ToastProps) {
  if (error) {
    return <ErrorDisplay error={error} onRetry={onRetry} closeToast={closeToast} />;
  }
  let percent = 0;
  if (documentsTotal === 0) {
    percent = 100;
  } else {
    percent = (100 / documentsTotal) * (documentsTotal - documentsLeft);
  }
  return (
    <div className={styles.container}>
      <h2 className={styles.header}>Deleting dataset</h2>
      <div className="flex align-center">
        <span className={styles.percent}>{Math.round(percent)}%</span>
        <Progress percent={percent} color="error" />
        <span className={styles.icon}>
          <Spinner />
        </span>
      </div>
      <div className={styles.count}>
        {documentsLeft > 0
          ? `${documentsTotal - documentsLeft} of ${documentsTotal} documents deleted`
          : 'Finishing up...'}
      </div>
    </div>
  );
}
