/**
 * Returns sorted copy of array, sorting organizations based on name > id
 * @param organizations
 * @returns
 */
export function sortOrganizations(organizations: Array<any>) {
  return [...organizations].sort((a, b) =>
    ((a?.name || a?.organizationId || '') as string).localeCompare((b?.name || b?.organizationId || '') as string)
  );
}
