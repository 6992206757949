import { ComponentProps, ReactNode } from 'react';
import { styled } from '@config/stitches';

const FeaturedIconContainer = styled('span', {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '10px',
  flexGrow: 0,
  flexShrink: 0,
  defaultVariants: {
    variant: 'default',
    size: 'lg',
  },
  compoundVariants: [
    {
      rounded: true,
      variant: 'light-primary',
      css: {
        backgroundColor: '$primary100',
        color: '$primary600',
      },
    },
  ],
  variants: {
    border: {
      true: {
        border: '1px solid $gray200',
      },
    },
    rounded: {
      true: {
        borderRadius: '200px',
      },
    },
    size: {
      sm: {
        width: '32px',
        height: '32px',
        borderRadius: '6px',
        '& svg': {
          width: '16px',
          height: '16px',
        },
      },
      md: {
        width: '40px',
        height: '40px',
        '& svg': {
          width: '20px',
          height: '20px',
        },
      },
      lg: {
        width: '48px',
        height: '48px',
        '& svg': {
          width: '24px',
          height: '24px',
        },
      },
    },
    variant: {
      default: {
        backgroundColor: '$gray100',
        color: '$gray600',
      },
      'dark-primary': {
        backgroundColor: '$gray800',
        color: '$gray25',
      },
      'light-error': {
        backgroundColor: '$error50',
        color: '$error600',
      },
      'light-primary': {
        backgroundColor: '$primary100',
        color: '$primary600',
      },
      'light-warning': {
        backgroundColor: '$warning100',
        color: '$warning600',
      },
      'light-success': {
        backgroundColor: '$success50',
        color: '$success600',
      },
      'light-white': {
        backgroundColor: 'white',
        color: '$gray400',
      },
      'light-dark': {
        backgroundColor: 'white',
        color: '$gray700',
      },
      info: {
        backgroundColor: '$primary50',
        color: '$primary600',
      },
    },
  },
});

export type FeaturedIconProps = {
  icon: ReactNode;
} & ComponentProps<typeof FeaturedIconContainer>;
export function FeaturedIcon({ icon, ...containerProps }: FeaturedIconProps) {
  return <FeaturedIconContainer {...containerProps}>{icon}</FeaturedIconContainer>;
}
