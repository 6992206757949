import { ReactNode, useCallback, useMemo } from 'react';

import { TrackerContext, TrackerContextValues } from './TrackerContext';
import { BaseTracker, RudderStackTracker } from '../../tracking';
import { useIdentify, UseIdentifyFn } from '@hooks';

export interface TrackerProviderProps {
  children?: ReactNode | null;
}

const RUDDERSTACK_WRITE_KEY = import.meta.env.VITE_FEATURE_RUDDERSTACK_WRITE_KEY;
const RUDDERSTACK_DATA_PLANE_URL = import.meta.env.VITE_FEATURE_RUDDERSTACK_DATA_PLANE_URL;
const RUDDERSTACK_CONTROL_PLANE_URL = import.meta.env.VITE_FEATURE_RUDDERSTACK_CONTROL_PLANE_URL;
const IS_DEVELOPMENT = import.meta.env.MODE === 'development';
const ENABLE_DEBUG = import.meta.env.MODE === 'development';
const ENABLE_RUDDERSTACK_IN_DEV = import.meta.env.VITE_ENABLE_RUDDERSTACK_IN_DEV === 'true';
const ENABLE_RUDDERSTACK =
  ((IS_DEVELOPMENT && ENABLE_RUDDERSTACK_IN_DEV) || !IS_DEVELOPMENT) &&
  RUDDERSTACK_WRITE_KEY &&
  RUDDERSTACK_DATA_PLANE_URL;

export function TrackerProvider({ children }: TrackerProviderProps) {
  const tracker = useMemo(() => {
    if (ENABLE_RUDDERSTACK) {
      return new RudderStackTracker(RUDDERSTACK_WRITE_KEY, RUDDERSTACK_DATA_PLANE_URL, {
        debug: ENABLE_DEBUG,
        configUrl: RUDDERSTACK_CONTROL_PLANE_URL,
      });
    } else {
      return new BaseTracker({ debug: ENABLE_DEBUG });
    }
  }, []);

  const identify: UseIdentifyFn = useCallback(
    (data) => {
      const traits = {
        email: data.email,
        firstName: data.givenName,
        lastName: data.familyName,
        onboarding: data.metadata?.onboarding,
      };
      tracker.identify(data.profileId, traits);
    },
    [tracker]
  );

  useIdentify(identify);

  const value: TrackerContextValues = { tracker };

  return <TrackerContext.Provider value={value}>{children}</TrackerContext.Provider>;
}
