import type { ComponentProps } from 'react';

import { StitchesBadge } from '@components/Badge';
import { styled } from '@config/stitches';
import AlertCircleIcon from '@assets/icon-alert-circle.svg?react';
import ArrowRightIcon from '@assets/icon-arrow-right.svg?react';
import { Link } from 'react-router-dom';

const BadgeContainer = styled(StitchesBadge, {
  backgroundColor: '$error50 !important',
  color: '$rose700 !important',
  alignSelf: 'center',
  fontSize: '0.85rem',
  gap: '0.4em',
  padding: '5px 5px 5px 8px',
});

const StyledAlertIcon = styled(AlertCircleIcon, {
  width: '1.4em',
  height: '1.4em',
});

const UpgradeBadge = styled(StitchesBadge, {
  backgroundColor: 'white !important',
  color: '$gray800 !important',
  ml: '0.3em',
  fontSize: '0.85rem',
  gap: '0.3em',
  '&:hover': {
    backgroundColor: '$gray50 !important',
  },
  '&:hover svg': {
    transform: 'translateX(1px)',
  },
  '& svg': {
    transition: 'transform 100ms ease',
    width: '1em',
    height: '1em',
    color: '$gray400',
  },
});

export type ErrorUpgradeBadgeProps = ComponentProps<typeof BadgeContainer>;

export function ErrorUpgradeBadge({ children, ...props }: ErrorUpgradeBadgeProps) {
  return (
    <BadgeContainer {...props}>
      <StyledAlertIcon />
      {children}
      <Link to="/settings/plans">
        <UpgradeBadge>
          Upgrade <ArrowRightIcon />
        </UpgradeBadge>
      </Link>
    </BadgeContainer>
  );
}
