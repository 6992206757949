import { HTMLAttributes, ReactNode } from 'react';
import styles from './NewBadge.module.scss';

export type NewBadgeProps = {
  variant?: 'warning' | 'error' | 'success' | 'info' | 'success-soft';
  icon?: ReactNode;
  simple?: boolean;
  iconPlacement?: 'left' | 'right';
} & HTMLAttributes<HTMLSpanElement>;

const variantToText = {
  warning: 'Warning',
  error: 'Error',
  success: 'Success',
  'success-soft': 'Success',
  info: 'Info',
};

export function NewBadge({
  className,
  variant = 'warning',
  simple,
  icon,
  iconPlacement = 'left',
  children,
  ...rest
}: NewBadgeProps) {
  const variantClass = variant ? ` ${styles[variant]}` : '';
  const classNames = `${styles.badge} ${simple ? styles.simple : ''} ${
    iconPlacement === 'left' ? styles.left : styles.right
  } ${className ? ` ${className}` : ''}${variantClass}`;
  const innerClassNames = `${styles.inner} ${icon ? styles.icon : ''}`;
  return (
    <span {...rest} className={classNames}>
      {!simple && iconPlacement === 'left' && (
        <span className={innerClassNames}>{icon ? icon : variantToText[variant]}</span>
      )}
      {children}
      {!simple && iconPlacement === 'right' && (
        <span className={innerClassNames}>{icon ? icon : variantToText[variant]}</span>
      )}
    </span>
  );
}
