import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export function dateToISO(date?: string | Date | null): string | undefined {
  if (date) {
    return dayjs(date).toISOString();
  }
}

export function dateToString(date?: string | Date | null): string | undefined {
  if (date) {
    return dayjs(date).toString();
  }
}

export function dateToRelative(date?: string | Date | null): string | undefined {
  if (date) {
    return dayjs(date).fromNow();
  }
}

type DateFormats = {
  iso: string | undefined;
  string: string | undefined;
  relative: string | undefined;
};
export function getAllDateFormats(date?: string | Date | null): DateFormats {
  if (date) {
    return { iso: dateToISO(date)!, string: dateToString(date)!, relative: dateToRelative(date)! };
  } else {
    return { iso: undefined, string: undefined, relative: undefined };
  }
}
