export const MINIMUM_REQUIRED_DOCUMENTS_TO_TRAIN = 5;
export const MINIMUM_DATESET_PERCENT_ANNOTATED = 10;

export const ORGANIZATION_QUERY_DELAY_MS = 1000;
// default stale time for react-query calls
export const DEFAULT_STALE_TIME = 1000 * 60 * 5;

export const LOCAL_STORAGE_AUTH_TOKEN_PREFIX = 'cradl-AuthToken';
export const LOCAL_STORAGE_LAST_USED_CLIENT_ID_KEY = 'cradl-lastActiveClientId';
export const LOCAL_STORAGE_REDIRECT_URL_KEY = 'cradl-loginRedirectUrl';
export const LOCAL_STORAGE_LOGIN_ORGANIZATION_ID = 'cradl-loginOrganizationId';

// Used to track a newly created organization, in order to wait when switching to new organization
export const SESSION_STORAGE_CREATED_ORGANIZATION_CLIENT_ID_KEY = 'cradl-createdOrganizationClientId';

// Where to redirect if going to "/". The default is to redirect you to "/models" if nothing is found.
export const DEFAULT_REDIRECT_ROUTE = '/flows';
export const IGNORED_REDIRECT_ROUTES = ['/globalAuthCallback', '/authCallback', '/login', '/logout', '/organizations'];

// Test model configuration
export const LOCAL_STORAGE_VIEW_AS_JSON_KEY = 'cradl-model_test-view_as_json';
export const TEST_MODEL_DOCUMENT_UPLOAD_RETENTION = 90;
export const DEFAULT_CONFIDENCE_GOOD_THRESHOLD = 50;

// Model metadata keys
export const METADATA_KEY_DEFAULT_DATASET = 'cradl-app:default-dataset';
export const METADATA_KEY_ATTACHED_DATASETS = 'cradl-app:attached-datasets';
export const METADATA_KEY_TRAINING_INFO = 'cradl:trainingInfo';

// Model graph view
export const LOCAL_STORAGE_MODEL_PREDICTION_RANGE = 'cradl-model_prediction_range';

// Annotate view
// Decides whether we should skip tabbing through button controls in the form,
// and only tab through input fields
export const ANNOTATE_SKIP_TAB_ON_BUTTON_CONTROL = true;
// Discard predictions for fields where confidence is below this value when using model help
export const ANNOTATE_MODEL_HELP_PREDICTION_THRESHOLD = 0.2;
export const ANNOTATE_DATE_GT_FORMAT = 'YYYY-MM-DD';
export const ANNOTATE_DATE_INPUT_FORMAT = 'DD.MM.YYYY';

// common urls
export const URL_DOCUMENTATION = 'https://docs.cradl.ai';
export const URL_TOS = 'https://docs.cradl.ai/legal/tos';
export const BOOK_DEMO_URL = 'https://www.cradl.ai/contact';
export const URL_EULA = 'https://docs.cradl.ai/legal/eula';

/** Validator */
// Default approve form default confidence values
export const DEFAULT_CONFIDENCE_AUTOMATED = 0.95;
export const DEFAULT_CONFIDENCE_HIGH = 0.85;
export const DEFAULT_CONFIDENCE_MEDIUM = 0.5;

/** Flows */
// Description for app-client, which will be used as the default for flows
export const FLOWS_DEFAULT_APP_CLIENT_DESCRIPTION = 'Default app client for flows';

export const LATEST_MODEL_VERSION = 4;
