import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Layout, Loading, ModelGuard, RequireAuth, CradlGuard } from '@components';
import { DEFAULT_REDIRECT_ROUTE } from '@config/app';
import { usePageTracker, useScrollToTop } from '@hooks';
import { BackgroundJobProvider, LoggerProvider, TrackerProvider } from '@contexts';

import { OnboardingGuard } from './features/onboarding/components';
import { FlowRoutes } from './features/flows/routes';
import { FlowTrainingProvider } from './features/flows/contexts';
import * as Sentry from '@sentry/react';

const Datasets = lazy(() => import('@views/Datasets'));
const LegacySuperviseRedirect = lazy(() => import('@views/legacy-supervise-redirect/legacy-supervise-redirect'));
const AppClientsList = lazy(() => import('@views/app-client-list'));
const Settings = lazy(() => import('@views/Settings'));
const Validator = lazy(() => import('./features/validator'));
const ListModels = lazy(() => import('@views/Models/ListModels/ListModels'));
const ModelDetails = lazy(() => import('@views/ModelDetails'));
const CreateModel = lazy(() => import('@views/Models/CreateModel'));
const CreatedOrganizationGuard = lazy(() => import('@views/OrganizationSelection/CreatedOrganizationGuard'));
const OrganizationGuard = lazy(() => import('@views/OrganizationSelection/OrganizationGuard'));
const OrganizationSelection = lazy(() => import('@views/OrganizationSelection/OrganizationSelection'));
const GlobalAuthSuccess = lazy(() => import('@views/AuthSuccess/GlobalAuthSuccess'));
const AuthSuccess = lazy(() => import('@views/AuthSuccess/AuthSuccess'));
const Login = lazy(() => import('@views/Login'));
const Logout = lazy(() => import('@views/Logout'));
const TrainingDetails = lazy(() => import('@views/TrainingDetails'));
const TrainingWizard = lazy(() => import('@views/TrainingWizard'));
const TrainingSummary = lazy(() => import('./features/training/views/summary'));
const Dataset = lazy(() => import('./features/training/views/dataset'));
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const PageTracker = () => {
  usePageTracker();
  return null;
};

export function App() {
  useScrollToTop();

  return (
    <LoggerProvider identifier="Cradl">
      <TrackerProvider>
        <BackgroundJobProvider>
          <ReactQueryDevtools initialIsOpen={false} position="bottom" />
          <PageTracker />
          <ToastContainer />
          <Suspense fallback={<Loading />}>
            <SentryRoutes>
              <Route path="/logout" element={<Logout />} />
              <Route path="/login" element={<Login />} />
              <Route path="/authCallback" element={<AuthSuccess />} />
              <Route path="/globalAuthCallback" element={<GlobalAuthSuccess />} />

              <Route
                element={
                  <RequireAuth>
                    <FlowTrainingProvider>
                      <CreatedOrganizationGuard />
                    </FlowTrainingProvider>
                  </RequireAuth>
                }
              >
                <Route element={<OnboardingGuard />}>
                  <Route element={<OrganizationGuard />}>
                    <Route element={<CradlGuard />}>
                      <Route element={<Layout />}>
                        <Route path="/appclients/*" element={<AppClientsList />} />
                        <Route path="/datasets/*" element={<Datasets />} />
                        <Route path="/settings/*" element={<Settings />} />
                      </Route>

                      <Route path="/flows/*" element={<FlowRoutes />} />

                      <Route path="/models">
                        <Route element={<Layout />}>
                          <Route index element={<ListModels />} />
                          <Route path="new" element={<CreateModel />} />
                        </Route>
                        <Route path=":modelId/*" element={<ModelDetails />} />
                        {/* @TODO: Refactor all model routes, use ModelGuard for :modelId paths */}
                        <Route element={<ModelGuard />}>
                          <Route path=":modelId/trainings/new" element={<TrainingSummary />} />
                          <Route path=":modelId/dataset" element={<Dataset />} />
                          <Route path=":modelId/training/new" element={<TrainingWizard />} />
                          <Route element={<Layout />}>
                            <Route path=":modelId/training/:trainingId" element={<TrainingDetails />} />
                          </Route>
                        </Route>
                      </Route>
                    </Route>

                    {/** @TODO: Remove this legacy redirect route and component when appropriate */}
                    <Route path="/supervise/*" element={<LegacySuperviseRedirect />} />
                    <Route
                      path="/validator/*"
                      element={
                        <LoggerProvider identifier="Validator">
                          <Validator />
                        </LoggerProvider>
                      }
                    />
                  </Route>
                </Route>
                <Route element={<OnboardingGuard />}>
                  <Route path="/organizations" element={<OrganizationSelection />} />
                </Route>
              </Route>

              <Route path="*" element={<Navigate to={DEFAULT_REDIRECT_ROUTE} replace />} />
            </SentryRoutes>
          </Suspense>
        </BackgroundJobProvider>
      </TrackerProvider>
    </LoggerProvider>
  );
}
