import { Fragment } from 'react';
import { Menu } from '@mantine/core';

import { StitchesBadge } from '@components';
import ArrowLeftIcon from '@assets/icon-arrow-narrow-left.svg?react';
import PlusIcon from '@assets/icon-plus.svg?react';
import { sortOrganizations } from '@utils/sortOrganizations';

export type OrganizationMenuProps = {
  goBack: () => void;
  onSelectOrganization: (clientId: string) => void;
  currentOrganizationClientId: string;
  openModal: () => void;
  organizations: any;
};
export function OrganizationMenu({
  goBack,
  organizations,
  currentOrganizationClientId,
  onSelectOrganization,
  openModal,
}: OrganizationMenuProps) {
  return (
    <>
      <Menu.Item className="header" leftSection={<ArrowLeftIcon />} onClick={goBack} closeMenuOnClick={false}>
        Workspaces
      </Menu.Item>
      <Menu.Divider />

      {sortOrganizations(organizations)
        .filter((org: any) => org.clientId)
        .map((org: any) => (
          <Fragment key={org.clientId}>
            <Menu.Item
              onClick={() => onSelectOrganization(org.clientId)}
              rightSection={
                org.clientId === currentOrganizationClientId ? (
                  <StitchesBadge variant="success" css={{ fontSize: '0.8em' }}>
                    Current
                  </StitchesBadge>
                ) : undefined
              }
            >
              {org.name || `Untitled workspace (${org.clientId})`}
            </Menu.Item>
            <Menu.Divider />
          </Fragment>
        ))}
      <Menu.Item rightSection={<PlusIcon />} onClick={openModal}>
        New workspace
      </Menu.Item>
    </>
  );
}
