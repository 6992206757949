import { DataBundle } from '@lucidtech/las-sdk-browser';
import { Accordion, Tooltip } from '@mantine/core';
import { ComponentProps, useMemo } from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import { clamp } from '@utils';
import { NewCard, StitchesBadge, StitchesProgress } from '@components';
import { AccordionControl, AccordionItem } from './Accordion';

import { DataQualityCard } from './DataQualityCard';
import { DataVolumeCard } from './DataVolumeCard';
import { LoadingVariationCell } from './LoadingCell';
import { styled } from '@config/stitches';
import IconError from './icon-error.svg?react';
import IconWarning from './icon-warning.svg?react';
import IconOk from './icon-ok.svg?react';
import { LoadingEstimation } from './LoadingEstimation';
import { ErrorMessage } from './ErrorMessage';
import { TrainableWarningCode } from '../../types';

function percentToBadgeVariant(percent: number): ComponentProps<typeof StitchesBadge>['variant'] {
  if (percent >= 80) {
    return 'success';
  } else if (percent >= 50) {
    return 'warning';
  } else {
    return 'error';
  }
}

const IssueCard = styled('section', {
  '& header': {
    display: 'flex',
    alignItems: 'center',
    gap: '$2',
    padding: '$4',
    borderBottom: '1px solid $gray200',
    fontWeight: 500,
    color: '$gray700',
  },
  '& header span': {
    display: 'inline-block',
    width: '0.5em',
    height: '0.5em',
    backgroundColor: '$error500',
    borderRadius: '50%',
  },
  '& ul': {
    marginTop: '$3',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '$2',
  },
  '& ul.warnings li': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
  },
});

const TableCard = styled(NewCard, {
  '& header': {
    padding: '24px',
    fontSize: '0.9rem',
  },
  '& table': {
    borderCollapse: 'collapse',
  },
  '& tr[data-discarded="true"]': {
    backgroundColor: '$gray50',
  },
  '& th': {
    color: '$gray500',
    fontWeight: 500,
    textAlign: 'start',
    fontSize: '0.8rem',
    borderTop: '1px solid $gray200',
    borderBottom: '1px solid $gray200',
    padding: '$3 $4',
    whiteSpace: 'nowrap',
  },
  '& th, & td': {
    borderTop: '1px solid $gray200',
  },
  '& td': {
    padding: '24px',
    fontSize: '0.8rem',
    fontWeight: 500,
    color: '$gray500',
  },
  '& .nowrap': {
    paddingLeft: 0,
    whiteSpace: 'nowrap',
  },
  '& .status-icon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const MainContainer = styled('div', {
  maxWidth: '850px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: '$3',
  color: '$gray900',
  '& .mantine-Tooltip-tooltip': {
    fontWeight: 400,
    fontSize: '0.8rem',
  },
});

const TopContainer = styled('section', {
  display: 'flex',
  gap: '$3',
  flexWrap: 'wrap',
});

export type DataBundleInfoProps = {
  dataBundle: DataBundle;
  pendingData?: Record<string, number>;
  pendingDocumentCount?: number;
};

export function DataBundleReport({ dataBundle, pendingData = {}, pendingDocumentCount = 0 }: DataBundleInfoProps) {
  const { t } = useTranslation();
  const dataBundleLoading = dataBundle?.status === 'running';
  const failed = dataBundle?.status === 'failed';
  const succeeded = dataBundle?.status === 'succeeded';

  const numberOfDocuments: number =
    dataBundle?.summary?.aggregate?.numberOfDocuments ||
    dataBundle?.datasets.reduce((sum, nextDataset) => sum + nextDataset.numberOfDocuments, 0) ||
    pendingDocumentCount ||
    0;

  const statData = useMemo(() => {
    const statData: Record<
      string,
      { variation: number; coverage: number; valid: number; invalid: number; trainable: boolean }
    > = {};

    if (dataBundle && succeeded) {
      // have to type this as any since we don't really have a set standard for the summary for now
      for (const [label, stats] of Object.entries<any>(dataBundle.summary?.labels || {})) {
        const variation = stats?.variation ? clamp(Math.floor(stats.variation * 100)) : 0;
        const coverage = stats?.coverage ? clamp(Math.floor(stats.coverage * 100), 0, Number.MAX_VALUE) : 0;
        const valid = stats?.numberOfValids || 0;
        const invalid = stats?.numberOfErrors || 0;
        const trainable = stats?.trainable || false;
        statData[label] = { variation, coverage, valid, invalid, trainable };
      }
    } else {
      // use preliminary data to calculate rough coverage numbers
      for (const [label, count] of Object.entries(pendingData).sort()) {
        const coverage = clamp(Math.floor((100 / (numberOfDocuments || 1)) * count), 0, Number.MAX_VALUE);
        statData[label] = { variation: 0, coverage, valid: count, invalid: 0, trainable: true };
      }
    }

    return statData;
  }, [dataBundle, succeeded, pendingData, numberOfDocuments]);

  const overallScore = clamp(Math.floor((dataBundle?.summary?.aggregate?.overallScore || 0) * 100));

  const duplicates = useMemo(() => {
    const duplicates: Record<string, number> = {};
    for (const duplicate of dataBundle?.summary?.aggregate?.duplicates || []) {
      if (duplicate.contentMD5) {
        duplicates[duplicate.contentMD5] = duplicate.documentIds.length;
      }
    }
    return duplicates;
  }, [dataBundle]);

  const fieldWarnings = useMemo(() => {
    const fieldWarnings: Record<string, Array<TrainableWarningCode>> = {};
    for (const [label, stats] of Object.entries(dataBundle?.summary?.labels || {})) {
      const warnings = (stats as any).errors?.filter((error: any) => error?.errorCode?.startsWith('TW')) || [];
      if (warnings.length > 0) {
        fieldWarnings[label] = [];
        for (const warning of warnings) {
          fieldWarnings[label].push(warning.errorCode);
        }
      }
    }
    return fieldWarnings;
  }, [dataBundle]);

  // Go through errors and get all errors
  const fieldErrors = useMemo(() => {
    const fieldErrors: Record<string, Record<string, Array<string | number>>> = {};
    for (const [label, stats] of Object.entries(dataBundle?.summary?.labels || {})) {
      const errors =
        (stats as any).errors?.filter(
          (error: any) => error?.errorCode?.startsWith('TE') || error?.errorCode?.startsWith('LE')
        ) || [];
      if (errors.length > 0) {
        fieldErrors[label] = {};
        for (const error of errors) {
          if (!fieldErrors[label][error.errorCode]) {
            fieldErrors[label][error.errorCode] = [];
          }
          fieldErrors[label][error.errorCode].push(error.value);
        }
      }
    }
    return fieldErrors;
  }, [dataBundle]);

  const hasDuplicateDocuments = Object.keys(duplicates).length > 0;

  const hasFieldErrors = Object.keys(fieldErrors).length > 0;
  const hasFieldWarnings = Object.keys(fieldWarnings).length > 0;

  const shouldShowIssues = hasDuplicateDocuments || hasFieldErrors;

  const totalDuplicateCount = Object.values(duplicates).reduce((sum, next) => sum + next, 0);
  const duplicateSetCount = Object.keys(duplicates).length;
  const issueCount =
    [hasDuplicateDocuments].filter((item) => item).length +
    Object.keys(fieldErrors).length +
    Object.keys(fieldWarnings).length;

  if (failed) {
    return <ErrorMessage />;
  }

  return (
    <MainContainer>
      {dataBundleLoading ? <LoadingEstimation dataBundle={dataBundle} /> : null}
      <TopContainer>
        <DataVolumeCard fileCount={numberOfDocuments} />
        <DataQualityCard loading={dataBundleLoading} score={overallScore} />
      </TopContainer>
      {shouldShowIssues && (
        <IssueCard>
          <NewCard shadow="xs">
            <header>
              <span />
              {`${issueCount} data ${issueCount > 1 ? 'issues' : 'issue'} detected`}
            </header>
            <Accordion multiple unstyled>
              {hasDuplicateDocuments && (
                <AccordionItem value="duplicate-documents">
                  <AccordionControl>
                    <IconWarning />
                    Duplicate documents
                  </AccordionControl>
                  <Accordion.Panel>
                    <div>
                      You have {duplicateSetCount >= 10 ? 'at least ' : ''}
                      {duplicateSetCount} {duplicateSetCount > 1 ? 'sets' : 'set'} of duplicated documents, with a total
                      of {totalDuplicateCount >= 10 ? 'at least ' : ''}
                      {totalDuplicateCount} duplicates. Consider removing any duplicated documents in your datasets, and
                      upload more unique documents for better results.
                    </div>
                  </Accordion.Panel>
                </AccordionItem>
              )}
              {hasFieldErrors &&
                Object.entries(fieldErrors).map(([label, errors]) => {
                  const totalErrors: number = dataBundle?.summary?.labels?.[label]?.numberOfErrors || 0;
                  return (
                    <AccordionItem key={`field-error-${label}`} value={`field-error-${label}`}>
                      <AccordionControl>
                        <IconError />
                        Invalid label values for {label}
                      </AccordionControl>
                      <Accordion.Panel>
                        <div>
                          {totalErrors} invalid {totalErrors > 1 ? 'value' : 'values'} found.
                          <ul>
                            {Object.values(fieldErrors[label] || {}).map((errors) =>
                              errors
                                .filter((error) => error)
                                .map((invalidValue) => (
                                  <li key={invalidValue.toString()}>
                                    <StitchesBadge>{invalidValue.toString()}</StitchesBadge>
                                  </li>
                                ))
                            )}
                          </ul>
                        </div>
                      </Accordion.Panel>
                    </AccordionItem>
                  );
                })}
              {hasFieldWarnings &&
                Object.entries(fieldWarnings).map(([label, warningCodes]) => {
                  return (
                    <AccordionItem key={`field-warning-${label}`} value={`field-warning-${label}`}>
                      <AccordionControl>
                        <IconWarning />
                        Training quality issues for {label}
                      </AccordionControl>
                      <Accordion.Panel>
                        <div>
                          {warningCodes.length} issues found.
                          <ul className="warnings">
                            {warningCodes.map((warningCode) => (
                              <li key={warningCode}>
                                <strong>{t(`errorCodes:${warningCode}.title`)}</strong>
                                {t(`errorCodes:${warningCode}.description`)}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Accordion.Panel>
                    </AccordionItem>
                  );
                })}
            </Accordion>
          </NewCard>
        </IssueCard>
      )}
      <section>
        <TableCard shadow="xs">
          <header>
            <h3>{t('wizard:inspectData.summary.headerText')}</h3>
          </header>
          <table>
            <thead>
              <tr>
                <th>Field</th>
                <th colSpan={2}>Valid data</th>
                <th className="cell-shrink">
                  <div>
                    <Tooltip label="How different and unique are the values for this field">
                      <span>
                        Variation <FiHelpCircle />
                      </span>
                    </Tooltip>
                  </div>
                </th>
                <th className="cell-shrink">Status</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(statData)
                .sort()
                .map(([field, data]) => {
                  const validPercent = Math.round((100 / (data.valid + data.invalid || 1)) * data.valid);
                  const fieldHasWarnings = Boolean(fieldWarnings[field]);
                  const fieldHasErrors = Boolean(fieldErrors[field]);
                  return (
                    <tr key={field}>
                      <td className="cell-shrink">
                        <StitchesBadge>{field}</StitchesBadge>
                      </td>
                      <td>
                        <StitchesProgress percent={validPercent} />
                      </td>
                      <td className="cell-shrink nowrap">
                        {data.valid}/{data.valid + data.invalid}
                      </td>
                      <td className="cell-shrink">
                        {dataBundleLoading ? (
                          <LoadingVariationCell />
                        ) : (
                          <StitchesBadge variant={percentToBadgeVariant(data.variation)}>
                            {data.variation}%
                          </StitchesBadge>
                        )}
                      </td>
                      <td>
                        <Tooltip
                          disabled={!fieldHasWarnings}
                          label={
                            fieldHasErrors || fieldHasWarnings
                              ? 'This label might have some issues, see above for details'
                              : undefined
                          }
                        >
                          <span className="status-icon">
                            {fieldHasErrors ? <IconError /> : fieldHasWarnings ? <IconWarning /> : <IconOk />}
                          </span>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </TableCard>
      </section>
    </MainContainer>
  );
}
