import ContentLoader from 'react-content-loader';

import { Avatar } from '../Avatar/Avatar';

export type AvatarProfileProps = {
  isLoading?: boolean;
  userData?: any;
};
export function AvatarProfile({ isLoading, userData }: AvatarProfileProps) {
  const fullName = `${userData?.givenName || ''}${userData?.familyName ? ` ${userData?.familyName}` : ''}`;
  return (
    <div>
      {isLoading ? (
        <ContentLoader
          speed={2}
          width={40}
          height={40}
          viewBox="0 0 40 40"
          backgroundColor="rgba(227, 235, 246, 0.396)"
          foregroundColor="rgba(191, 213, 243, 0.508)"
        >
          <circle cx="20" cy="20" r="20" />
        </ContentLoader>
      ) : (
        <Avatar name={fullName} avatar={userData?.picture} size="md" />
      )}
    </div>
  );
}
