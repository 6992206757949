import { styled } from '@config/stitches';

export interface ContentProps {
  children?: React.ReactNode;
}

export const Content = styled('main', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  // '@bp1': {
  //   padding: '0px $4 $8',
  // },
  // '@bp2': {
  //   padding: '0px $7 $8',
  // },
  // '@bp3': {
  //   padding: '0px $9 $8',
  // },
});
