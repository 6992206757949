/**
 * Replace characters in strings sent as JSON
 * @param str
 * @returns
 */
export function escapeJSONString(str: string): string {
  return str
    .replace(/\\/g, '\\\\')
    .replace(/\"/g, '\\"')
    .replace(/\n/g, '\\n')
    .replace(/\r/g, '\\r')
    .replace(/\t/g, '\\t');
}
