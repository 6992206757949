import { NewCard } from '@components';
import { useTranslation } from 'react-i18next';
import { styled } from '@config/stitches';

const StyledCard = styled(NewCard, {
  flex: '1 1 280px',
  minWidth: '280px',
  color: '$gray500',
  '& h3': {
    color: '$gray900',
    fontSize: '1.2rem',
    fontWeight: 500,
  },
  '& .count': {
    my: 'auto',
    fontSize: '2.2rem',
    fontWeight: 500,
    color: '$gray900',
  },
  '& .label': {
    color: '$gray500',
    fontSize: '0.8rem',
    fontWeight: 500,
  },
});

export type DataVolumeCardProps = {
  fileCount: number;
};

export function DataVolumeCard({ fileCount }: DataVolumeCardProps) {
  const { t } = useTranslation('wizard');
  return (
    <StyledCard padding="lg" shadow="xs">
      <h3>{t('inspectData.volumeHeader')}</h3>
      <span className="count">{fileCount}</span>
      <span className="label">{t('inspectData.volumeLabel')}</span>
    </StyledCard>
  );
}
