import { Button, Checkbox } from '@components';
import { URL_EULA, URL_TOS } from '@config/app';
import { HTMLAttributes } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

export type OnboardingFormStep1Values = {
  givenName: string;
  familyName: string;
  companyName: string;
  companyWebsite: string;
  acceptedTerms: boolean;
};

export type OnboardingFormStep1Props = {
  goToNextStep: (data: any) => void;
  initialValues: OnboardingFormStep1Values;
} & HTMLAttributes<HTMLDivElement>;

export function OnboardingFormStep1({ goToNextStep, initialValues, ...rest }: OnboardingFormStep1Props) {
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<OnboardingFormStep1Values>({
    mode: 'all',
    defaultValues: initialValues,
  });

  const onSubmit: SubmitHandler<any> = (data) => {
    goToNextStep(data);
  };

  return (
    <div {...rest}>
      <h2>Let's get to know you</h2>
      <p className="tw-text-slate-500">A little about you so we can configure it as per your needs.</p>

      <form onSubmit={handleSubmit(onSubmit)} className="tw-mt-5">
        <fieldset className="tw-mb-2 tw-flex tw-gap-4">
          <label htmlFor="givenName" className="tw-w-1/2 tw-font-medium">
            First name *
            <input
              type="text"
              autoComplete="off"
              placeholder="First name"
              className="aria-[invalid=true]:tw-border-error-300"
              aria-invalid={errors.givenName ? 'true' : 'false'}
              {...register('givenName', { required: true })}
            />
          </label>
          <label htmlFor="familyName" className="tw-w-1/2 tw-font-medium">
            Last name *
            <input
              type="text"
              autoComplete="off"
              placeholder="Last name"
              className="aria-[invalid=true]:tw-border-error-300"
              aria-invalid={errors.familyName ? 'true' : 'false'}
              {...register('familyName', { required: true })}
            />
          </label>
        </fieldset>
        <fieldset className="tw-flex tw-flex-col tw-gap-2">
          <label htmlFor="companyName" className="tw-w-full tw-font-medium">
            Company name *
            <input
              type="text"
              autoComplete="off"
              placeholder="Acme Inc."
              className="aria-[invalid=true]:tw-border-error-300"
              aria-invalid={errors.companyName ? 'true' : 'false'}
              {...register('companyName', { required: true })}
            />
          </label>
          <label htmlFor="companyWebsite" className="tw-w-full tw-font-medium">
            Company website
            <div className="tw-flex tw-rounded-md tw-border tw-border-slate-200 focus-within:tw-border-[#5A74EA]">
              <div className="tw-flex tw-items-center tw-rounded-l-md tw-border-r tw-border-slate-200 tw-bg-slate-50 tw-px-3 tw-text-slate-500">
                https://
              </div>
              <input
                className="!tw-border-0"
                type="text"
                autoComplete="off"
                placeholder="acme.com"
                {...register('companyWebsite')}
              />
            </div>
          </label>
        </fieldset>
        <div className="tw-my-5">
          <label htmlFor="acceptedTerms" aria-required className="tw-flex tw-gap-2 tw-font-medium">
            <Controller
              name="acceptedTerms"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <Checkbox
                  isRequired
                  onChange={(isSelected) => field.onChange(isSelected)}
                  isSelected={Boolean(field.value)}
                  validationState={fieldState.error ? 'invalid' : undefined}
                  aria-label="Accepted TOS and EULA"
                  aria-invalid={Boolean(fieldState.error)}
                  invalid={Boolean(fieldState.error)}
                />
              )}
            />
            <span>
              I accept Cradl AI's{' '}
              <a
                href={URL_TOS}
                className="!tw-text-slate-900 !tw-underline !tw-underline-offset-4 hover:!tw-text-primary-600"
                target="cradl-tos"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href={URL_EULA}
                className="!tw-text-slate-900 !tw-underline !tw-underline-offset-4 hover:!tw-text-primary-600"
                target="cradl-eula"
              >
                End User License Agreement
              </a>
            </span>
          </label>
        </div>
        <Button className="tw-w-full" type="submit">
          Save and continue -&gt;
        </Button>
      </form>
    </div>
  );
}
