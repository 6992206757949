import { HTMLProps } from 'react';
import { Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import Plus from '@assets/icon-plus-circle.svg?react';
import { ButtonLink, SectionHeader } from '@components';
import { useCanCreateWorkflow } from '@features/flows/hooks/useCanCreateWorkflow';
import { useOrganizationQuery } from '@hooks';

export type FlowListHeaderProps = HTMLProps<HTMLHeadElement> & { showButton: boolean };

/**
 * Flow list header.
 */
export function FlowListHeader({ className, showButton, ...rest }: FlowListHeaderProps) {
  const { t } = useTranslation('flows-list');
  const { canCreateWorkflow } = useCanCreateWorkflow();
  const { isNewOrganization } = useOrganizationQuery();

  return (
    <SectionHeader
      className="tw-mb-5"
      title={isNewOrganization ? 'Models' : 'Flows'}
      subTitle={t('headerSubtitle')}
      button={
        showButton ? (
          <Tooltip disabled={canCreateWorkflow} label="You have too many models. Upgrade plan to create another model.">
            <div>
              <ButtonLink disabled={!canCreateWorkflow} size="sm" variant="secondary-gray" to="new">
                <Plus className="tw-h-4 tw-w-4" />{' '}
                <span className="tw-text-sm">{isNewOrganization ? 'New Model' : 'New Flow'}</span>
              </ButtonLink>
            </div>
          </Tooltip>
        ) : null
      }
    />
  );
}
