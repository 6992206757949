import { forwardRef, ReactNode, useEffect, useImperativeHandle, useState, useRef } from 'react';
import { FiDownload, FiRotateCcw, FiRotateCw, FiZoomIn, FiZoomOut } from 'react-icons/fi';
import { Text } from '@mantine/core';

import PDFViewer from './PDFViewer';
import SimpleImageViewer from './SimpleImageViewer';
import styles from './DocumentViewer.module.css';
import TIFFViewer from './TIFFViewer';
import { useDownload } from '@hooks';
import { PredictionLocation } from './Location';

export type DocumentType = 'image/jpeg' | 'application/pdf' | 'image/png' | 'image/tiff' | 'image/webp';

export function getZoomStyle(zoom: ZoomState): string {
  let zoomStyle = '';

  switch (zoom) {
    case 2:
      zoomStyle = styles['zoom-200'];
      break;
    case 1.5:
      zoomStyle = styles['zoom-150'];
      break;
    case 0.75:
      zoomStyle = styles['zoom-75'];
      break;
    case 0.5:
      zoomStyle = styles['zoom-50'];
      break;
    case 1:
    default:
      break;
  }

  return zoomStyle;
}

export function getRotationStyle(rotation: Rotation): string {
  let rotationStyle = '';

  switch (rotation) {
    case 90:
      rotationStyle = styles['rotate-90'];
      break;
    case 180:
      rotationStyle = styles['rotate-180'];
      break;
    case 270:
      rotationStyle = styles['rotate-270'];
      break;
    default:
      break;
  }

  return rotationStyle;
}

export type ImperativeRef = {
  onZoomIn: () => void;
  onZoomOut: () => void;
  moveUp: () => void;
  moveDown: () => void;
  moveLeft: () => void;
  moveRight: () => void;
};

export type DocumentViewerProps = {
  fileName?: string;
  doc?: string | null;
  documentType?: DocumentType | null;
  loading?: boolean;
  className?: string;
  loadingComponent?: ReactNode;
  onRotate?: (rotation: Rotation) => void;
  showPageCount?: boolean;
  initialRotation?: Rotation;
  keepZoom?: boolean;
  customButtonClassname?: string;
  customZoomInfoClassname?: string;
  customToolbarClassname?: string;
  customToolbarContent?: ReactNode;
  onTextSelect?: (data: { text?: string; position?: { left: number; top: number } }) => void;
  children?: ReactNode;
  /** This should be a stable reference to the function, or the page count may be out of sync */
  onPageChange?: (pages: number[]) => void;
  locations?: PredictionLocation[] | undefined;
};

export type ZoomState = 0.5 | 0.75 | 1 | 1.5 | 2;
export type Rotation = 0 | 90 | 180 | 270;

/**
 * A viewer for PDF, TIFF and simple JPG/PNG image files.
 * The `ref` prop gives you access to an imperative handle, with some functions you can call
 * to interact with the viewer directly:
 * ref.current.onZoomIn, ...onZoomOut, ...moveUp, ...moveDown, ...moveLeft, ...moveRight
 */
export const DocumentViewer = forwardRef<ImperativeRef, DocumentViewerProps>(
  (
    {
      doc,
      documentType,
      className = '',
      loading = false,
      fileName = 'cradl-download',
      loadingComponent = <Text className="!tw-text-gray-900">Loading...</Text>,
      onRotate: onRotateCallback,
      showPageCount,
      initialRotation = 0,
      keepZoom,
      customButtonClassname,
      customToolbarContent,
      customZoomInfoClassname,
      customToolbarClassname,
      onTextSelect,
      children,
      onPageChange,
      locations,
    }: DocumentViewerProps,
    ref
  ): JSX.Element => {
    const isSimpleImage =
      documentType === 'image/jpeg' || documentType === 'image/png' || documentType === 'image/webp';
    const isTIFF = documentType === 'image/tiff';
    const isPDF = documentType === 'application/pdf';
    // Naive simple check for now
    const docIsUrl = doc?.startsWith('http');
    const dataUrl = docIsUrl ? (doc as string) : `data:${documentType};base64,${doc}`;
    const containerClasses = `${styles.container} ${className}`;
    const downloadDocument = useDownload(fileName, dataUrl);
    const contentContainerRef = useRef<HTMLDivElement>(null);
    const [zoom, setZoom] = useState<ZoomState>(1);
    const [rotation, setRotation] = useState<Rotation>(initialRotation);
    const [pageCount, setPageCount] = useState<number | undefined>(undefined);

    // call onRotate callback when rotation changes
    useEffect(() => {
      onRotateCallback?.(rotation);
    }, [rotation]);

    useEffect(() => {
      if (!keepZoom) {
        setZoom(1);
      }
      setRotation(initialRotation);
    }, [doc, keepZoom, initialRotation]);

    useEffect(() => {
      if (isSimpleImage) {
        onPageChange?.([0]);
      }
    }, [isSimpleImage, onPageChange]);

    const onZoomIn = (): void => {
      switch (zoom) {
        case 0.5:
          setZoom(0.75);
          break;
        case 0.75:
          setZoom(1);
          break;
        case 1:
          setZoom(1.5);
          break;
        case 1.5:
          setZoom(2);
          break;
        case 2:
        default:
          break;
      }
    };

    const onZoomOut = (): void => {
      switch (zoom) {
        case 2:
          setZoom(1.5);
          break;
        case 1.5:
          setZoom(1);
          break;
        case 1:
          setZoom(0.75);
          break;
        case 0.75:
          setZoom(0.5);
          break;
        case 0.5:
        default:
          break;
      }
    };

    const onRotateCW = (): void => {
      switch (rotation) {
        case 0:
          setRotation(90);
          break;
        case 90:
          setRotation(180);
          break;
        case 180:
          setRotation(270);
          break;
        case 270:
        default:
          setRotation(0);
          break;
      }
    };

    const onRotateCCW = (): void => {
      switch (rotation) {
        case 0:
          setRotation(270);
          break;
        case 90:
          setRotation(0);
          break;
        case 180:
          setRotation(90);
          break;
        case 270:
        default:
          setRotation(180);
          break;
      }
    };

    const moveUp = (): void => {
      if (!contentContainerRef.current) return;
      const el = contentContainerRef.current;
      const { height } = el.getBoundingClientRect();
      el.scrollBy({ top: -(height / 2), behavior: 'smooth' });
    };

    const moveDown = (): void => {
      if (!contentContainerRef.current) return;
      const el = contentContainerRef.current;
      const { height } = el.getBoundingClientRect();
      el.scrollBy({ top: height / 2, behavior: 'smooth' });
    };

    const moveLeft = (): void => {
      if (!contentContainerRef.current) return;
      const el = contentContainerRef.current;
      const { width } = el.getBoundingClientRect();
      el.scrollBy({ left: -(width / 2), behavior: 'smooth' });
    };

    const moveRight = (): void => {
      if (!contentContainerRef.current) return;
      const el = contentContainerRef.current;
      const { width } = el.getBoundingClientRect();
      el.scrollBy({ left: width / 2, behavior: 'smooth' });
    };

    useImperativeHandle(ref, () => ({
      onZoomIn,
      onZoomOut,
      moveUp,
      moveDown,
      moveLeft,
      moveRight,
      onRotateCW,
      onRotateCCW,
    }));

    const buttonClassname = customButtonClassname || styles.button;

    return (
      <div className={containerClasses}>
        <div className={`${styles.toolbarContainer} ${customToolbarClassname || styles.toolbarDisplay}`}>
          {customToolbarContent ? customToolbarContent : null}
          <div className={styles.mainGroup}>
            <div className={styles['toolbar-group']}>
              <span aria-disabled={zoom === 0.5} className={buttonClassname} onClick={onZoomOut}>
                <FiZoomOut title="Zoom out" />
              </span>
              <span className={customZoomInfoClassname || styles.info}>{Math.floor(zoom * 100)}%</span>
              <span aria-disabled={zoom === 2} className={buttonClassname} onClick={onZoomIn}>
                <FiZoomIn title="Zoom in" />
              </span>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles['toolbar-group']}>
              <span className={buttonClassname} onClick={onRotateCCW}>
                <FiRotateCcw title="Rotate counter clockwise" />
              </span>
              <span className={buttonClassname} onClick={onRotateCW}>
                <FiRotateCw title="Rotate clockwise" />
              </span>
            </div>
            <div className={styles['toolbar-group']}>
              <span className={buttonClassname} onClick={downloadDocument}>
                <FiDownload title="Download document" />
              </span>
            </div>
            {showPageCount && pageCount && (
              <div className={styles.pageCount}>{`${pageCount} page${pageCount > 1 ? 's' : ''}`}</div>
            )}
          </div>
        </div>
        {loading ? (
          <div className={styles['loading-container']}>{loadingComponent}</div>
        ) : (
          <div className={styles['content-container']} ref={contentContainerRef}>
            {!loading && !doc && <div className={styles['loading-container']}>Waiting for document...</div>}
            {!loading && doc && isSimpleImage && (
              <SimpleImageViewer
                doc={dataUrl}
                zoom={zoom}
                rotation={rotation}
                containerRef={contentContainerRef}
                locations={locations}
              />
            )}
            {!loading && doc && isPDF && (
              <PDFViewer
                containerRef={contentContainerRef}
                doc={doc}
                zoom={zoom}
                rotation={rotation}
                setPageCount={setPageCount}
                onTextSelect={onTextSelect}
                onPageChange={onPageChange}
                locations={locations}
              />
            )}
            {!loading && doc && isTIFF && <TIFFViewer doc={doc} zoom={zoom} />}
            {!loading && doc && !documentType && 'Unsupported document format. Download to view locally.'}
            {children}
          </div>
        )}
      </div>
    );
  }
);

DocumentViewer.displayName = 'DocumentViewer';
