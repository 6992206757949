import { LasDocument } from '@lucidtech/las-sdk-browser';
import { CSSProperties } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/cjs/languages/hljs/json';
import { atelierSeasideLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const dark = '#0D0B23';
const primarySoft = '#F1F3FC';

const theme = {
  ...atelierSeasideLight,
  hljs: { display: 'block', overflowX: 'auto', padding: '0.5em', background: primarySoft, color: dark },
};

const customStyle: CSSProperties = {
  border: '1px solid var(--border-color)',
  margin: 0,
  padding: '1em',
};

SyntaxHighlighter.registerLanguage('json', json);
export type JSONViewProps = {
  groundTruth: LasDocument['groundTruth'];
};
export function JSONView({ groundTruth = [] }: JSONViewProps) {
  return (
    <SyntaxHighlighter language="json" style={theme} customStyle={customStyle} PreTag={'section'}>
      {JSON.stringify(groundTruth, null, 2)}
    </SyntaxHighlighter>
  );
}
