import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PrivateProfile, PublicProfile } from '@lucidtech/las-sdk-browser';

import { useAuthContext } from './useAuthContext';

export function useProfile<T extends string | null | undefined>(
  profileId?: T,
  options?: Omit<
    UseQueryOptions<any, unknown, T extends 'me' ? PrivateProfile : PublicProfile, (string | undefined)[]>,
    'queryKey' | 'queryFn'
  >
) {
  const { client } = useAuthContext();
  const safeOptions = { enabled: options?.enabled === false ? false : Boolean(profileId), ...options };
  const result = useQuery({
    queryKey: ['profile', profileId ?? undefined],
    queryFn: () => client!.makeGetRequest<T extends 'me' ? PrivateProfile : PublicProfile>(`/profiles/${profileId}`),
    ...safeOptions,
  });

  return result;
}
