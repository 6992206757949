import { HTMLProps } from 'react';
import { twMerge } from 'tailwind-merge';

const defaultClassNames = 'tw-flex tw-flex-1 tw-items-center';

export type CellProps = HTMLProps<HTMLDivElement>;

export function Cell({ className, ...props }: CellProps) {
  const classNames = twMerge([defaultClassNames, className]);
  return <div className={classNames} {...props} />;
}
