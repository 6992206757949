import ReactSwitch, { ReactSwitchProps } from 'react-switch';

const PADDING = 2;
const HEIGHT = 18;
const HANDLE_DIAMETER = HEIGHT - PADDING * 3;
const WIDTH = HANDLE_DIAMETER * 2.5;
const ON_COLOR = '#535DC6';
const OFF_COLOR = '#C0CCDD';

export function Switch(props: ReactSwitchProps) {
  return (
    <ReactSwitch
      uncheckedIcon={false}
      checkedIcon={false}
      height={HEIGHT}
      handleDiameter={HANDLE_DIAMETER}
      width={WIDTH}
      onColor={ON_COLOR}
      offColor={OFF_COLOR}
      {...props}
    />
  );
}
