import { Navigate, Outlet } from 'react-router-dom';

import { Box, Button, Loading, SimpleAlert } from '@components';
import { useUser } from '@hooks';
import { hasValidatorRole } from '../../features/validator/utils';

/**
 * Route component, checking if you can access Cradl, or be redirected to Validator.
 * @returns
 */
export function CradlGuard() {
  const { data: userMe, isLoading: userIsLoading, error: userError, refetch: userRetry } = useUser('me');

  const selfRoles = userMe?.roleIds || [];
  const shouldBeRedirectedToValidator = hasValidatorRole(selfRoles);

  if (userIsLoading) {
    return <Loading>Checking permissions</Loading>;
  }

  if (userError) {
    return (
      <Box flex css={{ maxWidth: '400px', justifyContent: 'center', mx: 'auto', height: '100%', alignItems: 'center' }}>
        <SimpleAlert>
          <Box flex css={{ gap: '$2', alignItems: 'center', '& button': { fontSize: '0.8rem', padding: '$2 $3' } }}>
            Error loading user <Button onClick={() => userRetry()}>Retry?</Button>
          </Box>
        </SimpleAlert>
      </Box>
    );
  }

  if (shouldBeRedirectedToValidator) {
    return <Navigate to="/validator" replace />;
  }

  return <Outlet />;
}
