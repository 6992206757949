import { HTMLAttributes, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiHelpCircle, FiPlus } from 'react-icons/fi';
import { DndContext, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { Box, Button, CardBody, CardFooter, NewCard } from '..';
import { Tooltip } from '@mantine/core';
import styles from './FieldConfig.module.scss';
import { FieldConfigAction, FieldConfigState, TypeOption } from './fieldConfigReducer';
import { FieldItem } from './FieldItem';
import { styled } from '@config/stitches';
import { PointerSensor } from '@components';
import { Cell } from './Cell';
export * from './fieldConfigReducer';
export * from './normalizeFieldConfig';
export * from './isFieldConfigEqual';

export const Row = styled('div', {
  display: 'flex',
  gap: '$3',
  '& .name': {
    flexBasis: '30%',
  },
  '& .description': {
    flexBasis: '30%',
  },
  '& .type': {
    flexBasis: '30%',
    maxWidth: '23ch',
  },
  '& .button': {
    minWidth: '35px',
    maxWidth: '35px',
  },
});

const HeaderRow = styled(Row, {
  color: '$gray500',
  fontWeight: 500,
  flex: 1,
  fontSize: '0.8rem',
});

const List = styled('ol', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$3',
  py: '$3',
});

export type FieldConfigProps = {
  fields: FieldConfigState;
  dispatch: React.Dispatch<FieldConfigAction>;
  disabledTypes?: Array<TypeOption>;
  showConfidences: boolean;
  canEditFields?: boolean;
  enablePromptConfig?: boolean;
} & HTMLAttributes<HTMLTableElement>;
export function FieldConfig({
  fields,
  dispatch,
  disabledTypes,
  showConfidences,
  canEditFields,
  enablePromptConfig,
}: FieldConfigProps) {
  const enumInputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [activeId, setActiveId] = useState<string | null>(null);

  function handleDragStart(event: any) {
    dispatch({ type: 'closeCollapse', id: event.active.id as string });
    setActiveId(event.active.id as string);
  }

  const { t } = useTranslation();

  const sensors = useSensors(useSensor(PointerSensor));

  const tryAddingEnumOption = (fieldIndex: number) => {
    if (enumInputRefs.current?.[fieldIndex]?.value) {
      dispatch({ type: 'enumAdd', index: fieldIndex, payload: enumInputRefs.current[fieldIndex]!.value });
      enumInputRefs.current[fieldIndex]!.value = '';
    }
  };

  const onKeyDownHandler = (fieldIndex: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    const isEnterOrTab = ['Enter', 'Tab'].includes(event.key);
    const hasValue = event.currentTarget.value !== '';
    if (isEnterOrTab && hasValue) {
      event.preventDefault();
      tryAddingEnumOption(fieldIndex);
    }
    return !isEnterOrTab && !hasValue;
  };

  function handleDragEnd(event: any) {
    const { active, over } = event;

    // TODO: Figure out why active and/or over can be undefined/null
    if (active?.id && over?.id && active.id !== over.id) {
      dispatch({ type: 'move', id1: active.id, id2: over.id });
    }
    setActiveId(null);
  }

  return (
    <NewCard>
      <Box flex css={{ borderBottom: '1px solid $gray200', backgroundColor: '$grayblue25' }}>
        <Box css={{ width: '30px', height: '100%' }} />

        <HeaderRow>
          <Cell className="name !tw-min-w-[70%] tw-pb-5 tw-pt-5 tw-text-sm tw-text-gray-700">
            <Tooltip
              label={t('models:fieldTitleTooltip')}
              withArrow
              w={300}
              multiline={true}
              classNames={{ tooltip: 'tw-text-xs tw-font-normal tw-flex' }}
            >
              <div className="tw-flex tw-gap-1">Field name</div>
            </Tooltip>
          </Cell>
          {canEditFields ? (
            <>
              <Cell className="type tw-min-w-[8%] tw-text-sm tw-text-gray-700">
                <Tooltip
                  label={t('models:vocabularyTooltip')}
                  withArrow
                  w={300}
                  multiline={true}
                  classNames={{ tooltip: 'tw-text-xs tw-font-normal' }}
                >
                  <div className="tw-flex tw-gap-1">Type</div>
                </Tooltip>
              </Cell>
              <Cell className="type tw-min-w-[8%] tw-text-sm tw-text-gray-700">
                <Tooltip
                  label="Describe your field to help the model extract correct values"
                  withArrow
                  w={300}
                  multiline={true}
                  classNames={{ tooltip: 'tw-text-xs tw-font-normal' }}
                >
                  <div className="tw-flex tw-gap-1">LLM</div>
                </Tooltip>
              </Cell>
            </>
          ) : null}
          {showConfidences ? (
            <Cell className="confidence tw-text-sm tw-text-gray-700">{t('models-create:tableHeaders.confidence')}</Cell>
          ) : null}
          <Cell className="button" />
        </HeaderRow>
      </Box>
      <List>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <SortableContext items={fields} strategy={verticalListSortingStrategy}>
            {fields.map((field, fieldIndex) => (
              <FieldItem
                key={field.id}
                active={activeId === field.id}
                dispatch={dispatch}
                item={field}
                itemIndex={fieldIndex}
                onKeyDownHandler={onKeyDownHandler}
                tryAddingEnumOption={tryAddingEnumOption}
                enumInputRefs={enumInputRefs}
                disabledTypes={disabledTypes}
                showConfidences={showConfidences}
                canEditFields={canEditFields}
                enablePromptConfig={enablePromptConfig}
              />
            ))}
          </SortableContext>
        </DndContext>
      </List>

      {canEditFields ? (
        <CardFooter>
          <CardBody className={styles.footerContainer}>
            <div className="flex">
              <Button variant="borderless" onClick={() => dispatch({ type: 'add' })} className="flex align-center">
                <FiPlus /> {t('models-create:fields.addFieldBtn')}
              </Button>
            </div>
          </CardBody>
        </CardFooter>
      ) : null}
    </NewCard>
  );
}
