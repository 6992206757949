import { ComponentProps, ReactNode } from 'react';
import { styled } from '@config/stitches';

const NewAlertContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  alignItems: 'flex-start',
  border: '1px solid $primary300',
  borderRadius: '8px',
  padding: '1rem',
  defaultVariants: {
    variant: 'info',
  },
  variants: {
    variant: {
      error: {
        backgroundColor: '$error25',
        color: '$error600',
        borderColor: '$error300',
      },
      info: {
        backgroundColor: '$primary25',
        color: '$primary600',
      },
      success: {
        backgroundColor: '$success25',
        borderColor: '$success300',
        color: '$success700',
      },
    },
  },
});

export type NewAlertProps = {
  badge?: ReactNode;
  children: ReactNode;
} & ComponentProps<typeof NewAlertContainer>;
export function NewAlert({ badge, children, ...containerProps }: NewAlertProps) {
  return (
    <NewAlertContainer {...containerProps}>
      {badge}
      {children}
    </NewAlertContainer>
  );
}
